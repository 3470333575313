import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Input,
  TablePagination,
  TableFooter,
  TableBody,
} from "@material-ui/core";
import { useAuthContext } from "../components/AuthContext";
import hooks from "../hooks";
import Title from "../components/VisitTable/Title";
import moment from "moment";
import alertIcon from "../assets/alert.png";
import { useEffect } from "react";
import { patchPlain } from "../utils/aidbox";
import { useRef } from "react";

const useStyles = makeStyles({
  container: {
    display: "flex",
    marginBottom: ".75rem",
    padding: ".75rem",
    flexDirection: "column",
  },
});

// In a perfect world these would all come from a survey definition, but it is what it is
const headerCells = [
  { id: "date", label: "Date" },
  { id: "user", label: "Participant Name" },
  { id: "substanceUse", label: "General Substance Use" },
  { id: "craving", label: "Craving Score" },
  { id: "selfEfficacy", label: "Self-Efficacy Score" },
  { id: "alerts", label: "Alerts" },
  { id: "triaged", label: "Alert Reviewed/Addressed" },
];

const cravingMap = {
  1: "1 - No urge",
  2: "2 - Slight",
  3: "3 - Moderate",
  4: "4 - Strong",
  5: "5 - Strongest ever",
};

const efficacyMap = {
  1: "1 - Not confident at all",
  2: "2 - Slightly confident",
  3: "3 - Moderately confident",
  4: "4 - Very confident",
  5: "5 - Completely confident",
};

const alertHeaders = [
  {
    keywords: ["increased craving score", "high craving score"],
    label: "Craving Score: ",
    surveyProp: "craving",
  },
  {
    keywords: ["decreased self-efficacy score", "low self-efficacy score"],
    label: "Self-Efficacy Score: ",
    surveyProp: "selfEfficacy",
  },
  {
    keywords: ["substance use"],
    label: "Substance Use",
  },
];

function SurveyAdministrationPage() {
  const { user } = useAuthContext();
  const classes = useStyles();
  const [searchStr, setsearchStr] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [displayResults, setDisplayResults] = useState([]);
  const { loading, data, error, refetch } = hooks.useQuery({
    url: `/CustomSurvey?_count=${rowsPerPage}&_page=${
      page + 1
    }&_sort=-date,-createdAt`,
    method: "GET",
  });

  useEffect(() => {
    if (loading) {
      return;
    }
    updateDisplay();
  }, [loading]);

  const updateDisplay = () => {
    let responses = data?.entry.map((surveyResponse) => {
      return surveyResponse.resource;
    });

    if (searchStr?.length > 2) {
      setDisplayResults(
        responses.filter((elem) => {
          let compname =
            `${elem.name?.givenName} ${elem.name?.familyName}`.toLowerCase();
          let searchName = searchStr;
          let containsSubstr = compname.includes(searchName);
          return containsSubstr;
        })
      );
    } else {
      setDisplayResults(responses);
    }
  };

  const dateDisplay = (dateStr) => {
    return moment(dateStr, "YYYY-MM-DD").format("MM/DD/YY");
  };

  const setDisplayFromSearch = (event) => {
    let surveyData = data?.entry.map((surveyResponse) => {
      return surveyResponse.resource;
    });
    setsearchStr(event.target.value);
    if (event.target.value?.length > 2) {
      setDisplayResults(
        surveyData.filter((elem) => {
          let compname =
            `${elem.name?.givenName} ${elem.name?.familyName}`.toLowerCase();
          let searchName = event.target.value.toLowerCase();
          let containsSubstr = compname.includes(searchName);
          return containsSubstr;
        })
      );
    } else {
      setDisplayResults(surveyData);
    }
  };

  function BodyCell(props) {
    return (
      <TableCell
        className={classes.tableCell}
        component="td"
        scope="row"
        padding="normal"
      >
        {props.children}
      </TableCell>
    );
  }

  const getAlerts = (alert, surveyResponse) => {
    let matches = alert.keywords.filter((value) =>
      surveyResponse.alerts?.includes(value)
    );
    if (matches.length > 0) {
      return (
        <div>
          <h3 style={{ fontSize: "14px", color: "red" }}>
            {alert.label}
            {alert.surveyProp && ` ${surveyResponse.result[alert.surveyProp]}`}
          </h3>
          {alert.surveyProp && (
            <p style={{ fontSize: "12px" }}>
              {matches.map((elem) => `(${elem})`).join(" ")}
            </p>
          )}
        </div>
      );
    }
  };

  const generateTableRow = (surveyResponse, index) => {
    return (
      <TableRow
        className={classes.tableRow}
        hover
        tabIndex={-1}
        key={"row" + index}
      >
        <BodyCell>{dateDisplay(surveyResponse.date)}</BodyCell>
        <BodyCell>
          {surveyResponse.name?.givenName} {surveyResponse.name?.familyName}
        </BodyCell>
        <BodyCell>{surveyResponse.result.substanceUse?.join(", ")}</BodyCell>
        <BodyCell>{cravingMap[surveyResponse.result.craving]}</BodyCell>
        <BodyCell>{efficacyMap[surveyResponse.result.selfEfficacy]}</BodyCell>
        <BodyCell>
          {alertHeaders.map((alert) => getAlerts(alert, surveyResponse))}
        </BodyCell>
        <BodyCell>
          <Checkbox
            onClick={() => {
              if (!loading) {
                let body = {
                  ...surveyResponse,
                  triaged: !surveyResponse.triaged,
                };
                patchPlain(`/CustomSurvey/` + body.id, body).then((resp) => {
                  refetch(
                    `/CustomSurvey?_count=${rowsPerPage}&_page=${
                      page + 1
                    }&_sort=-date,-createdAt`
                  );
                });
              }
            }}
            style={{ marginLeft: "40%" }}
            checked={surveyResponse.triaged}
          />
        </BodyCell>
      </TableRow>
    );
  };

  return (
    <div className={classes.container}>
      <Input
        defaultValue={undefined}
        onChange={(event) => {
          setDisplayFromSearch(event);
        }}
        placeholder="Search by participant name"
        style={{ width: 300 }}
        fullWidth={false}
      />
      <Title style={{ margin: "30pt 0" }} title="Surveys" />
      <Paper className={classes.paper}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headerCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    style={{ fontWeight: "700", verticalAlign: "center" }}
                    align={"center"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    className={classes.tableHeadCell}
                  >
                    {headCell.id == "alerts" && (
                      <img
                        src={alertIcon}
                        alt="alert icon"
                        style={{
                          marginRight: "3pt",
                          height: "15pt",
                        }}
                      />
                    )}
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayResults?.map((elem, index) => {
                return generateTableRow(elem, index);
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page ?? 0}
                  count={data?.total ?? 0}
                  rowsPerPage={rowsPerPage ?? 0}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(event.target.value);
                    refetch(
                      `/CustomSurvey?_count=${event.target.value}&_page=${
                        page + 1
                      }&_sort=-date,-createdAt`
                    );
                  }}
                  onPageChange={(event, newPage) => {
                    setPage(newPage);
                    refetch(
                      `/CustomSurvey?_count=${rowsPerPage}&_page=${
                        newPage + 1
                      }&_sort=-date,-createdAt`
                    );
                  }}
                ></TablePagination>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default SurveyAdministrationPage;
