import React, { createContext, useContext, useEffect, useState } from "react";

const StoreLayerContext = createContext(null);

export class Store {
  /**
   * @constructor
   * @template T
   * @param {T} initValue
   * @param {*} reducer
   */
  constructor(initValue, reducer) {
    this.value = initValue;
    /**
     * @private
     */
    this.reducer = reducer;
    /**
     * @private
     */
    this.listeners = [];
    this.transform = this.transform.bind(this);
    this.addListener = this.addListener.bind(this);
  }

  /**
   * @param {function(T):T|function(T):any} fn
   * @returns {T}
   */
  transform(fn) {
    const prevValue = this.value;
    this.value = this.reducer(prevValue, fn);
    if (this.value !== prevValue) {
      this.listeners.forEach((it) => it(this.value));
    }
    return this.value;
  }

  addListener(listener) {
    listener(this.value);
    this.listeners.push(listener);
    return () => {
      /**
       * @private
       */
      this.listeners = this.listeners.filter((it) => it !== listener);
    };
  }
}

export function StoreLayer({ store, children }) {
  const [value, setValue] = useState(store.value);
  useEffect(() => {
    return store.addListener((val) => setValue(() => val));
  }, [store]);

  return (
    <StoreLayerContext.Provider value={[value, store.transform]}>
      {children}
    </StoreLayerContext.Provider>
  );
}

export function useStore(fn) {
  /* eslint prefer-const: "off" */
  let [value, setStore] = useContext(StoreLayerContext);
  useState(() => {
    if (fn) {
      value = setStore(fn);
    }
  });
  return [value, setStore];
}
