import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getIn } from "../utils/utils";

export default function AutocompleteAsync(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await props.queryFunction();

      if (active && response) {
        setOptions(props.setOptions(response));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  async function setQueryResults(value) {
    const response = await props.queryFunction(value);

    if (response) {
      setOptions(props.setOptions(response));
    }
  }

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      value={props.value}
      onInputChange={(e, value) => {
        console.log("Query", value);
        setQueryResults(value);
      }}
      onChange={props.onChange}
      id={props.id || ""}
      style={{ width: 300 }}
      openOnFocus
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => {
        return option.label === value.label;
      }}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          autoComplete="off"
          label={props.label || ""}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
