import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CachedIcon from "@material-ui/icons/Cached";
import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { debounce } from "../hooks/useDebounceInput";
import * as aidbox from "../utils/aidbox";
import { getIn } from "../utils/utils";
import * as vars from "../styles/vars";
import { buttons, helpers } from "../styles";
import { BrandColors } from "../styles/vars";

const PharmacySearch = ({ currentlyPreferredPharmacy, isClosed, onSave }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: ".75rem",
      backgroundColor: "white",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      marginBottom: ".75rem",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    btnGroup: {
      display: "flex",
    },
    chosenMedication: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    },
    chosenMedicationText: {
      fontWeight: "bold",
    },

    chosenMedicationResearch: {
      marginLeft: "10px",
    },
    addBody: {
      position: "relative",
      padding: ".5rem",
      display: "grid",
      gridTemplateColumns: "1fr 75px",
      gridGap: "10px",
    },
    addBodySearchInput: {
      width: "100%",
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: "4px",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      padding: ".25rem",
      fontSize: "1.25rem",
    },
    submit: {
      display: "flex",
      alignItems: "flex-end",
      "& button": {
        fontWeight: "bold",
        height: "35px",
      },
    },
    searchResult: {
      position: "absolute",
      top: "125px",
      left: ".5rem",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      height: "200px",
      borderRadius: "4px",
      overflowY: "auto",
      zIndex: "10",
      backgroundColor: "whitesmoke",
      color: theme.palette.primary.main,
      padding: ".75rem",
      border: `2px solid ${theme.palette.primary.main}`,
      "& div": {
        cursor: "pointer",
        padding: ".25rem",
        "& span": {
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      },
    },
    medicationsList: {
      marginTop: "1.5rem",
      display: "grid",
      gridGap: "10px",
    },
    medication: {
      marginBottom: ".75rem",
      display: "grid",
      alignItems: "flex-end",
      gridTemplateColumns: "1fr",
      paddingBottom: ".25rem",
      borderBottom: "3px solid #D32F2F",
      color: "black",
    },
    medicationActive: {
      marginTop: 10,
    },
    code: {
      padding: "0 .25rem",
      borderRight: "1px solid black",
      fontWeight: "bold",
    },
    text: {
      paddingLeft: ".25rem",
    },
    medicationDelete: {
      display: "flex",
      cursor: "pointer",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      color: "#616161",
      "& span": {
        display: "inline-block",
        fontWeight: " bold",
      },
      "&:hover": {
        color: "red",
      },
    },
  }));
  const classes = useStyles();
  const btns = buttons();
  const utils = helpers();
  const [isShow, setShow] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [isNotFound, setNotFound] = React.useState(false);
  const [isStopped, setStopped] = React.useState(false);
  const [currentPharmacy, setCurrentPharmacy] = React.useState({
    StoreName: "",
    PharmacyId: null,
  });
  const [addBody, setBody] = React.useState({});
  const [pharmacies, setPharmacies] = React.useState([]);
  const [isChosen, setChosen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [pharamacySearchName, setPharamacySearchName] = useState("");

  useEffect(() => {
    (async () => {
      const id = currentlyPreferredPharmacy?.id;
      if (!id) return;
      try {
        const pharm = await aidbox.postPlain(`/$dosespot-get-pharmacy-by-id`, {
          id,
        });
        setCurrentPharmacy(pharm.data);
        console.log("Current pharmacy", pharm.data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [currentlyPreferredPharmacy]);

  function resetForm() {
    setShow(false);
    setChosen(false);
    setBody();
    setPharmacies([]);
    setCurrentPharmacy({
      StoreName: "",
      PharmacyId: null,
    });
  }
  function searchPharmacies(zip, name) {
    setPharmacies([]);
    if (zip.length >= 5) {
      setLoading(true);
      aidbox
        .postPlain(`/$dosespot-search-pharmacies`, {
          zip,
          name,
        })
        .then((resp) => {
          if (!isStopped) {
            if (resp.data.length) {
              const temp = resp.data;
              console.log(temp);
              if (temp.length) {
                setPharmacies(temp);
                setLoading(false);
                setNotFound(false);
              } else setNotFound(true);
            } else {
              setNotFound(true);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          aidbox.sendErrorLogs({ data: err });
          console.log(err);
        });
    }
  }

  const debounceSearch = React.useCallback(debounce(searchPharmacies, 200), []);

  function choosePharmacy(pharmacy) {
    setCurrentPharmacy({ ...pharmacy });
    setChosen(true);
    setBody({ ...addBody, ...pharmacy });
    setPharmacies([]);
    setDisabled(false);
    onSave({ ...pharmacy });
    resetForm();
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>Pharmacy</div>
        <div className={classes.btnGroup}>
          {!isClosed ? (
            isShow ? (
              <button
                type="button"
                className={clsx(btns.btn, btns.btnEdit, utils.fontBold)}
                onClick={() => resetForm()}
              >
                Reset
              </button>
            ) : (
              <button
                type="button"
                className={clsx(btns.btn, btns.btnAdd, utils.fontBold)}
                onClick={() => setShow(true)}
              >
                Add
              </button>
            )
          ) : null}
        </div>
      </div>
      {isShow ? (
        <div className={classes.addBody}>
          <div>
            {isChosen ? (
              <div className={classes.chosenMedication}>
                <div className={classes.chosenMedicationText}>
                  <div>Name: {currentPharmacy.StoreName}</div>
                  <div>Address: {currentPharmacy.Address1}</div>
                  <div>City: {currentPharmacy.City}</div>
                  <div>State: {currentPharmacy.State}</div>
                  <div>ZIP: {currentPharmacy.ZipCode}</div>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className={classes.chosenMedicationResearch}
                  onClick={() => setChosen(false)}
                >
                  <CachedIcon />
                </div>
              </div>
            ) : (
              <>
                <div>
                  <label className="required">Pharmacy Name</label>
                  <input
                    className={classes.addBodySearchInput}
                    type="text"
                    value={pharamacySearchName}
                    onChange={(e) => {
                      setPharamacySearchName(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label className="required">ZIP Code</label>
                  <input
                    className={classes.addBodySearchInput}
                    type="text"
                    onChange={(e) => {
                      debounceSearch(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {pharmacies.length !== 0 ? (
            <div className={classes.searchResult}>
              <span
                onClick={() => setPharmacies([])}
                style={{
                  color: "red",
                  right: 5,
                  top: -2,
                  position: "absolute",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              >
                ×
              </span>
              {pharmacies
                .filter((pharmacy) => {
                  if (pharamacySearchName) {
                    return pharmacy.StoreName.toLowerCase().includes(
                      pharamacySearchName.toLowerCase()
                    );
                  }
                  return true;
                })
                .map((pharmacy) => (
                  <div onClick={() => choosePharmacy(pharmacy)}>
                    <span>
                      {pharmacy.StoreName} - {pharmacy.Address1} -{" "}
                      {pharmacy.ZipCode}
                    </span>
                  </div>
                ))}
            </div>
          ) : null}
          {isNotFound ? (
            <div className={classes.searchResult}>
              <div>Not found </div>
            </div>
          ) : null}
          {isLoading ? (
            <div className={classes.searchResult}>
              <div> Processing... </div>
            </div>
          ) : null}
          <div className={classes.submit}>
            <button
              className={
                isDisabled
                  ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
                  : clsx(btns.btn, btns.btnAdd, utils.fontBold)
              }
              onClick={() => {
                onSave(addBody);
                resetForm();
              }}
              type="button"
              disabled={isDisabled}
            >
              Save
            </button>
          </div>
        </div>
      ) : null}
      {currentPharmacy && (
        <div className={clsx(classes.medication, classes.medicationActive)}>
          <div className={classes.code}>{currentPharmacy.StoreName}</div>
          <div className={classes.text}>{currentPharmacy.Address1}</div>
          <div className={classes.text}>
            {currentPharmacy.City}, {currentPharmacy.State}{" "}
            {currentPharmacy.ZipCode}
          </div>
        </div>
      )}
    </div>
  );
};

PharmacySearch.propTypes = {};

export default PharmacySearch;
