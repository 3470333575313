import React from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { getIn } from "../utils/utils";
import { postPlain } from "../utils/aidbox";
import USStates from "../constants/USStates";

export default function EligibilityChecker({
  clsx,
  Boolean,
  classes,
  dispatch,
  setAlertBar,
  setAlertMessage,
  patientData,
}) {
  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required."),
    birthDate: yup
      .date("This must be a valid date")
      .required("Birthdate is required."),
    activationCodeText: yup.string().required("Activation code is required."),
    stateInCurrently: yup.string().required("Current state is required."),
  });

  const formik = useFormik({
    initialValues: {
      firstName: patientData.firstName || "",
      lastName: patientData.lastName || "",
      birthDate: patientData.birthDate || "",
      activationCodeText: patientData.activationCodeText || "",
      stateInCurrently: patientData.stateInCurrently || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const submissionValues = { ...values };
      submissionValues.birthDate = moment(
        values.birthDate,
        "YYYY-MM-DD"
      ).format("MM/DD/YYYY");
      submissionValues.state = values.stateInCurrently;
      try {
        const resp = await postPlain(
          "/npc/$patient-eligibility",
          submissionValues
        );

        const eligibility = yup.object({
          isEligible: yup
            .boolean()
            .required("This user is ineligible")
            .oneOf([true], "This user is ineligible"),
          locationValid: yup
            .boolean()
            .required("This user is not in a supported location")
            .oneOf([true], "This user is not in a supported location"),
        });

        if (!getIn(resp, ["data"], false)) throw new Error();

        if (resp.status === 200) {
          await eligibility.validate(resp.data);
          dispatch({
            type: "updatePatientData",
            data: { ...patientData, values },
          });
          dispatch({ type: "nextStep" });
        } else {
          throw new Error();
        }
      } catch (e) {
        setAlertBar(true);
        setAlertMessage(e.toString());
      }
    },
  });

  return (
    <>
      <Typography style={{ marginBottom: 10 }} variant="h6">
        Check Eligibility
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <div className={clsx(classes.formWrapperCentered)}>
          <Grid
            container
            item
            spacing={3}
            columns={{
              xs: 1,
              sm: 1,
              md: 1,
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="firstName"
                id="firstName"
                label="First Name"
                variant="outlined"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="lastName"
                id="lastName"
                label="Last Name"
                variant="outlined"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="birthDate"
                label="Birth Date"
                type="date"
                name="birthDate"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.birthDate}
                onChange={formik.handleChange}
                error={
                  formik.touched.birthDate && Boolean(formik.errors.birthDate)
                }
                helperText={formik.touched.birthDate && formik.errors.birthDate}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="activationCodeText"
                id="activationCodeText"
                label="Activation Code"
                variant="outlined"
                value={formik.values.activationCodeText}
                onChange={(event) =>
                  formik.setFieldValue(
                    "activationCodeText",
                    event.target.value.toUpperCase()
                  )
                }
                error={
                  formik.touched.activationCodeText &&
                  Boolean(formik.errors.activationCodeText)
                }
                helperText={
                  formik.touched.activationCodeText &&
                  formik.errors.activationCodeText
                }
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="State Currently In"
                variant="outlined"
                value={formik.values.stateInCurrently}
                onChange={formik.handleChange}
                error={
                  formik.touched.stateInCurrently &&
                  Boolean(formik.errors.stateInCurrently)
                }
                helperText={
                  formik.touched.stateInCurrently &&
                  formik.errors.stateInCurrently
                }
                onBlur={formik.handleBlur}
                inputProps={{
                  name: "stateInCurrently",
                  id: "stateInCurrently",
                }}
              >
                {USStates.map((state) => {
                  return (
                    <MenuItem
                      key={state.abbreviation}
                      value={state.abbreviation}
                    >
                      {state.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Check
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
}
