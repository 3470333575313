import React, { useEffect } from "react";
import DateFnsUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import PropTypes from "prop-types";
import CachedIcon from "@material-ui/icons/Cached";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { debounce } from "../hooks/useDebounceInput";
import { getPlain, sendErrorLogs } from "../utils/aidbox";
import * as vars from "../styles/vars";
import { buttons, helpers } from "../styles";
import { BrandColors } from "../styles/vars";
import { getIn } from "../utils/utils";

const MedicationAllergies = ({
  patientAllergies,
  subject,
  onSave,
  onDelete,
  encounterID,
  isClosed,
}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: ".75rem",
      backgroundColor: "white",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      marginBottom: ".75rem",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    btnGroup: {
      display: "flex",
    },
    radioInput: {
      appearance: "auto",
      marginLeft: 4,
      marginRight: 4,
    },
    chosenAllergy: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    },
    chosenAllergyText: {
      fontWeight: "bold",
    },

    chosenAllergyResearch: {
      marginLeft: "10px",
    },
    addBody: {
      position: "relative",
      padding: ".75rem 0",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: "10px",
    },
    allergyBody: {
      "& input": {
        width: "100%",
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: vars.borderRadius,
        padding: ".25rem",
        fontSize: "1.25rem",
      },
    },
    reactionBody: {
      "& textarea": {
        width: "100%",
        border: `2px solid ${theme.palette.primary.main}`,
        resize: "none",
        borderRadius: vars.borderRadius,
        padding: ".25rem",
        fontSize: "1.25rem",
      },
    },
    statusBody: {
      display: "flex",
    },
    dateBody: {},
    submit: {
      display: "flex",
      alignItems: "flex-end",
      "& button": {
        marginLeft: "10px",
        fontWeight: "bold",
        height: "35px",
      },
    },
    searchResult: {
      position: "absolute",
      top: "75px",
      left: ".5rem",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      height: "200px",
      borderRadius: "4px",
      overflowY: "auto",
      zIndex: "10",
      backgroundColor: "whitesmoke",
      color: theme.palette.primary.main,
      padding: ".75rem",
      border: `2px solid ${theme.palette.primary.main}`,
      "& div": {
        cursor: "pointer",
        padding: ".25rem",
        "& span": {
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      },
    },
    allergiesList: {
      marginTop: "1.5rem",
      display: "grid",
      gridGap: "10px",
    },
    allergy: {
      borderBottom: `3px solid #BF360C`,
      paddingBottom: ".25rem",
      gridTemplateColumns: "1fr 200px 10px",
      display: "grid",
      alignItems: "center",
      justifyContent: "center",
      gridColumnGap: "10px",
    },
    allergyHeader: {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      gridTemplateColumns: "1fr 200px 10px",
      display: "grid",
      alignItems: "center",
      justifyContent: "center",
      gridColumnGap: "10px",
    },
    allergyName: {
      wordBreak: "break-word",
      "& span": {
        fontWeight: "bold",
        fontSize: "1.25rem",
      },
    },
    allergyInner: {
      wordBreak: "break-word",
      textTransform: "capitalize",
      borderLeft: `1px solid black`,
      paddingLeft: ".25rem",
      display: "grid",
      fontSize: ".85rem",
    },
    allergyDelete: {
      display: "flex",
      cursor: "pointer",
      color: "#616161",
      "& span": {
        fontWeight: " bold",
      },
      "&:hover": {
        color: "red",
      },
    },
  }));
  const classes = useStyles();
  const btns = buttons();
  const utils = helpers();
  const [isShow, setShow] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [isNotFound, setNotFound] = React.useState(false);
  const [isStopped, setStopped] = React.useState(false);
  const [currentAllergy, setAllergy] = React.useState({
    Name: "",
    Code: null,
  });
  const [addBody, setBody] = React.useState({
    status: "1",
    patient: {
      resourceType: "Patient",
      id: subject.id,
    },
    encounter: {
      resourceType: "Encounter",
      id: encounterID,
    },
    onsetDateTime: null,
    reaction: "",
  });
  const [allergies, setAllergies] = React.useState([]);
  const [isChosen, setChosen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  function resetForm() {
    setShow(false);
    setChosen(false);
    setBody({
      status: "1",
      patient: {
        resourceType: "Patient",
        id: subject.id,
      },
      encounter: {
        resourceType: "Encounter",
        id: encounterID,
      },
      onsetDateTime: null,
      reaction: "",
    });
    setAllergies([]);
    setAllergy({ Name: "", Code: null });
  }
  function searchAllergies(allergy) {
    setAllergies([]);
    if (allergy.length >= 3) {
      setLoading(true);
      getPlain(`/Dosespot/$dosespot-search-allergies/${allergy}`)
        .then((resp) => {
          if (!isStopped) {
            if (resp.data.length) {
              const temp = resp.data.filter((allergyInner) =>
                allergyInner.RxCUI
                  ? !patientAllergies.find((exist) => {
                      return (
                        getIn(exist, [
                          "code",
                          "coding",
                          ({ system }) => system === "dosespot:rxcui",
                          0,
                          "code",
                        ]) === allergyInner.RxCUI.toString()
                      );
                    })
                  : true
              );
              if (temp.length) {
                setAllergies(temp);
                setLoading(false);
                setNotFound(false);
              } else setNotFound(true);
            } else {
              setNotFound(true);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          sendErrorLogs({ data: err });
        });
    }
  }

  const debounceSearch = React.useCallback(debounce(searchAllergies, 200), []);

  function chooseAllergy(index) {
    setAllergy({ ...allergies[index] });
    setChosen(true);
    setBody({ ...addBody, ...allergies[index] });
    setAllergies([]);
  }

  useEffect(() => {
    if (currentAllergy.Code && addBody.status) setDisabled(false);
  }, [addBody.status, currentAllergy]);

  return (
    /* eslint no-nested-ternary: "off" */
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>Medication Allergies</div>
          <div className={classes.btnGroup}>
            {!isClosed ? (
              isShow ? (
                <button
                  type="button"
                  className={clsx(btns.btn, btns.btnEdit, utils.fontBold)}
                  onClick={() => resetForm()}
                >
                  Reset
                </button>
              ) : (
                <button
                  type="button"
                  className={clsx(btns.btn, btns.btnAdd, utils.fontBold)}
                  onClick={() => setShow(true)}
                >
                  Add
                </button>
              )
            ) : null}
          </div>
        </div>
        {isShow ? (
          <div className={classes.addBody}>
            <div className={classes.allergyBody}>
              <label className="required">Name</label>
              {isChosen ? (
                <div className={classes.chosenAllergy}>
                  <div className="text">
                    <div>Name: {currentAllergy.Name}</div>
                    <div>Code: {currentAllergy.Code}</div>
                    <div>CodeType: {currentAllergy.CodeType}</div>
                    <div>RxCUI: {currentAllergy.RxCUI ?? "Not set"}</div>
                  </div>
                  <div className="re-search" onClick={() => setChosen(false)}>
                    <CachedIcon />
                  </div>
                </div>
              ) : (
                <input
                  type="text"
                  onBlur={(e) => {
                    setStopped(true);
                    if (isChosen || isNotFound) {
                      setNotFound(false);
                      setAllergies([]);
                    }
                    setLoading(false);
                    e.target.value = "";
                  }}
                  onChange={(e) => {
                    debounceSearch(e.target.value);
                  }}
                />
              )}
            </div>
            <div className={classes.reactionBody}>
              <label className="required">Reaction</label>
              <textarea
                rows={4}
                value={addBody.reaction}
                onChange={(e) =>
                  setBody({ ...addBody, reaction: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="" className="required">
                Status
              </label>
              <div>
                <label>
                  Active
                  <input
                    className={classes.radioInput}
                    defaultChecked
                    type="radio"
                    name="status"
                    value="1"
                    onChange={(e) =>
                      setBody({
                        ...addBody,
                        status: e.target.value,
                      })
                    }
                  />
                  <span className="checkmark" />
                </label>
                <label>
                  Inactive
                  <input
                    className={classes.radioInput}
                    type="radio"
                    name="status"
                    value="2"
                    onChange={(e) =>
                      setBody({
                        ...addBody,
                        status: e.target.value,
                      })
                    }
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className={classes.submit}>
              <DateTimePicker
                label="Choose onset date"
                value={addBody.onsetDateTime}
                onChange={(e) => {
                  setBody({
                    ...addBody,
                    onsetDateTime: moment(e._d).toISOString(),
                  });
                }}
              />
              <button
                className={
                  isDisabled
                    ? clsx(btns.btn, btns.btnDisabled)
                    : clsx(btns.btn, btns.btnAdd)
                }
                onClick={() => {
                  onSave(addBody, subject);
                  resetForm();
                }}
                type="button"
                disabled={isDisabled}
              >
                Save
              </button>
            </div>

            {allergies.length !== 0 ? (
              <div className={classes.searchResult}>
                {allergies.map((a, index) => (
                  <div onClick={() => chooseAllergy(index)}>
                    <span>{a.Name}</span>
                  </div>
                ))}
              </div>
            ) : null}
            {isNotFound ? (
              <div className={classes.searchResult}>
                <div>Not found </div>
              </div>
            ) : null}
            {isLoading ? (
              <div className={classes.searchResult}>
                <div> Processing... </div>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className={classes.allergiesList}>
          {(patientAllergies || []).length === 0 ? (
            <center>
              <h2>No medication allergies</h2>
            </center>
          ) : (
            <>
              <div className={classes.allergyHeader}>
                <div>Name</div>
                <div>Options</div>
                <div />
              </div>
              {(patientAllergies || []).map((allergy) => (
                <div className={classes.allergy} key={allergy.id}>
                  <div className={classes.allergyName}>
                    <span>{allergy.code.text}</span>
                  </div>
                  <div className={classes.allergyInner}>
                    {(allergy.code.coding || []).map((coding) => (
                      <div key={coding.code}>
                        {coding.system === "dosespot:rxcui" ? (
                          <div>
                            <strong>RxCUI: </strong>
                            {coding.code}
                          </div>
                        ) : (
                          <div>
                            <strong>
                              Allergy Code Type {coding.system.split(":").pop()}
                              :{" "}
                            </strong>
                            {coding.code}
                          </div>
                        )}
                      </div>
                    ))}
                    <div>
                      {getIn(allergy, ["clinicalStatus", "coding", 0, "code"])}
                    </div>
                  </div>
                  <div className={classes.allergyDelete}>
                    {!isClosed ? (
                      <span
                        onClick={() => {
                          const isDelete = window.confirm(
                            `Are you sure you would like to delete ${allergy.code.text}`
                          );
                          if (isDelete) {
                            onDelete(allergy.id, subject.id);
                          }
                        }}
                      >
                        &times;
                      </span>
                    ) : null}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

MedicationAllergies.propTypes = {
  patientAllergies: PropTypes.instanceOf(Array).isRequired,
  subject: PropTypes.instanceOf(Object).isRequired,
  encounterID: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isClosed: PropTypes.bool.isRequired,
};

export default MedicationAllergies;
