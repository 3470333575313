import React from "react";
import * as ui from "@material-ui/core";
import { useParams } from "react-router";
import moment from "moment";
import { useController } from "../../Controller";
import { getIn } from "../../utils/utils";
import hooks from "../../hooks";
import { useAuthContext } from "../../components/AuthContext";

const useStyles = ui.makeStyles({
  chat: {
    height: "200px",
  },
  container: {},
});

function VisitBilling() {
  const classes = useStyles();
  const ctrl = useController();
  const params = useParams();
  const { user } = useAuthContext();

  const { loading, error, data } = hooks.useQuery({
    url: `/$graphql-query/physician-app-visit-billing`,
    method: "POST",
    data: {
      variables: {
        encId: params.id,
        userId: getIn(user, ["id"]),
      },
    },
  });

  if (!data) return <></>;

  console.log("Data", data);
  const diagnosis = {
    display: getIn(
      data,
      ["encounter", "diagnosis", "0", "code", "coding", "0", "display"],
      false
    ),
    text: getIn(data, ["encounter", "diagnosis", "0", "code", "text"], false),
  };
  const billingCodes = getIn(
    data,
    ["encounter", "assessment", "0", "billingCodes"],
    []
  ).map((code) => {
    return {
      description: code.resource.description,
      id: code.resource.id,
    };
  });
  const scheduleText = getIn(
    data,
    ["encounter", "videocall", "0", "description"],
    false
  );
  const dateOfService = getIn(data, ["encounter", "videocall", "0", "start"]);
  console.log(billingCodes);

  return (
    <div>
      <div>First Name: {data.encounter.subject.resource.name[0].given[0]}</div>
      <div>Last Name: {data.encounter.subject.resource.name[0].family}</div>
      <div>
        Birthdate:{" "}
        {moment(data.encounter.subject.resource.birthDate).format("MM/DD/YYYY")}
      </div>
      {console.log(dateOfService)}
      {dateOfService && (
        <div>Date of Service: {moment(dateOfService).format("MM/DD/YYYY")}</div>
      )}
      {diagnosis.display && diagnosis.text && (
        <div>
          Diagnosis: {diagnosis.display} - {diagnosis.text}{" "}
        </div>
      )}
      <br />
      {billingCodes.length > 0 && (
        <div>
          Billing Codes:
          {billingCodes.map((code) => (
            <div>
              {code.id} - {code.description}
            </div>
          ))}
        </div>
      )}
      <br />
      {scheduleText && <div>Visit Date: {scheduleText}</div>}
    </div>
  );
}

export default VisitBilling;
