import React, { useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as ui from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
// import moment from "moment";
import moment from "moment-timezone";
import VideoPlayer from "../../components/Agora";
import { useController } from "../../Controller";
import { getIn, href } from "../../utils/utils";
import VisitPatientInfo from "../../components/VisitPatientInfo";
import { useStore } from "../../Store";
import DoseSpotUI from "../../components/DoseSpotUI";
import SingleEncounterButtonToolbar from "../../components/SingleEncounterButtonToolbar";
import Conditions from "../../components/Conditions";
import Prescriptions from "../../components/Prescriptions";
import Medications from "../../components/Medications";
import MedicationAllergies from "../../components/MedicationAllergies";
import DocumentReferenceEncounter from "../../components/DocumentReferenceEncounter";
import FollowUpMessages from "../../components/FollowUpMessages";
import AssessmentList from "../../components/AssessmentList/AssessmentList";
import BillingCode from "../../components/BillingCode";
import AssessmentField from "../../components/AssessmentField";
import * as vars from "../../styles/vars";
import { helpers } from "../../styles";
import ObservationTable from "../../components/ObservationsTable";
import QuestionnaireResponseTable from "../../components/QuestionnaireResponseTable";

const useStyles = ui.makeStyles((theme) => ({
  chat: {
    height: "200px",
  },
  container: {},
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    gridGap: ".75rem",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2,1fr)",
    },
  },
  videoSchedule: {
    width: "100%",
    padding: ".75rem",
    backgroundColor: "white",
    boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    marginBottom: ".75rem",
  },
  headerTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
  descriptionForm: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1rem",
  },
  linkCopied: {
    color: "red",
    display: "inline-block",
    marginLeft: 4,
    fontWeight: "bold",
  },
  headerTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function VideoVisit() {
  const classes = useStyles();
  const [state] = useStore();
  const ctrl = useController();
  const meRef = React.useRef();
  const patientRef = React.useRef();
  const isClosed = state.data.status === "finished";
  const yesterday = moment().subtract(1, "day");
  const styleUtils = helpers();
  const printRef = useRef();

  function valid(current) {
    return current.isAfter(yesterday);
  }

  function handlePrint() {
    printRef.current.style.display = "none";
    ctrl.printVisit();
    printRef.current.style.display = "block";
  }

  return (
    <>
      <ui.Grid container spacing={2}>
        <ui.Grid item xs={12}>
          <VisitPatientInfo
            patient={getIn(state.data, ["subject", "resource"])}
            chief={state.data?.reasonCode}
            datetime={getIn(state.data, ["period", "start"])}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <SingleEncounterButtonToolbar
            loading={state.loading}
            printVisit={handlePrint}
            refresh={ctrl.refetch}
            subject={state.data.subject}
            isSync={state.isModalSync}
            scheduleVideoVisit={ctrl.scheduleVideoVisit}
            prescribe={ctrl.prescribe}
            sync={ctrl.syncDoseSpot}
            closeVisit={() => {
              if (
                state.data?.diagnosis?.length === 0 ||
                Object.keys(state.billingCodes).length === 0
              ) {
                alert(
                  "Please add a diagnosis and billing code before completing this visit."
                );
                return;
              }
              ctrl.closeVisit();
            }}
            isClosed={isClosed}
          />
        </ui.Grid>
        <ui.Grid
          item
          xs={12}
          style={{ display: state.video.ready ? "" : "none" }}
        >
          <VideoPlayer meRef={meRef} patientRef={patientRef} />
        </ui.Grid>
        <ui.Grid item xs={12} md={6}>
          <ui.Grid item xs={12}>
            <div className={classes.videoSchedule}>
              <div>
                <span className={classes.headerTitle}>Video Visit</span>
              </div>
              {getIn(state, ["data", "videocall", 0]) ? (
                <div className={classes.descriptionForm}>
                  <div>
                    <ui.Typography
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        marginTop: 10,
                      }}
                    >
                      Visit Date and Time
                    </ui.Typography>

                    <ui.TextField
                      type="datetime-local"
                      inputProps={{ min: moment().format("YYYY-MM-DDTHH:mm") }}
                      value={
                        state.videoCallStartTime
                          ? moment(
                              new Date(moment.utc(state.videoCallStartTime))
                            ).format("YYYY-MM-DDTHH:mm")
                          : null
                      }
                      onChange={(event) => {
                        const value = event.target.value;
                        const momentValue = moment(value);
                        ctrl.setVideoCallStartTime(momentValue);
                      }}
                    ></ui.TextField>
                  </div>
                  {state.video.id ? (
                    <div>
                      <CopyToClipboard
                        text={`${
                          window.AIDBOX_URL === null ? "" : window.AIDBOX_URL
                        }/static/patient-video.html?key=${state.video.id}`}
                      >
                        <a
                          onClick={(e) => ctrl.setVideoLinkCopied(e)}
                          href="#"
                          style={{ textDecoration: "underline", color: "blue" }}
                        >
                          Share this link with patient
                        </a>
                      </CopyToClipboard>
                      {state.video.videoLinkCopied ? (
                        <span className={classes.linkCopied}>Link copied</span>
                      ) : null}
                    </div>
                  ) : null}
                  <div>
                    <ui.Button
                      onClick={ctrl.saveVideoCallStartTime}
                      color="primary"
                      variant="contained"
                      style={{ marginRight: "10px" }}
                    >
                      Save
                    </ui.Button>
                    {!state.video.ready ? (
                      <ui.Button
                        onClick={(e) =>
                          ctrl.startVideoCall(e, meRef, patientRef)
                        }
                        color="primary"
                        variant="contained"
                      >
                        Start
                      </ui.Button>
                    ) : null}
                    <span style={{ paddingLeft: "0.5rem", color: "red" }}>
                      {" "}
                      {getIn(state, ["video", "message"])}{" "}
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <ui.Button
                    onClick={ctrl.scheduleVideoVisit}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "1rem" }}
                  >
                    Schedule
                  </ui.Button>
                </div>
              )}
            </div>
          </ui.Grid>
          <ui.Grid item xs={12}>
            <Conditions
              title="Conditions"
              patientConditions={state.data.subject.resource.conditions.filter(
                (cond) =>
                  getIn(cond, [
                    "category",
                    0,
                    "coding",
                    { code: "problem-list-item" },
                    0,
                  ])
              )}
              patientID={state.data.subject.id}
              encounterID={state.data.id}
              onSave={ctrl.saveCondition}
              onDelete={ctrl.deleteCondition}
              isClosed={isClosed}
            />
          </ui.Grid>
          <ui.Grid item xs={12}>
            <Prescriptions prescriptions={state.data.prescriptions} />
          </ui.Grid>
          <ui.Grid item xs={12} style={{ marginTop: ".5rem" }}>
            <Medications
              patientMedications={getIn(
                state.data,
                ["subject", "resource", "medications"],
                []
              )}
              subject={state.data.subject}
              encounterID={state.data.id}
              onSave={ctrl.saveMedication}
              onDelete={ctrl.deleteMedication}
              patientAllergies={getIn(
                state.data,
                ["subject", "resource", "allergies"],
                []
              )}
              isClosed={isClosed}
            />
          </ui.Grid>
          <ui.Grid item xs={12}>
            <MedicationAllergies
              patientAllergies={getIn(
                state.data,
                ["subject", "resource", "allergies"],
                []
              )}
              subject={state.data.subject}
              encounterID={state.data.id}
              onSave={ctrl.saveAllergy}
              onDelete={ctrl.deleteAllergy}
              isClosed={isClosed}
            />
          </ui.Grid>
        </ui.Grid>
        <ui.Grid item md={6} xs={12}>
          {/* <ui.Grid item xs={12}>
            <Chat
              chatId={state.data.chat.id}
              className={classes.chat}
              participantId={state.participantId}
              userLinkId={getIn(state.user, ["link", 0, "link", "id"])}
            />
          </ui.Grid> */}
          {/* <ui.Grid item xs={12}>
              <DocumentReferenceEncounter
              title="Physician Notes"
              onSave={savePhysicianNotes}
              data={data.physicianNotes}
              isClosed={isClosed}
              />
              </ui.Grid> */}
          <ui.Grid item xs={12}>
            <Conditions
              title="Diagnosis"
              patientConditions={state.data.diagnosis.filter((cond) =>
                getIn(cond, [
                  "category",
                  0,
                  "coding",
                  { code: "encounter-diagnosis" },
                  0,
                ])
              )}
              patientID={state.data.subject.id}
              encounterID={state.data.id}
              onSave={ctrl.saveDiagnosis}
              onDelete={ctrl.deleteDiagnosis}
              isClosed={isClosed}
            />
          </ui.Grid>
          {/* <ui.Grid item xs={12}>
            <DocumentReferenceEncounter
              title="Patient Instructions / After Visit Summary"
              onSave={ctrl.saveVisitInstructions}
              data={state.data.visitInstructions || []}
              isClosed={isClosed}
            />
          </ui.Grid> */}
          <ui.Grid item xs={12}>
            {/* <FollowUpMessages
              messages={getIn(state.data, ["chat", "resource", "followUp"], [])}
              ids={{
                chat: state.data.chat.id,
                participant: state.participantId,
                encounter: state.data.id,
                author: getIn(state.user, ["link", 0, "link", "id"]),
              }}
              onSave={ctrl.saveFollowUp}
              onDisable={ctrl.disableFollowUp}
              onEnable={ctrl.enableFollowUp}
              isClosed={isClosed}
            /> */}
          </ui.Grid>
        </ui.Grid>
        {getIn(state, ["data", "subject", "resource", "observations"], [])
          .length > 0 ? (
          <ui.Grid item xs={12}>
            <ObservationTable
              title="Observations"
              observations={state?.data?.subject?.resource?.observations}
            />
          </ui.Grid>
        ) : null}
        {getIn(
          state,
          ["data", "subject", "resource", "questionnaireResponses"],
          []
        ).length > 0 ? (
          <ui.Grid item xs={12}>
            <QuestionnaireResponseTable
              title="Questionnaire Responses"
              responses={state?.data?.subject?.resource?.questionnaireResponses}
            />
          </ui.Grid>
        ) : null}
        <ui.Grid item xs={12} ref={printRef}>
          <BillingCode
            title="Search Billing Codes"
            list={state.assessment.billingCodes || []}
            billingCodes={state.billingCodes}
            onSave={ctrl.handleBillingCode}
            onDelete={ctrl.handleDeleteBillingCode}
            isClosed={isClosed}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <div className={styleUtils.card}>
            <div className={classes.header}>
              <div className={classes.headerTitle}>Patient History</div>
            </div>
            <div>
              {getIn(state.data, ["subject", "resource", "patientHistory"], [])
                .filter((f) => {
                  const start = getIn(f, [
                    "encounter",
                    "resource",
                    "videocalls_as_encounter",
                    "0",
                    "start",
                  ]);

                  return (
                    f.id !== getIn(state.data, ["assessment", 0, "id"]) &&
                    moment().isAfter(start)
                  );
                })
                .sort((a, b) => {
                  return getIn(a, [
                    "encounter",
                    "resource",
                    "videocalls_as_encounter",
                    "0",
                    "start",
                  ]) >
                    getIn(b, [
                      "encounter",
                      "resource",
                      "videocalls_as_encounter",
                      "0",
                      "start",
                    ])
                    ? 1
                    : -1;
                })
                .map((k) => {
                  const start = getIn(k, [
                    "encounter",
                    "resource",
                    "videocalls_as_encounter",
                    "0",
                    "start",
                  ]);
                  const content = (
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                      <RouterLink
                        style={{ fontWeight: "bold" }}
                        to={href(
                          "visits",
                          getIn(k, ["encounter", "resource", "id"])
                        )}
                      >
                        {start
                          ? moment(start).format("MM/DD/YYYY")
                          : "(No date)"}
                      </RouterLink>
                      <div style={{ whiteSpace: "pre-line" }}>
                        {getIn(k, ["patientHistory"], "").replace()}
                      </div>
                    </div>
                  );
                  return content;
                })}
            </div>
          </div>
        </ui.Grid>
        <ui.Grid item xs={12}>
          <AssessmentField
            title={`Visit Notes for ${moment(
              getIn(state, ["data", "videocall", "0", "start"])
            ).format("MM/DD/YYYY")}`}
            stateData={state.data}
            value={state.assessment.patientHistory || ""}
            handleChange={ctrl.handleAssessment}
            name="patientHistory"
            inputType="textarea"
            inputRows={8}
            onBlur={ctrl.saveAssessment}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <AssessmentList
            handle={ctrl.handleAssessment}
            assesments={state.assessment}
            onBlur={ctrl.saveAssessment}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <AssessmentField
            handleChange={ctrl.handleAssessment}
            title="Physician Summary"
            value={state.assessment.physicianSummary || ""}
            inputType="textarea"
            name="physicianSummary"
            inputRows={8}
            onBlur={ctrl.saveAssessment}
          ></AssessmentField>
        </ui.Grid>
        <ui.Grid item xs={12}>
          <ui.Button
            variant="contained"
            color="primary"
            disabled={state.isSaving}
            onClick={ctrl.saveAssessment}
          >
            Save assessment
          </ui.Button>
        </ui.Grid>
      </ui.Grid>
      <DoseSpotUI {...state.modalState} />

      <ui.Snackbar
        message={state.snackBar.message}
        open={state.snackBar.open}
        autoHideDuration={5000}
        onClose={ctrl.closeSnackBar}
      ></ui.Snackbar>
    </>
  );
}

export default VideoVisit;
