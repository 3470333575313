import React, { useEffect } from "react";
import { produce } from "immer";
import moment from "moment";
import { teal, deepPurple } from "@material-ui/core/colors";
import Feedback from "./Feedback";
import useGlobalError from "../../hooks/useGlobalError";
import { StoreLayer, Store } from "../../Store";
import { ControllerLayer } from "../../Controller";
import { useAuthContext } from "../../components/AuthContext";
import * as aidbox from "../../utils/aidbox";
import { getIn, href } from "../../utils/utils";

const store = new Store(
  {
    isLoading: true,
    chatMessages: [],
    chatVersion: -1,
    chatParticipant: null,
    chatParticipants: null,
  },
  produce
);

function FeedbackLayer() {
  const { user } = useAuthContext();
  const { addError } = useGlobalError();

  store.transform((state) => {
    state.user = user;
  });

  const pageController = {
    onSend: async (msgs) => {
      if (!msgs.text) return;
      const msg = await aidbox.createResource({
        resourceType: `Chat/support/$send-message`,
        text: msgs.text,
        from: {
          id: store.value.chatParticipant.id,
          resourceType: "ChatParticipant",
        },
      });

      store.transform((state) => {
        state.chatMessages.push({
          id: msg.data.id,
          text: msgs.text,
          createdAt: moment(new Date()).format("h:mm A"),
          user: {
            id: store.value.chatParticipant.id,
            resourceType: "ChatParticipant",
            color: deepPurple[500],
            name: store.value.chatParticipant?.name
              ? store.value.chatParticipant?.name
              : store.value.chatParticipant?.userName
              ? store.value.chatParticipant?.userName
              : store.value.chatParticipant?.user,
            avatar: !window.AIDBOX_URL
              ? href(
                  "static",
                  "physician-app",
                  "static",
                  "images",
                  "avatar.png"
                )
              : `${window.AIDBOX_URL}${href(
                  "static",
                  "physician-app",
                  "static",
                  "images",
                  "avatar.png"
                )}`,
          },
        });
      });
    },
    fetchMessages: async () => {
      if (store.value.chatVersion === -1) {
        const { version } = (
          await aidbox.getPlain(`/ChatMessage/$changes`)
        ).data;
        store.transform((state) => {
          state.chatVersion = version;
        });
        const { data } = await aidbox.readResource("ChatMessage", {
          _count: 1000,
          ".chat.id": "support",
          _sort: "-createdAt",
        });
        const chatMessages = data.entry.map((e) => e.resource);

        const msgs = chatMessages.map((resource) => {
          const userId =
            resource.from.id ||
            `${resource.from.identifier.system}/${resource.from.identifier.value}`;
          const cp = getIn(
            store.value.chatParticipants,
            [(u) => u?.id === resource.from.id, 0],
            store.value.chatParticipant
          );
          return {
            id: resource.id,
            text: resource.text,
            createdAt: moment(
              resource.datetime || resource.meta.createdAt
            ).format("h:mm A"),
            user: {
              id: resource.from.id,
              name: cp?.name
                ? cp?.name
                : cp?.userName
                ? cp?.userName
                : cp?.user,
              color:
                cp.id === store.value.chatParticipant.id
                  ? deepPurple[500]
                  : teal[500],
              avatar:
                userId === "supdoc:bot/chat-bot"
                  ? href(
                      "static",
                      "physician-app",
                      "static",
                      "images",
                      "bot-avatar.png"
                    )
                  : !window.AIDBOX_URL
                  ? href(
                      "static",
                      "physician-app",
                      "static",
                      "images",
                      "avatar.png"
                    )
                  : `${window.AIDBOX_URL}${href(
                      "static",
                      "physician-app",
                      "static",
                      "images",
                      "avatar.png"
                    )}`,
            },
          };
        });
        store.transform((state) => {
          state.chatMessages = msgs.reverse();
        });
      } else {
        let data = {};
        try {
          data = await aidbox.readResource("ChatMessage/$changes", {
            _count: 1000,
            version: store.value.chatVersion,
            ".chat.id": "support",
          });
        } catch (err) {
          aidbox.sendErrorLogs({ data: err });
        }
        const msgs = data.data?.changes
          ?.filter(({ event }) => event === "created")
          .map(({ resource }) => {
            const cp = getIn(
              store.value.chatParticipants,
              [(u) => u?.id === resource.from.id, 0],
              store.value.chatParticipant
            );
            const userId =
              resource.from.id ||
              `${resource.from.identifier.system}/${resource.from.identifier.value}`;
            return {
              id: resource.id,
              text: resource.text,
              createdAt: moment(resource.meta.createdAt).format("h:mm A"),
              user: {
                id: userId,
                name: cp?.name
                  ? cp?.name
                  : cp?.userName
                  ? cp?.userName
                  : cp?.user,
                color:
                  cp.id === store.value.chatParticipant.id
                    ? deepPurple[500]
                    : teal[500],
                avatar: !window.AIDBOX_URL
                  ? href(
                      "static",
                      "physician-app",
                      "static",
                      "images",
                      "avatar.png"
                    )
                  : `${window.AIDBOX_URL}${href(
                      "static",
                      "physician-app",
                      "static",
                      "images",
                      "avatar.png"
                    )}`,
              },
            };
          });
        if (msgs) {
          const msgIds = new Set(
            store.value.chatMessages.map((message) => message.id)
          );
          store.transform((state) => {
            state.chatVersion = data.data.version;
            state.chatMessages = [
              ...store.value.chatMessages,
              ...msgs.filter((msg) => !msgIds.has(msg.id)),
            ];
          });
        }
      }
    },
  };

  useEffect(() => {
    const fetchPatricipant = async () => {
      if (!store.value.chatParticipants) {
        console.log("fetch participant");
        try {
          const response = await aidbox.getPlain(
            "/Chat/support/$get-participants"
          );
          store.transform((state) => {
            state.chatParticipant = getIn(response, [
              "data",
              (u) => u?.user === store.value.user.id,
              0,
            ]);
            state.chatParticipants = getIn(response, [
              "data",
              (u) => u?.user !== store.value.user.id,
            ]);
          });
        } catch (err) {
          console.log(err);
          addError(`LOAD_DATA_ERROR: ${err}`, err.response.status);
        }
      }
    };
    fetchPatricipant().then(() => {
      pageController.fetchMessages();
      store.transform((state) => {
        state.isLoading = false;
      });
    });
  }, []);

  return (
    <ControllerLayer value={pageController}>
      <StoreLayer store={store}>
        <Feedback />
      </StoreLayer>
    </ControllerLayer>
  );
}

export default FeedbackLayer;
