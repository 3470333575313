import React from "react";
import { request, sendErrorLogs } from "../utils/aidbox";

function useQuery({ url, method, data }) {
  const [state, setState] = React.useState({ loading: true, url: url });
  function refetch(url = state.url) {
    setState({
      loading: true,
    });
    request({ url: url, method, data })
      .then((res) => {
        setState({
          loading: false,
          data: res.data?.data || res.data || res,
          refetch,
        });
      })
      .catch((err) => {
        sendErrorLogs({ data: err });
        console.log(err);
        setState({
          loading: false,
          error: err.response.data,
          refetch,
        });
      });
  }

  React.useEffect(() => {
    refetch();
  }, []);

  return state;
}

export default {
  useQuery,
};
