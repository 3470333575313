import React from "react";
import VisitTable from "../components/VisitTable";
import { useAuthContext } from "../components/AuthContext";
import * as aidbox from "../utils/aidbox";
import { getIn } from "../utils/utils";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import Alert from "../components/Errors/Alert";

function ReviewEncountersPage() {
  const [error, setError] = React.useState(false);

  if (error)
    return (
      <Alert
        severity="error"
        error="Something went wrong. If this persists, contact Recuro at support@recurohealth.com."
      />
    );
  return (
    <div>
      <EncountersForReviewList />
      <EncountersReviewedList />
    </div>
  );
}

export default ReviewEncountersPage;

function EncountersReviewedList() {
  const { user, userCan, practitioner } = useAuthContext();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  async function fetchData() {
    setLoading(false);
    try {
      const resp = await aidbox.postPlain(
        "/$graphql-query/physician-app-homepage",
        {
          variables: {
            status: "finished",
            reviewedBy: user.id,
            reviewStatus: "review-finished",
          },
        }
      );
      if (resp.status !== 200) {
        throw new Error("Something went wrong.");
      }

      setData(getIn(resp, ["data", "data", "new"], []));
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  if (data.length === 0) return null;

  return (
    userCan("phys-app-set-encounter-status-complete") && (
      <ErrorBoundary>
        <VisitTable
          fetchData={fetchData}
          tableTitle="Visits You Reviewed"
          rows={data}
        />
      </ErrorBoundary>
    )
  );
}

function EncountersForReviewList() {
  const { user, userCan, practitioner } = useAuthContext();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  async function fetchData() {
    setLoading(false);
    try {
      const resp = await aidbox.postPlain(
        "/$graphql-query/physician-app-homepage",
        {
          variables: {
            status: "in-progress",
            locationId: practitioner.practiceLocations?.join(",") || "",
            reviewStatus: "ready-for-review",
          },
        }
      );
      if (resp.status !== 200) {
        throw new Error("Something went wrong.");
      }

      setData(getIn(resp, ["data", "data", "new"], []));
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  if (data.length === 0) return null;

  return (
    userCan("phys-app-set-encounter-status-complete") && (
      <ErrorBoundary>
        <VisitTable
          fetchData={fetchData}
          tableTitle="Visits for Review"
          rows={data}
        />
      </ErrorBoundary>
    )
  );
}
