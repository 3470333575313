import React from "react";
import * as ui from "@material-ui/core";
import { useController } from "../../Controller";
import { useStore } from "../../Store";
import HsChat from "../../components/HsChat/HsChat";
import { href } from "../../utils/utils";

const useStyles = ui.makeStyles({
  chat: {
    height: "200px",
  },
  container: {
    position: "relative",
  },
  loader: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,.8)",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});

function Feedback() {
  const classes = useStyles();
  const ctrl = useController();
  const [state] = useStore();
  React.useEffect(() => {
    ctrl.fetchMessages();
    const id = setInterval(ctrl.fetchMessages, 5000);
    return () => {
      clearInterval(id);
    };
  }, []);
  return (
    <ui.Grid className={classes.container}>
      <HsChat
        user={{
          id: state.chatParticipant?.id,
          resourceType: "ChatParticipant",
          name: state.chatParticipant?.name
            ? state.chatParticipant?.name
            : state.chatParticipant?.userName
            ? state.chatParticipant?.userName
            : state.chatParticipant?.user,
          avatar: !window.AIDBOX_URL
            ? href("static", "physician-app", "static", "images", "avatar.png")
            : `${window.AIDBOX_URL}${href(
                "static",
                "physician-app",
                "static",
                "images",
                "avatar.png"
              )}`,
        }}
        messages={state.chatMessages}
        onSend={(msgs) => ctrl.onSend(msgs)}
        title="Share you feedback"
      />
      {state.isLoading ? (
        <div className={classes.loader}>
          <ui.CircularProgress />
        </div>
      ) : null}
    </ui.Grid>
  );
}

export default Feedback;
