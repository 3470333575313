import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import mainTheme from "../styles/mainTheme";
import { AuthContextProvider } from "./AuthContext";
import AppRouter from "../routes";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_APIKEY,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ["production", "staging"],
  plugins: [new BugsnagPluginReact()],
  appType: "Physician App",
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const ErrorView = () => (
  <div>
    <p>There is an error with your application. If problems persist, please contact support at info@recurohealth.com.</p>
  </div>
);

function App() {
  return (
    <AuthContextProvider>
      <ThemeProvider theme={mainTheme}>
        <AppRouter />
      </ThemeProvider>
    </AuthContextProvider>
  );
}

export default () => (
  <ErrorBoundary FallbackComponent={ErrorView}>
    <App />
  </ErrorBoundary>
);
