import React, { useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { helpers, buttons } from "../styles/index";
import hooks from "../hooks";
import { getIn, href } from "../utils/utils";
import * as vars from "../styles/vars";
import Alert from "../components/Errors/Alert";
import Spinner from "../components/Spinner";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import { useAuthContext } from "../components/AuthContext";
import { request, search, sendErrorLogs } from "../utils/aidbox";

const useStyles = makeStyles({
  root: {
    height: "min-content",
  },
  patientList: {
    display: "grid",
    gridRowGap: "1rem",
  },
  patient: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: "1rem",
    alignItems: "center",
    borderBottom: `2px solid ${vars.brown500}`,
    padding: ".5rem 0",
  },
  text: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
});

function PatientListPage() {
  const utils = helpers();
  const btns = buttons();
  const classes = useStyles();

  const [dataFiltered, setDataFiltered] = useState([]);
  const { practitioner } = useAuthContext();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const timeout = React.useRef(null);

  const getFilteredData = () => {
    const filtered = data.patients
      .filter((patient) => {
        const doc =
          getIn(
            patient,
            [
              "encounters",
              (enc) =>
                getIn(enc, ["participant", 0, "individual", "id"]) ==
                practitioner.id,
            ],
            []
          ).length > 0;
        return !!doc;
      })
      .sort((a, b) => {
        console.log(a, b);
        return getIn(a, ["name", 0, "family"], "") >
          getIn(b, ["name", 0, "family"], "")
          ? 1
          : -1;
      });
    return filtered;
  };

  const callGetPatientList = (search) => {
    setLoading(true);
    request({
      url: "/$graphql-query/physician-app-patient-searchlist",
      method: "POST",
      data: {
        variables: {
          search: search,
          organization: practitioner.physicianAppOrganization
        },
      },
    })
      .then((res) => {
        setLoading(false);
        setData(res.data ? res.data.data : res.data);
      })
      .catch((err) => {
        sendErrorLogs({ data: err });
        console.log(err);
        setLoading(false);
        setError(err.response.data);
      });
  };

  useEffect(() => {
    if (!searchText) {
      callGetPatientList("");
    }
    if (searchText.length >= 2) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        callGetPatientList(searchText);
      }, 300);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [searchText]);

  useEffect(() => {
    if (!data) return;
    const filtered = getFilteredData();
    setDataFiltered(filtered);
  }, [data]);

  useEffect(() => {
    callGetPatientList("");
  }, []);

  if (error)
    return (
      <Alert
        severity="error"
        error="Something went wrong. If this persists, contact Recuro at support@recurohealth.com."
      />
    );
  return (
    <ErrorBoundary>
      <div className={clsx(utils.card, classes.root)}>
        <div>
          <TextField
            style={{
              width: "100%",
              marginBottom: 10,
              marginTop: 10,
              maxWidth: 300,
            }}
            label="Search by first or last name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        {loading && <Spinner />}

        {!loading && (
          <div className={classes.patientList}>
            {(dataFiltered || []).map((patient) => (
              <div className={classes.patient} key={patient.id}>
                <div className={classes.text}>
                  {getIn(patient, ["name", 0, "given", 0])}{" "}
                  {getIn(patient, ["name", 0, "family"])}
                  {", "}
                  {getIn(patient, ["gender"])}
                  {", "}
                  {patient.birthDate
                    ? `${moment().diff(patient.birthDate, "years")} y.o.`
                    : null}{" "}
                  {`(${moment(patient.birthDate).format("MM/DD/YYYY")})`}
                </div>
                <RouterLink
                  to={href("patients", patient.id)}
                  className={clsx(btns.btn, btns.btnLink)}
                >
                  Go to patient page
                </RouterLink>
              </div>
            ))}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default PatientListPage;
