import React, { Component } from "react";
import Alert from "./Alert";
import { sendErrorLogs } from "../../utils/aidbox";
import { GlobalErrorContext } from "../../contexts/GlobalErrorContext";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    sendErrorLogs({ data: error });
    return {
      hasError: true,
    };
  }

  render() {
    const value = this.context;
    if (this.state.hasError || value.error) {
      return (
        <Alert
          error="Something went wrong. If this persists, contact Recuro at support@recurohealth.com."
          severity="error"
        />
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.contextType = GlobalErrorContext;
export default ErrorBoundary;
