import axios from "axios";
import axiosRetry from "axios-retry";
import { getParams, mergeParams } from "./redirect";
import Bugsnag from "@bugsnag/js";

export const { AIDBOX_URL } = window;

let token = window.localStorage.getItem("accesstoken");

const axiosConfig = {
  "axios-retry": {
    retries: 1,
  },
};

const http = () => {
  const client = axios.create({
    baseURL: AIDBOX_URL,
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  axiosRetry(client, {
    retries: 6,
    retryDelay: (retryCount) => {
      return retryCount * 1000;
    },
  });

  return client;
};

export const redirectToLogin = () => {
  setTimeout(
    window.location.replace(
      `${AIDBOX_URL || ""}/auth/authorize?client_id=app&response_type=code`
    ),
    0
  );
};

export const logout = () => {
  http()
    .delete("/Session")
    .then(() => redirectToLogin())
    .catch(() => redirectToLogin());
  window.localStorage.removeItem("accesstoken");
};

export const getUserInfo = async () => {
  const { code } = getParams();
  if (code !== undefined) {
    return axios
      .create({
        baseURL: AIDBOX_URL,
        headers: { "Content-type": "application/json" },
      })
      .post("/auth/token", {
        client_id: "app",
        code,
        grant_type: "authorization_code",
      })
      .then((resp) => {
        localStorage.setItem("accesstoken", resp.data.access_token);
        token = localStorage.getItem("accesstoken");
        return mergeParams({ code: null });
      })
      .catch((e) => {
        Bugsnag.notify(e);
      });
  }

  return http()
    .get("/auth/userinfo")
    .catch((e) => Bugsnag.notify(e));
};

export const readResource = (resourceType, searchParams = {}) => {
  return http().get(`/${resourceType}`, {
    ...axiosConfig,
    params: { _format: "json", ...searchParams },
  });
};

export const request = (config) => {
  return http().request({ ...config, ...axiosConfig });
};

export const getPlain = (path) => {
  return http().get(path, axiosConfig);
};

export const postPlain = (path, resource) => {
  return http().post(path, resource, axiosConfig);
};
export const deletePlain = (path) => {
  return http().delete(path, axiosConfig);
};
export const putPlain = (path, body) => {
  return http().put(path, body);
};

export const patchPlain = (path, body) => {
  return http().patch(path, body);
};

export const getResource = (resourceType, id) => {
  return http().get(`/${resourceType}/${id}`, axiosConfig);
};

export const search = (resourceType, params) => {
  return http().get(`/${resourceType}`, {
    ...params,
    ...axiosConfig,
  });
};

export const createResource = (resource) => {
  return http().post(`/${resource.resourceType}`, resource, axiosConfig);
};

export const patchResource = (resource) => {
  return http().patch(
    `/${resource.resourceType}/${resource.id}`,
    resource,
    axiosConfig
  );
};

export const sendErrorLogs = (data) => {
  return http().post("/$loggy", data, axiosConfig);
};

export default {
  logout,
  redirectToLogin,
  http,
  request,
  readResource,
  getResource,
  getPlain,
  postPlain,
  search,
  createResource,
  getUserInfo,
  patchResource,
};
