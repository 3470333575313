import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { BrandColors } from "../../styles/vars";
import SpeechInput from "../SpeechInput/SpeechInput";

const assesments = [
  { heading: "Appearance", value: "", name: "appearance", id: "appearance" },
  { heading: "Behavior", value: "", name: "behavior", id: "behavior" },
  { heading: "Speech", value: "", name: "speech", id: "speech" },
  { heading: "Mood/Affect", value: "", name: "mood", id: "mood" },
  { heading: "Thought Content", value: "", name: "thought", id: "thought" },
  {
    heading: "Suicidal/Homicidal Thoughts",
    value: "",
    name: "suicidal",
    id: "suicidal",
  },
  { heading: "Thought Process", value: "", name: "process", id: "process" },
  {
    heading: "Associations",
    value: "",
    name: "associations",
    id: "associations",
  },
  {
    heading: "Fund of Knowledge",
    value: "",
    name: "fund_of_knowledge",
    id: "fund_of_knowledge",
  },
  {
    heading: "Attention/Concentration",
    value: "",
    name: "attention",
    id: "attention",
  },
  { heading: "Insight", value: "", name: "insight", id: "insight" },
  { heading: "Judgment", value: "", name: "judgment", id: "judgment" },
  { heading: "Memory", value: "", name: "memory", id: "memory" },
  {
    heading: "Motor Status",
    value: "",
    name: "motorStatus",
    id: "motorStatus",
  },
  { heading: "Orientation", value: "", name: "orientation", id: "orientation" },
];

function AssessmentList(props) {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      padding: ".75rem",
      backgroundColor: "white",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      marginBottom: ".75rem",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    assesments: {
      marginTop: "1rem",
    },
    assesment: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "1rem",
      alignItems: "center",
      flexFlow: "row wrap",
    },
    assesmentLabel: {
      flex: 1,
      minWidth: "240px",
    },
    assesmentInput: {
      flex: 4,
      minWidth: "400px",
    },
  }));
  const classes = useStyles();

  const memoAssesments = useMemo(
    () =>
      assesments.map((assesment) => ({
        ...assesment,
        value: props?.assesments[assesment.id] || assesment.value,
      })),
    [props]
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>Patient Assessment</div>
      </div>
      <div className={classes.assesments}>
        {memoAssesments.map((assesment) => (
          <div className={classes.assesment} key={assesment.id}>
            <h3 className={classes.assesmentLabel}>{assesment.heading}</h3>
            <div className={classes.assesmentInput}>
              <SpeechInput
                handleChange={props.handle}
                heading={`${assesment.heading} notes`}
                value={assesment.value || ""}
                inputName={assesment.name}
                onKeyDown={props.onKeyDown}
                inputType="input"
                onBlur={props.onBlur}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

AssessmentList.propTypes = {
  handle: PropTypes.func.isRequired,
};

export default AssessmentList;
