import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { helpers } from "../styles";
import SpeechInput from "./SpeechInput/SpeechInput";
import { BrandColors } from "../styles/vars";

function AssessmentField({
  title,
  heading,
  name,
  value,
  handleChange,
  inputType,
  additionalData,
  autoStart,
  inputRows,
  onBlur,
}) {
  const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    container: {
      position: "relative",
      padding: ".5rem 0",
    },
    submit: {
      display: "flex",
      alignItems: "flex-end",
      "& button": {
        width: "100%",
        fontWeight: "bold",
        height: "35px",
      },
    },
    additional: {
      display: "flex",
      padding: ".5rem 0",
      flexDirection: "column",
      "& div + div": {
        marginTop: ".25rem",
      },
    },
  }));
  const classes = useStyles();
  const styleUtils = helpers();

  const clearField = (e) => {
    e.target.value = "";
    handleChange(e);
  };

  const handleKeyDown = (e) => e.keyCode === 27 && clearField(e);
  return (
    <div className={styleUtils.card}>
      <div className={classes.header}>
        <div className={classes.headerTitle}> {title} </div>{" "}
      </div>
      <div className={classes.additional}>
        {(additionalData || []).map((data) => (
          <div> {data} </div>
        ))}
      </div>
      <div className={classes.container}>
        <SpeechInput
          handleChange={handleChange}
          heading={heading}
          inputName={name}
          value={value}
          autoStart={autoStart}
          onKeyDown={handleKeyDown}
          inputType={inputType}
          inputRows={inputRows}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
}

AssessmentField.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AssessmentField;
