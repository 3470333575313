import React from "react";
import { useStore } from "../../Store";
import { useController } from "../../Controller";
import CircularLoading from "../../utils/loading";
import Alert from "../../components/Errors/Alert";
import { getIn } from "../../utils/utils";
import Visit from "./Visit";
import VideoVisit from "./VideoVisit";

export default function VisitProcess() {
  const [state] = useStore();
  const ctrl = useController();
  if (state.isLoading || !state.data) return <CircularLoading />;

  if (state.error || !state.data)
    return (
      <Alert error="Please contact SupDoc techical support" severity="error" />
    );

  // if (
  //   getIn(state?.data, ["participant", 0, "individual", "id"]) !==
  //     getIn(state.user, ["link", 0, "link", "id"]) &&
  //   state.data
  // ) {
  //   return <Alert error="Visit not found" severity="error" />;
  // }
  if (!state.data?.subject) {
    return <Alert severity="error" error="Patient not found" />;
  }

  if (state?.data && state.data?.status === "arrived") {
    ctrl.updateEncounter();
  }
  if (!state.data) {
    return <Alert severity="error" error="Visit not found" />;
  }
  // TODO: move code to constant
  const isVideo =
    getIn(state.data, ["serviceType", "coding", 0, "code"]) === "259";

  return <>{isVideo ? <VideoVisit /> : <Visit />}</>;
}
