import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  Grid,
  Snackbar,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import clsx from "clsx";
import { Link as RouterLink, Redirect, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import DateTime from "react-datetime";
import { helpers } from "../styles/index";
import { getIn } from "../utils/utils";
import aidbox, { postPlain } from "../utils/aidbox";
import * as vars from "../styles/vars";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import AutocompleteAsync from "../components/AutocompleteAsync";
import { useAuthContext } from "../components/AuthContext";

function NewEditVisitPage({ location }) {
  const [alertBar, setAlertBar] = useState(false);
  const [redirect, setRedirect] = useState("");
  const { user } = useAuthContext();
  const params = useRef(new URLSearchParams(location.search));

  const validationSchema = yup.object({
    videoCallStartTime: yup
      .date("This must be a valid date")
      .required("Visit start time is required"),
    videoCallEndTime: yup
      .date("This must be a valid date")
      .when("videoCallStartTime", (videoCallStartTime, schema) => {
        return (
          videoCallStartTime &&
          schema.min(
            moment(videoCallStartTime).add(1, "minutes"),
            "Visit end time must be after visit start time."
          )
        );
      })
      .required("Visit end time is required."),
    patient: yup.mixed().required("Patient is required."),
    practitioner: yup.mixed().required("Doctor is required."),
    chiefComplaint: yup.string().required("Chief Complaint is required."),
  });

  const patientId = params.current.get("patient");

  useEffect(() => {
    if (patientId) {
      (async () => {
        const patient = await aidbox.getResource("Patient", patientId);
        formik.setFieldValue("patient", {
          name: `${getIn(patient, ["data", "name", 0, "given", 0])} ${getIn(
            patient,
            ["data", "name", 0, "family"]
          )} - ${moment(getIn(patient, ["data", "birthDate"])).format(
            "MM/DD/YYYY"
          )}`,
          label: patient.data.id,
        });
      })(patientId);
    }
  }, [patientId]);

  const formik = useFormik({
    initialValues: {
      videoCallStartTime: "",
      videoCallEndTime: "",
      patient: null,
      practitioner: null,
      sendEmail: true,
      chiefComplaint: "Behavioral",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        console.log("Tring");
        const resp = await aidbox.postPlain(
          "/Encounter/$create-video-encounter",
          {
            patientID: values.patient.label,
            practitionerID: values.practitioner.label,
            videoCallStartTime: moment(values.videoCallStartTime),
            videoCallEndTime: moment(values.videoCallEndTime),
            chiefComplaint: values.chiefComplaint,
            sendEmail: values.sendEmail,
          }
        );
        const code = getIn(resp, ["status"]);
        console.log(resp);
        if (code === 200) {
          if (user && user.roleName === "scheduler") {
            setRedirect("/");
          }
          else {
            setRedirect("/visits");
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        setAlertBar(true);
      }
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "min-content",
    },
    patientList: {
      display: "grid",
      gridRowGap: "1rem",
    },
    patient: {
      display: "grid",
      gridTemplateColumns: "1fr max-content",
      gridGap: "1rem",
      alignItems: "center",
      borderBottom: `2px solid ${vars.brown500}`,
      padding: ".5rem 0",
    },
    text: {
      textTransform: "capitalize",
      fontWeight: "bold",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    formRow: {
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const utils = helpers();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <ErrorBoundary>
      <div className={clsx(utils.card, classes.root)}>
        <Typography style={{ marginBottom: 10 }} variant="h6">
          Create a New Visit
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Grid container item spacing={3} xs={6}>
            <Grid item xs={12}>
              <AutocompleteAsync
                error={formik.touched.patient && Boolean(formik.errors.patient)}
                value={formik.values.patient}
                label="Patient Search"
                onChange={(e, value) => {
                  formik.setFieldValue("patient", value);
                }}
                queryFunction={(value) =>
                  aidbox
                    .http()
                    .get(`/Patient/$scheduler-search?search=${value}`, {
                      params: { _count: 1000 },
                    })
                }
                setOptions={(response) => {
                  return response.data.entry.map((item) => {
                    const name = `${getIn(
                      item,
                      ["resource", "name", 0, "given", 0],
                      ""
                    )} ${getIn(
                      item,
                      ["resource", "name", 0, "family"],
                      ""
                    )} - ${moment(
                      getIn(item, ["resource", "birthDate"])
                    ).format("MM/DD/YYYY")}`;
                    const label = getIn(item, ["resource", "id"], "");

                    return { name, label };
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompleteAsync
                value={formik.values.practitioner}
                onChange={(e, value) => {
                  formik.setFieldValue("practitioner", value);
                }}
                error={
                  formik.touched.practitioner &&
                  Boolean(formik.errors.practitioner)
                }
                label="Doctor Search"
                queryFunction={() => aidbox.http().get("/Practitioner")}
                setOptions={(response) => {
                  return response.data.entry.map((item) => {
                    const name = `${getIn(
                      item,
                      ["resource", "name", 0, "given", 0],
                      ""
                    )} ${getIn(item, ["resource", "name", 0, "family"], "")}`;
                    const label = getIn(item, ["resource", "id"], "");

                    return { name, label };
                  });
                }}
              />
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="chiefComplaint"
                  id="chiefComplaint"
                  label="Chief Complaint"
                  variant="outlined"
                  value={formik.values.chiefComplaint}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.chiefComplaint &&
                    Boolean(formik.errors.chiefComplaint)
                  }
                  helperText={
                    formik.touched.chiefComplaint &&
                    formik.errors.chiefComplaint
                  }
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={3} xs={12}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="datetime-local"
                  variant="outlined"
                  label="Visit Start Time"
                  inputProps={{ min: moment().format('YYYY-MM-DDTHH:mm') }}
                  error={
                    formik.touched.videoCallStartTime &&
                    Boolean(formik.errors.videoCallStartTime)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    formik.touched.videoCallStartTime &&
                    formik.errors.videoCallStartTime
                  }
                  value={formik.values.videoCallStartTime}
                  onChange={(event) => {
                    const value = event.target.value;
                    formik.setFieldValue("videoCallStartTime", value);
                    formik.setFieldValue(
                      "videoCallEndTime",
                      moment(value).add(15, "minutes").format('YYYY-MM-DDTHH:mm')
                    );
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="datetime-local"
                  variant="outlined"
                  label="Visit End Time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: moment().format('YYYY-MM-DDTHH:mm') }}
                  value={formik.values.videoCallEndTime}
                  error={
                    formik.touched.videoCallEndTime &&
                    Boolean(formik.errors.videoCallEndTime)
                  }
                  helperText={
                    formik.touched.videoCallEndTime &&
                    formik.errors.videoCallEndTime
                  }
                  onChange={(event) => {
                    const value = event.target.value;
                    formik.setFieldValue("videoCallEndTime", value);
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    label="Email patient with visit details?"
                    control={
                      <Checkbox
                        onChange={formik.handleChange}
                        checked={formik.values.sendEmail}
                        name="sendEmail"
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          message="There was an error. If this happens again, please contact SupDoc support."
          open={alertBar}
          autoHideDuration={5000}
          onClose={() => setAlertBar(false)}
        ></Snackbar>
      </div>
    </ErrorBoundary>
  );
}
export default NewEditVisitPage;

