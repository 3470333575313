import React from "react";
import { Switch } from "react-router";
import { HashRouter as Router, Route } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonAdd from "@material-ui/icons/PersonAdd";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ForumIcon from "@material-ui/icons/Forum";
import SettingsIcon from "@material-ui/icons/Settings";
import FeedbackIcon from "@material-ui/icons/Feedback";
import LocalPhone from "@material-ui/icons/LocalPhone";
import CheckBox from "@material-ui/icons/CheckBox";
import Schedule from "@material-ui/icons/Schedule";
import HomePage from "./pages/HomePage";
import Error404 from "./components/Errors/404";
import VisitsPage from "./pages/VisitsPage";
import VisitsPageBilling from "./pages/VisitsPageBilling";
import MessageCenterPage from "./pages/MessageCenterPage";
import PatientListPage from "./pages/PatientListPage";
import PatientPage from "./pages/PatientPage";
import SchedulerPage from "./pages/SchedulerPage";
import VisitLayer from "./pages/Visit/VisitLayer";
import FeedbackLayer from "./pages/Feedback/FeedbackLayer";
import SettingsLayer from "./pages/Settings/SettingsLayer";
import { useAuthContext } from "./components/AuthContext";
import PrivateRoute from "./PrivateRoute";
import VisitBilling from "./pages/Visit/VisitBilling";
import PatientListPageScheduler from "./pages/PatientListPageScheduler";
import PatientPageScheduler from "./pages/PatientPageScheduler";
import NewEditPatientPage from "./pages/NewEditPatientPage";
import NewEditVisitPage from "./pages/NewEditVisitPage";
import NewPhoneVisitPage from "./pages/NewPhoneVisitPage";
import ReviewEncountersPage from "./pages/ReviewEncountersPage";
import SurveyAdministrationPage from "./pages/SurveyAdministrationPage";
import permissions from "./constants/Permissions";
import Home from "@material-ui/icons/Home";

const routes = [
  {
    path: `/`,
    title: "Home",
    exact: true,
    sidebar: true,
    component: HomePage,
    icon: HomeIcon,
    roles: ["practitioner"],
  },
  {
    path: `/visits`,
    title: "Visits",
    exact: true,
    sidebar: true,
    component: VisitsPage,
    icon: ListAltIcon,
    roles: ["practitioner"],
  },
  {
    path: `/`,
    title: "Visits",
    exact: true,
    sidebar: true,
    component: VisitsPageBilling,
    icon: ListAltIcon,
    roles: ["biller"],
  },
  {
    path: `/`,
    title: "Patients",
    exact: true,
    sidebar: true,
    component: PatientListPageScheduler,
    icon: PeopleAltIcon,
    roles: ["scheduler"],
  },
  {
    path: `/visits/:id/`,
    exact: true,
    sidebar: false,
    component: VisitLayer,
    roles: ["practitioner"],
  },
  {
    path: `/visits/:id/`,
    exact: true,
    sidebar: false,
    component: VisitBilling,
    roles: ["biller"],
  },
  {
    path: "/review-encounters",
    title: "Review Visits",
    exact: true,
    sidebar: true,
    component: ReviewEncountersPage,
    roles: ["practitioner"],
    icon: CheckBox,
    permissions: [permissions.SET_ENCOUNTER_STATUS_COMPLETE],
  },
  {
    path: `/message-center/`,
    title: "Message Center",
    exact: true,
    sidebar: true,
    component: MessageCenterPage,
    icon: ForumIcon,
    permissions: [permissions.VIEW_MESSAGE_CENTER_PAGE],
    roles: ["practitioner"],
  },
  {
    path: `/patients/`,
    title: "Patients",
    exact: true,
    sidebar: true,
    component: PatientListPage,
    icon: PeopleAltIcon,
    permissions: [permissions.VIEW_PATIENTS_LIST_PAGE],
    roles: ["practitioner"],
  },
  {
    path: `/patients/:id/`,
    exact: true,
    sidebar: false,
    component: PatientPage,
    roles: ["practitioner"],
  },
  {
    path: `/patients/:id/`,
    exact: true,
    sidebar: false,
    component: PatientPageScheduler,
    roles: ["scheduler"]
  },
  {
    path: `/visits-new/:id/`,
    exact: true,
    sidebar: false,
    component: VisitLayer,
    roles: ["practitioner"],
    permissions: [permissions.CREATE_NEW_VISIT],
  },
  {
    path: "/settings",
    title: "Settings",
    exact: true,
    sidebar: true,
    icon: SettingsIcon,
    component: SettingsLayer,
    roles: ["practitioner"],
  },
  {
    path: "/feedback",
    title: "Feedback",
    exact: true,
    sidebar: true,
    icon: FeedbackIcon,
    component: FeedbackLayer,
    roles: ["practitioner"],
    permissions: [permissions.VIEW_FEEDBACK_PAGE],
  },
  {
    path: "/scheduler",
    title: "Scheduler",
    exact: true,
    sidebar: true,
    icon: Schedule,
    component: SchedulerPage,
    roles: ["practitioner", "scheduler"],
    permissions: [permissions.VIEW_SCHEDULE_PAGE],
  },
  {
    path: "/new-patient",
    title: "New Patient",
    exact: true,
    sidebar: true,
    component: NewEditPatientPage,
    roles: ["practitioner", "scheduler"],
    icon: PersonAdd,
    permissions: [permissions.CREATE_NEW_PATIENT],
  },
  {
    path: "/edit-patient",
    title: "New Patient",
    exact: true,
    sidebar: false,
    component: NewEditPatientPage,
    roles: ["practitioner", "scheduler"],
    icon: PersonAdd,
    permissions: [permissions.CREATE_NEW_PATIENT],
  },
  {
    path: "/new-visit",
    title: "New Visit",
    exact: true,
    sidebar: true,
    component: NewEditVisitPage,
    roles: ["practitioner", "scheduler"],
    icon: InsertInvitation,
    permissions: [permissions.CREATE_NEW_VISIT],
  },
  {
    path: "/",
    title: "New Phone Visit",
    exact: true,
    sidebar: true,
    component: NewPhoneVisitPage,
    roles: ["call-center-agent"],
    icon: LocalPhone,
  },
  {
    path: "/survey-administration",
    title: "Surveys",
    exact: true,
    sidebar: true,
    component: SurveyAdministrationPage,
    roles: ["survey-administrator"],
    icon: Home,
  },
  {
    path: "*",
    sidebar: false,
    component: Error404,
    roles: ["practitioner", "biller", "scheduler"],
  },
];

const AppRouter = () => {
  const { user, userCan } = useAuthContext();
  const roleRoutes = routes
    .filter((route) => {
      return route.roles.includes(user?.roleName);
    })
    .filter((route) => {
      if (!route.permissions) return true;
      return route.permissions.some((permission) => userCan(permission));
    });

  if (!user) return <div>Loading...</div>;
  return (
    <Router hashType="slash">
      <Switch>
        {routes.map((route) => (
          <PrivateRoute
            path={route.path}
            exact={route.exact}
            key={route.path}
            routes={roleRoutes}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default AppRouter;
