import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Avatar from "./Avatar";
import { Button, useTheme } from "@material-ui/core";

const Message = ({ message, user, classes, onSend }) => {
  const messagesRef = React.useRef(null);
  const scrollToBottom = () => {
    messagesRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      alignToTop: false,
    });
  };

  React.useEffect(() => {
    if (messagesRef.current) {
      scrollToBottom();
    }
  }, [messagesRef]);
  return (
    <>
      {message.user.id === user.id ? (
        <div
          className={clsx(
            classes.messageContainer,
            classes.messageContainerPract
          )}
          ref={messagesRef}
        >
          {message.user.avatar ? (
            <Avatar src={message.user.avatar} isPract classes={classes} />
          ) : null}
          <div className={clsx(classes.message, classes.messagePract)}>
            <div
              className={clsx(classes.messageInner, classes.messageInnerPract)}
            >
              <div>
                <div
                  className={classes.userName}
                  style={{
                    color: message.user.color,
                  }}
                >
                  {message.user.name}
                </div>
                <div
                  className={clsx(
                    classes.messageText,
                    classes.messageTextPract
                  )}
                >
                  {message.text}
                </div>
                <div
                  className={clsx(
                    classes.messageTime,
                    classes.messageTimePract
                  )}
                >
                  {message.createdAt}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.messageContainer} ref={messagesRef}>
          {message.user.avatar ? (
            <Avatar src={message.user.avatar} classes={classes} />
          ) : (
            <Avatar
              src="https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Favatar.png?alt=media&token=bdd40e1f-0eb5-4aa9-9d8f-07f6a093af6b"
              classes={classes}
            />
          )}
          <div className={classes.message}>
            <div className={classes.messageInner}>
              <div>
                <div
                  className={classes.userName}
                  style={{
                    color: message.user.color,
                  }}
                >
                  {message.user.name}
                </div>
                <div className={classes.messageText}>{message.text}</div>
                <div className={classes.messageTime}>{message.createdAt}</div>
              </div>
            </div>
          </div>
          {message.quickReplies?.values?.length > 0 && !message.isSent && (
            <QuickReplies
              values={message.quickReplies.values}
              classes={classes}
              type={message.quickReplies.type}
              onSend={onSend}
            />
          )}
        </div>
      )}
    </>
  );
};

Message.propTypes = {
  message: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default Message;

function QuickReplies({ values, classes, type, onSend }) {
  const [selectedReplies, setSelectedReplies] = useState([]);
  const [isSent, setIsSent] = useState(false);

  const sendReply = (payload) => {
    onSend(payload);
    setIsSent(true);
  };

  const handleReplyClick = (payload) => {
    toggleSelected(payload);
    if (type == "radio") {
      sendReply({ text: payload.title });
    }
  };

  const toggleSelected = (reply) => {
    const currentlySelected = [...selectedReplies];
    if (currentlySelected.includes(reply)) {
      currentlySelected.splice(currentlySelected.indexOf(reply), 1);
    } else {
      currentlySelected.push(reply);
    }
    setSelectedReplies(currentlySelected);
  };

  if (isSent) return null;
  return (
    <div className={classes.quickReplies}>
      <div>
        {values.map((value) => {
          return (
            <QuickReply
              key={value.value}
              value={value}
              classes={classes}
              type={type}
              onClick={handleReplyClick}
              isSelected={selectedReplies.includes(value)}
            />
          );
        })}
      </div>
      {type == "checklist" && selectedReplies.length > 0 && (
        <Button
          onClick={() =>
            sendReply({
              text: selectedReplies.map((item) => item.title).join(", "),
            })
          }
          color="primary"
          variant="contained"
          fullWidth
          type="button"
        >
          Send
        </Button>
      )}
    </div>
  );
}

function QuickReply({ value, classes, onClick, isSelected }) {
  const theme = useTheme();
  return (
    <div
      onClick={() => {
        onClick(value);
      }}
      className={classes.quickReply}
      style={
        isSelected
          ? { backgroundColor: theme.palette.primary.main, color: "white" }
          : {}
      }
    >
      {value.title}
    </div>
  );
}
