import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

const Toolbar = ({ onSend, classes }) => {
  const [message, setMessage] = React.useState("");
  return (
    <div className={clsx(classes.toolbar)}>
      <textarea
        className={classes.toolbarInput}
        placeholder="Type text..."
        value={message}
        onKeyPress={(e) => {
          if (e.charCode === 13) {
            onSend({ text: message });
            setMessage("");
            e.preventDefault();
          }
        }}
        onChange={(e) => setMessage(e.target.value)}
      />
      {message !== "" ? (
        <button
          className={classes.toolbarBtn}
          type="button"
          onClick={() => {
            onSend({ text: message });
            setMessage("");
          }}
        >
          Send
        </button>
      ) : null}
    </div>
  );
};

Toolbar.propTypes = {
  onSend: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default Toolbar;
