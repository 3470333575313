import React from "react";
import PropTypes from "prop-types";
import Message from "./Message";

const MessageList = ({ messageList, user, classes, onSend }) => {
  return (
    <div className={classes.messageList}>
      {messageList.map((message) => (
        <Message
          key={message.id}
          message={message}
          user={user}
          classes={classes}
          onSend={onSend}
        />
      ))}
    </div>
  );
};

MessageList.propTypes = {
  messageList: PropTypes.instanceOf(Array).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default MessageList;
