import React, { memo } from "react";

const Spinner = memo(() => (
  <div>
    <span role="img" aria-label="Loading...">
      🌀
    </span>
  </div>
));

export default Spinner;
