import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { getIn } from "../utils/utils";
import * as vars from "../styles/vars";

let Prescriptions = ({ prescriptions, title }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: ".75rem",
      backgroundColor: "white",
      boxshadow: vars.boxShadow,
      borderRadius: vars.borderRadius,
      display: "flex",
      flexDirection: "column",
      marginBottom: ".75em",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    prescriptionList: {
      marginTop: "1.5rem",
      display: "grid",
      gridGap: "10px",
    },
    prescription: {
      borderBottom: `3px solid #BF360C`,
      paddingBottom: ".25rem",
      gridTemplateColumns: "1fr 200px",
      display: "grid",
      alignItems: "center",
      justifyContent: "center",
      gridColumnGap: "10px",
    },
    prescriptionHeader: {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      gridTemplateColumns: "1fr 200px",
      display: "grid",
      alignItems: "center",
      justifyContent: "center",
      gridColumnGap: "10px",
    },
    prescriptionName: {
      wordBreak: "break-word",
      "& span": {
        fontWeight: "bold",
        fontSize: "1.25rem",
      },
    },
    prescriptionInner: {
      wordBreak: "break-word",
      borderLeft: `1px solid black`,
      paddingLeft: ".25rem",
      display: "grid",
      fontSize: ".85rem",
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          {title ? title : "New Prescriptions"}
        </div>
      </div>
      <div className={classes.prescriptionList}>
        <div className={classes.prescriptionHeader}>
          <div>Name</div>
          <div>Options</div>
        </div>
        {prescriptions.map((p) => (
          <div key={p.id} className={classes.prescription}>
            <div className={classes.prescriptionName}>
              <span>{getIn(p, ["medication", "CodeableConcept", "text"])}</span>
            </div>
            <div className={classes.prescriptionInner}>
              <div>
                <strong>Effective Date: </strong>
                {moment(
                  getIn(p, ["dispenseRequest", "validityPeriod", "start"])
                ).format("DD MMM YYYY")}
              </div>
              <div>
                <strong>Refills: </strong>
                {getIn(p, ["dispenseRequest", "numberOfRepeatsAllowed"], 0)}
              </div>
              <div>
                <strong>Dispense: </strong>
                {getIn(p, ["dispenseRequest", "quantity", "value"])}{" "}
                {getIn(p, ["dispenseRequest", "quantity", "unit"])}
              </div>
              <div>
                <strong>Day supply: </strong>
                {getIn(
                  p,
                  ["dispenseRequest", "dispenseInterval", "value"],
                  0
                )}{" "}
                day
              </div>
              <div>
                <strong>Strengh: </strong>
                {getIn(p, ["dosageInstruction", 0, "text"], "—")}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Prescriptions = React.memo(Prescriptions);
