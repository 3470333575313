import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    textDecoration: "underline",
    textDecorationColor: "red",
    fontSize: "2.5rem",
    fontWeight: "bold",
  },
  text: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginRight: ".5rem",
  },
});

function DoseSpotCreatingUrlError({ error }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span>An error occurred while creating a DoseSpot Frame</span>
      </div>
      <span className={classes.text}>{error}</span>
    </div>
  );
}

DoseSpotCreatingUrlError.propTypes = {
  error: PropTypes.string.isRequired,
};

export default DoseSpotCreatingUrlError;
