import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const Avatar = ({ src, isPract, classes }) => {
  return (
    <div
      className={
        isPract
          ? clsx(classes.messageAvatar, classes.messageAvatarPract)
          : classes.messageAvatar
      }
    >
      <img src={src} alt="" />
    </div>
  );
};

Avatar.defaultProps = {
  isPract: false,
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  isPract: PropTypes.bool,
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default Avatar;
