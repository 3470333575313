import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Toolbar from "./Toolbar";
import MessageList from "./MessageList";
import Title from "./Title";
import * as vars from "../../styles/vars";
import { grey200 } from "../../styles/vars";

const HsChat = ({ messages, onSend, user, title, showToolbar }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "500px",
      marginBottom: ".75rem",
      display: "grid",
      gridTemplateRows: "30px 460px",
      gridRowGap: "10px",
    },
    mainChat: {
      padding: ".75rem",
      backgroundColor: "white",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      borderRadius: "4px",
      marginBottom: ".75rem",
      display: "grid",
      gridTemplateRows: "1fr 60px",
      gridRowGap: "10px",
    },
    messageList: {
      height: "100%",
      backgroundColor: "white",
      overflow: "auto",
      padding: ".5rem 0",
    },
    messageContainer: {
      justifyContent: "flex-start",
      marginBottom: "10px",
      marginLeft: "8px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    message: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      minWidth: "300px",
      maxWidth: "500px",
    },
    messageInner: {
      display: "flex",
      minHeight: "20px",
      marginRight: "60px",
      flexBasis: "auto",
      flexShrink: "0",
      justifyContent: "flex-end",
      color: "black",
      borderRadius: "15px",
      borderBottomLeftRadius: "0",
      backgroundColor: grey200,
    },
    messageContainerPract: {
      flexDirection: "row-reverse",
    },
    messagePract: {
      alignItems: "flex-end",
    },
    messageInnerPract: {
      marginRight: 0,
      marginLeft: "60px",
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: 0,
    },
    messageAvatarPract: {
      marginRight: 0,
      marginLeft: "15px",
    },
    messageAvatar: {
      display: "flex",
      flexShrink: "0",
      position: "relative",
      height: "36px",
      width: "36px",
      marginRight: "15px",
      "& img": {
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        objectFit: "cover",
        width: "100%",
        height: "100%",
        transform: "translate(-50%, -50%)",
        maxHeight: "100%",
        maxWidth: "100%",
      },
    },
    messageText: {
      margin: "5px 10px",
      display: "inline-block",
      wordBreak: "break-word",
    },
    messageTextPract: {
      textAlign: "right",
      display: "inline-block",
      wordBreak: "break-word",
      margin: "5px 10px",
    },
    messageTime: {
      margin: "5px 10px",
      fontSize: "10px",
      color: "rgb(170, 170, 170)",
      overflowWrap: "break-word",
      textDecoration: "none",
      fontFamily:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    },
    messageTimePract: {
      textAlign: "right",
    },
    grid: {
      width: "100%",
      justifyContent: "center",
    },
    gridRow: {
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    columnDirection: {
      flexFlow: "column wrap",
    },
    gridItem: {
      flexWrap: "wrap",
      display: "flex",
      flex: 1,
      padding: "15px",
    },
    userAvatar: {
      width: "50px",
      height: "50px",
      position: "relative",
      marginRight: ".5rem",

      "& img": {
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        objectFit: "cover",
        width: "100%",
        height: "100%",
        transform: "translate(-50%, -50%)",
        maxHeight: "100%",
        maxWidth: "100%",
      },
    },
    toolbar: {
      display: "flex",
      height: "60px",
      marginBottom: "-10px",
      width: "100%",
    },
    toolbarBtn: {
      flex: "1 1 10%",
      right: "15px",
      padding: ".5rem",
      cursor: "pointer",
      border: "none",
      color: "rgb(0, 132, 255)",
      fontWeight: "700",
      backgroundColor: "transparent",
    },
    toolbarInput: {
      flex: "1 1 90%",
      display: "block",
      resize: "none",
      padding: ".375rem .75rem",
      paddingRight: "55px",
      fontSize: "1rem",
      color: "#495057",
      overflow: "auto",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "none",
      borderTop: "1px solid #ced4da",
      borderRadius: 0,
    },
    chatTitle: {
      "& span": {
        color: "black",
        fontSize: "1.25rem",
        fontWeight: "bold",
        borderBottom: `5px solid ${theme.palette.primary.main}`,
      },
    },
    userName: {
      padding: ".5rem .75rem 0 .75rem",
      textTransform: "capitalize",
      fontWeight: 700,
    },
    quickReply: {
      display: "inline-block",
      borderRadius: 5,
      border: `2px solid ${theme.palette.primary.main}`,
      padding: 5,
      margin: 5,
    },
    quickReplies: {
      maxWidth: 500,
      flexBasis: "100%",
      marginLeft: "45px",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Title title={title} classes={classes} />
      <div className={classes.mainChat}>
        <MessageList
          messageList={messages}
          user={user}
          classes={classes}
          onSend={onSend}
        />
        {showToolbar && <Toolbar onSend={onSend} classes={classes} />}
      </div>
    </div>
  );
};

HsChat.defaultProps = {
  title: "Patient Intake and Chat",
  showToolbar: true,
};

HsChat.propTypes = {
  onSend: PropTypes.func.isRequired,
  messages: PropTypes.instanceOf(Array).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  showToolbar: PropTypes.bool,
};

export default HsChat;
