import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { helpers, buttons } from "../styles";
import * as vars from "../styles/vars";
import { href, dateDisplayToVisitTable } from "../utils/utils";

export default function ScheduledMessageList({ title, emptyText, messages }) {
  const useStyles = makeStyles((theme) => ({
    headerTitle: {
      marginBottom: ".5rem",
      "& span": {
        fontSize: "1.25rem",
        fontWeight: "bold",
        borderBottom: `5px solid ${theme.palette.primary.main}`,
      },
    },
    messageList: {
      display: "grid",
      gridRowGap: ".75rem",
    },
    message: {
      display: "grid",
      gridTemplateColumns: "max-content max-content 1fr max-content",
      gridColumnGap: ".5rem",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      paddingBottom: ".5rem",
      alignItems: "center",
    },
    text: {
      wordBreak: "break-word",
    },
    link: {
      color: vars.indigo500,
      "&:hover": {
        color: vars.indigo900,
        fontWeight: "bold",
      },
    },
    date: {
      fontSize: ".95rem",
      fontWeight: "bold",
    },
    emptyText: {
      textAlign: "center",
      "& span": {
        fontSize: "1.25rem",
        borderBottom: `2px solid ${vars.red500}`,
        fontWeight: "bold",
      },
    },
  }));
  const utils = helpers();
  const classes = useStyles();
  const btns = buttons();
  return (
    <div className={utils.card}>
      <div className={classes.headerTitle}>
        <span>{title}</span>
      </div>
      <div className={classes.messageList}>
        {messages.length ? (
          messages.map((message) => (
            <div key={message.id} className={classes.message}>
              <div className={classes.date}>
                {dateDisplayToVisitTable(message.dateTime)}
              </div>
              <div>
                <a
                  href={href("patients", message.patient.id)}
                  className={classes.link}
                >
                  {message.patient.name}
                </a>
              </div>
              <div className={classes.text}>{message.text}</div>
              <div>
                <a
                  href={href("visits", message.encounter.id)}
                  className={clsx(
                    btns.btn,
                    btns.btnLink,
                    utils.textUpper,
                    utils.fontBold
                  )}
                >
                  Show visit
                </a>
              </div>
            </div>
          ))
        ) : (
          <div className={classes.emptyText}>
            <span>{emptyText}</span>
          </div>
        )}
      </div>
    </div>
  );
}
