import React from "react";
import * as ui from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import { decode as atob } from "base-64";
import { buttons, helpers } from "../styles";
import { getIn } from "../utils/utils";

const useStyles = ui.makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    borderBottom: "5px solid #F57C00",
  },
  btnGroup: {
    display: "flex",
    "& button + button": {
      marginLeft: "15px",
    },
  },
  addInstructions: {
    margin: ".75rem 0",
  },
  textArea: {
    width: "100%",
    padding: ".25rem",
    resize: "vertical",
  },
  instructionsList: {
    display: "grid",
    marginTop: ".75rem",
  },
  instruction: {
    marginBottom: ".75rem",
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    paddingBottom: ".25rem",
    borderBottom: "3px solid #E65100",
  },
  date: {
    padding: "0 .25rem",
    borderRight: "1px solid black",
    fontWeight: "bold",
  },
  text: {
    paddingLeft: ".25rem",

    "& span": {
      height: "100%",
    },
  },
});

const DocumentReferenceEncounter = ({ onSave, title, data, isClosed }) => {
  const [isShow, setShow] = React.useState(false);
  const [instruction, setInstruction] = React.useState("");

  const classes = useStyles();
  const btns = buttons();
  const utils = helpers();

  const resetForm = () => {
    setShow(false);
    setInstruction("");
  };

  return (
    <div className={utils.card}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>{title}</div>
        <div className={classes.btnGroup}>
          {!isClosed ? (
            isShow ? (
              <button
                type="button"
                className={clsx(btns.btn, btns.btnEdit, utils.fontBold)}
                onClick={resetForm}
              >
                Reset
              </button>
            ) : (
              <button
                type="button"
                className={clsx(btns.btn, btns.btnAdd, utils.fontBold)}
                onClick={() => setShow(true)}
              >
                Add
              </button>
            )
          ) : null}
        </div>
      </div>
      {isShow ? (
        <div className={classes.addInstructions}>
          <textarea
            className={classes.textArea}
            rows={10}
            value={instruction}
            onChange={(e) => setInstruction(e.target.value)}
          />
          <button
            type="button"
            className={
              instruction.length === 0
                ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
                : clsx(btns.btn, btns.btnAdd, utils.fontBold)
            }
            disabled={instruction.length === 0}
            onClick={() => {
              onSave(instruction);
              resetForm();
            }}
          >
            Submit and send
          </button>
        </div>
      ) : null}
      <div className={classes.instructionsList}>
        {data.map((el) => (
          <div key={el.id} className={classes.instruction}>
            <div className={classes.date}>
              {moment(el.date, "YYYY-MM-DD").format("MM/DD/YYYY")}
            </div>
            <div className={classes.text}>
              <span>{atob(getIn(el.content, [0, "attachment", "data"]))}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

DocumentReferenceEncounter.propTypes = {
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isClosed: PropTypes.bool.isRequired,
};

export default DocumentReferenceEncounter;
