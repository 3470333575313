import React from "react";
import { makeStyles } from "@material-ui/core";
import VisitTable from "../components/VisitTable";
import { useAuthContext } from "../components/AuthContext";
import hooks from "../hooks";
import { getIn } from "../utils/utils";
import Spinner from "../components/Spinner";
import Alert from "../components/Errors/Alert";

const useStyles = makeStyles({
  container: {
    display: "flex",
    marginBottom: ".75rem",
    padding: ".75rem",
    flexDirection: "column",
  },
});

function VisitsPageBilling() {
  const { user } = useAuthContext();
  const classes = useStyles();

  const { loading, error, data } = hooks.useQuery({
    url: "/$graphql-query/physician-app-visits-billing",
    method: "POST",
    data: {
      variables: {
        userId: getIn(user, ["id"]),
      },
    },
  });

  const sectionLabels = {
    completed: "Completed Visits",
    inProgress: "In-Progress Visits",
    new: "New Visits",
  };

  if (loading || !data) return <Spinner />;

  if (error)
    return (
      <Alert
        severity="error"
        error="There was an error. If this continues, contact SupDoc technical support."
      />
    );

  const rowsFiltered = Object.keys(data).map((key, index) => {
    return data[key].filter((visit) => {
      return visit.reasonCode[0].text == "Behavioral";
    });
  });

  return (
    <div className={classes.container}>
      {Object.keys(data).map((key, index) => {
        return (
          data[key].length > 0 && (
            <VisitTable
              sortDirection="desc"
              tableTitle={sectionLabels[key]}
              rows={data[key].filter((visit) => {
                return visit.reasonCode[0].text == "Behavioral";
              })}
            />
          )
        );
      })}
    </div>
  );
}

export default VisitsPageBilling;
