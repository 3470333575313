import { createTheme } from "@material-ui/core/styles";

import * as vars from "./vars";

const mainTheme = createTheme({
  palette: {
    primary: {
      main: vars.BrandColors.DARK_BLUE,
    },
    secondary: {
      main: vars.BrandColors.BLUE_MAIN,
    },
    warning: {
      main: vars.BrandColors.ORANGE,
    },
    error: {
      main: vars.red500,
    },
    text: {
      primary: vars.grey700,
      secondary: vars.grey500,
      disabled: vars.grey300,
    },
  },
});

export default mainTheme;
