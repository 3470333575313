import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import DateFnsUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import * as vars from "../styles/vars";
import { helpers, buttons } from "../styles/index";

const FollowUpMessages = ({
  messages,
  onSave,
  ids,
  onDisable,
  onEnable,
  isClosed,
}) => {
  const useStyles = makeStyles((theme) => ({
    headerTitle: {
      marginBottom: ".5rem",
      "& span": {
        fontSize: "1.25rem",
        fontWeight: "bold",
        borderBottom: `5px solid ${theme.palette.primary.main}`,
      },
    },
    messageList: {
      display: "grid",
      gridRowGap: ".75rem",
    },
    message: {
      display: "grid",
      gridTemplateColumns: "max-content 1fr 50px",
      gridColumnGap: "1.5rem",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      paddingBottom: ".5rem",
      "& div": {
        paddingRight: ".5rem",
        borderRight: "2px solid black",
      },
      "& div:last-child": {
        paddingRight: "0",
        borderRight: "none",
      },
    },
    buttons: {
      alignItems: "flex-start",
      justifyContent: "flex-end",
      textAlign: "right",
    },
    text: {
      wordBreak: "break-word",
    },
    buttonDisable: {
      color: vars.red500,
      fontWeight: "bold",
      "&:focus": {
        outline: "none",
      },
    },
    buttonEnable: {
      color: vars.green500,
      fontWeight: "bold",
      "&:focus": {
        outline: "none",
      },
    },
    buttonFinished: {
      color: vars.grey500,
      cursor: "not-allowed",
    },
    form: {
      color: "black",
      display: "flex",
      flexDirection: "column",
    },
    showAdd: {
      display: "flex",
      alignSelf: "flex-end",
      margin: ".75rem 0",
    },
    btnGroup: {
      marginTop: ".5rem",
      display: "flex",
      "& > button": {
        margin: " 0 .5rem",
        width: "100%",
      },
    },
    timeField: {
      margin: ".5rem",
    },
    textField: {
      padding: ".5rem",
      border: `2px solid ${theme.palette.primary.main}`,
      margin: ".5rem",
    },
  }));
  const utils = helpers();
  const classes = useStyles();
  const btns = buttons();
  const [isShow, setIsShow] = React.useState(false);
  const [textMessage, setText] = React.useState(null);
  const [selectedDate, handleDateChange] = React.useState(
    moment().add(4, "days").format("YYYY-MM-DD hh:mm")
  );
  function hideForm() {
    setIsShow(false);
    setText("");
    handleDateChange(null);
  }
  return (
    <div className={utils.card}>
      <div className={classes.headerTitle}>
        <span>Follow-up Messages</span>
      </div>
      <div className={classes.messageList}>
        {(messages || []).map((message) => (
          <div className={classes.message} key={message.id}>
            <div>
              <span className="text date">
                {moment(message.dateTime).format("YYYY-MM-DD hh:mm")}
              </span>
            </div>
            <div>
              <span className={classes.text}>{message.text}</span>
            </div>

            <div className={classes.buttons}>
              {(() => {
                switch (message.status) {
                  case "in-queue":
                    return (
                      <button
                        type="button"
                        onClick={() => {
                          onDisable(message.id);
                        }}
                        className={classes.buttonDisable}
                      >
                        Disable
                      </button>
                    );
                  case "disabled":
                    return (
                      <button
                        type="button"
                        onClick={() => {
                          onEnable(message.id);
                        }}
                        className={classes.buttonEnable}
                      >
                        Enable
                      </button>
                    );
                  default:
                    return (
                      <button
                        type="button"
                        disabled
                        className={classes.buttonFinished}
                      >
                        Done
                      </button>
                    );
                }
              })()}
            </div>
          </div>
        ))}
        {!isClosed ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.form}>
              {isShow ? (
                <>
                  <div className={classes.headerTitle}>
                    <span>Add follow-up message</span>
                  </div>
                  <textarea
                    type="text"
                    rows={4}
                    style={{ resize: "vertical" }}
                    defaultValue={textMessage}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    className={classes.textField}
                  />
                  <DateTimePicker
                    label="Choose date"
                    className={classes.timeField}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  <div className={classes.btnGroup}>
                    <button
                      type="button"
                      onClick={() => {
                        hideForm();
                      }}
                      className={clsx(btns.btn, btns.btnRemove)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        onSave({
                          text: textMessage,
                          from: {
                            resourceType: "ChatParticipant",
                            id: ids.participant || "error",
                          },
                          chat: {
                            resourceType: "Chat",
                            id: ids.chat,
                          },
                          status: "in-queue",
                          dateTime: moment(selectedDate).utc().format(),
                        });
                        setIsShow(false);
                        hideForm();
                      }}
                      className={clsx(btns.btn, btns.btnPrimary)}
                    >
                      Add
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className={clsx(btns.btn, btns.btnAdd, classes.showAdd)}
                  type="button"
                  onClick={() => {
                    handleDateChange(
                      moment().add(4, "days").format("YYYY-MM-DD hh:mm")
                    );
                    setText(
                      "Hello! I wanted to check how you are feeling after treatment. Please let me know if you aren't feeling better or if there is anything else I can help answer."
                    );
                    setIsShow(true);
                  }}
                >
                  Add
                </button>
              )}
            </div>
          </MuiPickersUtilsProvider>
        ) : null}
      </div>
    </div>
  );
};

FollowUpMessages.propTypes = {
  messages: PropTypes.instanceOf(Array).isRequired,
  ids: PropTypes.instanceOf(Object).isRequired,
  onSave: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  isClosed: PropTypes.bool.isRequired,
};

export default FollowUpMessages;
