import React from "react";
import { makeStyles } from "@material-ui/core";
import VisitTable from "../components/VisitTable";
import { useAuthContext } from "../components/AuthContext";
import hooks from "../hooks";
import { getIn } from "../utils/utils";
import Spinner from "../components/Spinner";
import Alert from "../components/Errors/Alert";

const useStyles = makeStyles({
  container: {
    display: "flex",
    marginBottom: ".75rem",
    padding: ".75rem",
    flexDirection: "column",
  },
});

function VisitsPage() {
  const { user } = useAuthContext();
  const classes = useStyles();

  const { loading, error, data } = hooks.useQuery({
    url: "/$graphql-query/physician-app-visits",
    method: "POST",
    data: {
      variables: {
        practId: getIn(user, ["link", 0, "link", "id"]),
      },
    },
  });

  const sectionLabels = {
    completed: "Completed Visits",
    inProgress: "In-Progress Visits",
    inReview: "In Review",
    new: "New Visits",
  };

  const sectionFilters = {
    inProgress: (visit) => visit.review?.status !== "ready-for-review",
  };

  const sortDirections = {
    completed: "desc",
    inProgress: "asc",
    new: "asc",
  };

  if (loading) return <Spinner />;

  Object.keys(data).forEach(
    (key) =>
      (data[key] = data[key].filter(
        sectionFilters[key] ? sectionFilters[key] : () => true
      ))
  );

  if (error)
    return (
      <Alert
        severity="error"
        error="There was an error. If this continues, please contact SupDoc technical support."
      />
    );

  return (
    <div className={classes.container}>
      {console.log(data)}
      {Object.keys(data).map((key, index) => {
        return (
          data[key].length > 0 && (
            <VisitTable
              tableTitle={sectionLabels[key]}
              rows={data[key]}
              sortDirection={sortDirections[key]}
            />
          )
        );
      })}
    </div>
  );
}

export default VisitsPage;
