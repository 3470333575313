/* eslint react/prop-types: 0 */
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { buttons, helpers } from "../styles";
import { useAuthContext } from "../components/AuthContext";

let SingleEncounterButtonToolbar = ({
  loading,
  refresh,
  isSync,
  prescribe,
  sync,
  subject,
  closeVisit,
  printVisit,
  isClosed,
  markEncounterReadyForReview,
  encounter,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(4,1fr)",
      gridGap: ".75rem",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(2,1fr)",
      },
    },
  }));
  const classes = useStyles();
  const btns = buttons();
  const utils = helpers();
  const { userCan } = useAuthContext();
  const reviewButtonDisabled = ["ready-for-review", "review-finished"].includes(
    encounter?.reviewStatus
  );

  return (
    <div className={classes.root}>
      <button
        type="button"
        className={
          loading || isSync || isClosed
            ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
            : clsx(btns.btn, btns.btnPrimary, utils.fontBold)
        }
        onClick={(e) => prescribe(e, subject)}
        disabled={loading || isSync || isClosed}
      >
        Prescribe
      </button>
      {/* <button
        type="button"
        className={
          loading || isSync || isClosed
            ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
            : clsx(btns.btn, btns.btnPrimary, utils.fontBold)
        }
        disabled={loading || isSync || isClosed}
        onClick={() => sync(subject)}
      >
        Synchronization
      </button> */}
      {/* <button
        type="button"
        className={
          loading || isSync || isClosed
            ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
            : clsx(btns.btn, btns.btnPrimary, utils.fontBold)
        }
        onClick={refresh}
        disabled={(loading && isSync) || isClosed}
      >
        Refresh page data
      </button> */}
      {userCan("phys-app-set-encounter-status-complete") ? (
        <button
          type="button"
          className={
            loading || isSync || isClosed
              ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
              : clsx(btns.btn, btns.btnPrimary, utils.fontBold)
          }
          onClick={closeVisit}
          disabled={(loading && isSync) || isClosed}
        >
          Mark Encounter as Complete
        </button>
      ) : null}

      {userCan("phys-app-set-encounter-status-ready-for-review") ? (
        <button
          type="button"
          className={
            loading || isSync || isClosed || reviewButtonDisabled
              ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
              : clsx(btns.btn, btns.btnPrimary, utils.fontBold)
          }
          onClick={markEncounterReadyForReview}
          disabled={(loading && isSync) || isClosed || reviewButtonDisabled}
        >
          Mark Ready for Review
        </button>
      ) : null}
      <button
        type="button"
        className={
          loading || isSync || isClosed
            ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
            : clsx(btns.btn, btns.btnPrimary, utils.fontBold)
        }
        onClick={printVisit}
      >
        Print Visit
      </button>
    </div>
  );
};

export default SingleEncounterButtonToolbar = React.memo(
  SingleEncounterButtonToolbar
);
