const enumerated = {
  SEE_UNASSIGNED_ENCOUNTERS: "see-unassigned-encounters",
  SET_ENCOUNTER_STATUS_COMPLETE: "set-encounter-status-complete",
  SET_ENCOUNTER_STTATUS_READY_FOR_REVIEW:
    "set-encounter-status-ready-for-review",
  PRESCRIBE_GENERAL: "prescribe-general",
  CREATE_NEW_PATIENT: "create-new-patient",
  CREATE_NEW_VISIT: "create-new-visit",
  VIEW_SCHEDULE_PAGE: "view-schedule-page",
  VIEW_MESSAGE_CENTER_PAGE: "view-message-center-page",
  VIEW_PATIENTS_LIST_PAGE: "view-patients-list-page",
  VIEW_FEEDBACK_PAGE: "view-feedback-page",
  VIEW_ANY_SINGLE_ENCOUNTER: "view-any-single-encounter",
};

Object.keys(enumerated).map((key) => {
  enumerated[key] = `phys-app-${enumerated[key]}`;
});

export default enumerated;
