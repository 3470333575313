import React from "react";
import PropTypes from "prop-types";
import * as ui from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import clsx from "clsx";
import { useTheme } from "@material-ui/core";
import * as aidbox from "../utils/aidbox";
import { debounce } from "../hooks/useDebounceInput";
import { buttons, helpers } from "../styles";
import { getIn } from "../utils/utils";
import * as vars from "../styles/vars";

const FamilyMemberHistories = ({
  title,
  patientFamilyHistories,
  onSave,
  onDelete,
  patientID,
  encounterID,
  isClosed,
  queryParams,
}) => {
  const useStyles = ui.makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    btnGroup: {
      display: "flex",
      "& button + button": {
        marginLeft: "15px",
      },
    },
    chosenMedication: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    },
    chosenMedicationText: {
      fontWeight: "bold",
    },

    chosenMedicationResearch: {
      marginLeft: "10px",
    },
    addBody: {
      position: "relative",
      padding: ".5rem",
      display: "grid",
      gridTemplateColumns: "1fr 75px",
      gridGap: "10px",
    },
    addBodySearchInput: {
      width: "100%",
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: "4px",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      padding: ".25rem",
      fontSize: "1.25rem",
    },
    submit: {
      display: "flex",
      alignItems: "flex-end",
      "& button": {
        width: "100%",
        fontWeight: "bold",
        height: "35px",
      },
    },
    searchResult: {
      position: "absolute",
      top: "75px",
      left: ".5rem",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      height: "200px",
      borderRadius: "4px",
      overflowY: "auto",
      backgroundColor: "whitesmoke",
      color: vars.btnPrimary,
      padding: ".75rem",
      border: `2px solid ${theme.palette.primary.main}`,
      "& div": {
        cursor: "pointer",
        padding: ".25rem",
        "& span": {
          borderBottom: `2px solid {theme.palette.primary.main}`,
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      },
      zIndex: 1202,
    },
    searchResultWrapper: {
      width: "100%",
      minHeight: "100vh",
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      zIndex: 1201,
    },
    conditionsList: {
      marginTop: "1.5rem",
      display: "grid",
      gridGap: "10px",
    },
    condition: {
      marginBottom: ".75rem",
      display: "grid",
      alignItems: "flex-end",
      gridTemplateColumns: "min-content 1fr 1fr max-content",
      paddingBottom: ".25rem",
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      color: "black",
    },
    conditionActive: {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    code: {
      padding: "0 .25rem",
      borderRight: `1px solid ${theme.palette.primary.main}`,
      fontWeight: "bold",
    },
    text: {
      paddingLeft: ".25rem",
    },
    conditionDelete: {
      display: "flex",
      cursor: "pointer",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      color: theme.palette.primary.main,
      "& span": {
        display: "inline-block",
        fontWeight: " bold",
      },
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  }));

  const classes = useStyles();
  const btns = buttons();
  const styleUtils = helpers();
  const [isShow, setShow] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [isNotFound, setNotFound] = React.useState(false);
  const [isStopped] = React.useState(false);
  const [conditions, setConditions] = React.useState([]);
  const [isChosen, setChosen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [currentCondition, setCondition] = React.useState({
    id: "",
    display: "",
    code: "",
  });
  const [addBody, setBody] = React.useState({
    subject: {
      resourceType: "Patient",
      id: patientID,
    },
    encounter: {
      resourceType: "Encounter",
      id: encounterID,
    },
    clinicalStatus: {
      text: "active",
    },
  });

  const resetForm = () => {
    setShow(false);
    setChosen(false);
    setBody({
      code: {
        coding: [
          {
            system: "icd-10",
            code: "",
            display: "",
          },
        ],
        text: "",
      },
      subject: {
        resourceType: "Patient",
        id: patientID,
      },
      encounter: {
        resourceType: "Encounter",
        id: encounterID,
      },
      clinicalStatus: {
        text: "active",
      },
    });
    setConditions([]);
    setCondition({
      id: "",
      display: "",
      code: "",
    });
  };

  const searchConditions = (query) => {
    setConditions([]);
    setNotFound(false);

    if (!query.length) {
      return;
    }

    setLoading(true);
    setNotFound(false);

    aidbox
      .getPlain(`/Concept?system=ICD-10&_ilike=${query}${queryParams || ""}`)
      .then((resp) => {
        if (isStopped) {
          setLoading(false);
          return;
        }

        if (!resp.data.entry) {
          setNotFound(true);
          return;
        }

        const conditionsWithoutCode = resp.data.entry
          .map((r) => r.resource)
          .filter(
            (r) =>
              !patientFamilyHistories.find(
                (condition) =>
                  getIn(condition, [
                    "code",
                    "coding",
                    ({ system }) => system === "icd-10",
                    0,
                    "code",
                  ]) === r.id
              )
          );

        if (conditionsWithoutCode.length) {
          setConditions(conditionsWithoutCode);
        }

        setNotFound(!conditionsWithoutCode.length);
        setLoading(false);
      })
      .catch((err) => {
        aidbox.sendErrorLogs({ data: err });
      });
  };

  const debounceSearch = React.useCallback(debounce(searchConditions, 200), []);

  const chooseCondition = (condition) => {
    setCondition({
      id: condition.id,
      display: condition.display,
      code: condition.code,
    });
    setChosen(true);
    setBody({
      ...addBody,
      code: {
        coding: [
          {
            system: condition.system,
            code: condition.code,
            display: condition.display,
            id: condition.id,
          },
        ],
        text: condition.display,
      },
    });
    setConditions([]);
    setDisabled(false);
  };

  const clearSearchInput = () => {
    setInputValue("");
  };

  const onCancelSearch = (e) => {
    if (e.keyCode === 27) {
      setConditions([]);
      clearSearchInput();
    }
    isNotFound && setNotFound(false);
  };

  return (
    /* eslint no-nested-ternary: "off" */
    <div className={styleUtils.card}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>{title}</div>
        <div className={classes.btnGroup}>
          {!isClosed ? (
            isShow ? (
              <button
                type="button"
                className={clsx(btns.btn, btns.btnEdit, styleUtils.fontBold)}
                onClick={() => resetForm()}
              >
                Reset
              </button>
            ) : (
              <button
                type="button"
                className={clsx(btns.btn, btns.btnAdd, styleUtils.fontBold)}
                onClick={() => setShow(true)}
              >
                Add
              </button>
            )
          ) : null}
        </div>
      </div>
      {isShow ? (
        <div className={classes.addBody}>
          <div>
            {isChosen ? (
              <div className={classes.chosenMedication}>
                <div className={classes.chosenMedicationText}>
                  <div>Name: {currentCondition.display}</div>
                  <div>Code: {currentCondition.code}</div>
                </div>
                <div
                  className={classes.chosenMedicationResearch}
                  onClick={() => setChosen(false)}
                >
                  <CachedIcon />
                </div>
              </div>
            ) : (
              <>
                <label className="required">Name</label>
                <input
                  className={classes.addBodySearchInput}
                  value={inputValue}
                  type="text"
                  onBlur={(e) => {
                    onCancelSearch(e);
                    clearSearchInput(e);
                  }}
                  onKeyDown={onCancelSearch}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    debounceSearch(e.target.value);
                  }}
                />
              </>
            )}
          </div>
          {conditions.length !== 0 ? (
            <>
              <div
                className={classes.searchResultWrapper}
                onClick={() => setConditions([])}
              ></div>
              <div className={classes.searchResult}>
                {conditions.map((condition) => (
                  <div
                    key={condition.id}
                    onClick={() => chooseCondition(condition)}
                  >
                    <span>
                      {condition.display} - {condition.code}
                    </span>
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {isLoading ? (
            <div className={classes.searchResult}>
              <div> Processing... </div>
            </div>
          ) : null}
          {isNotFound ? (
            <div
              onMouseDown={(e) => {
                onSave({}, inputValue);
                setNotFound(false);
              }}
              className={classes.searchResult}
            >
              <div>Add User Defined Condition</div>
            </div>
          ) : null}

          <div className={classes.submit}>
            <button
              className={
                isDisabled
                  ? clsx(btns.btn, btns.btnDisabled, styleUtils.fontBold)
                  : clsx(btns.btn, btns.btnAdd, styleUtils.fontBold)
              }
              onClick={() => {
                onSave(addBody);
                resetForm();
              }}
              type="button"
              disabled={isDisabled}
            >
              Save
            </button>
          </div>
        </div>
      ) : null}
      <div className={classes.conditionsList}>
        {patientFamilyHistories.map((history) => {
          const condition = history.condition[0];
          return (
            <div
              className={
                getIn(condition, ["clinicalStatus", "text"]) === "active"
                  ? clsx(classes.condition, classes.conditionActive)
                  : clsx(classes.condition)
              }
              key={history.id}
            >
              <div className={classes.code}>
                {getIn(condition, ["code", "coding", 0, "code"])}
              </div>
              <div className={classes.text}>
                {getIn(condition, ["code", "coding", 0, "display"])}
              </div>
              <div className={classes.text}>({getIn(history, ["status"])})</div>
              <div className={classes.conditionDelete}>
                {!isClosed ? (
                  <span
                    onClick={() => {
                      const isDelete = window.confirm(
                        `Are you sure you would like to delete ${getIn(
                          condition,
                          ["code", "text"]
                        )}`
                      );
                      if (isDelete) {
                        onDelete(history, condition);
                      }
                    }}
                  >
                    &times;
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FamilyMemberHistories;
