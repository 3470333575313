import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAuthContext } from "./components/AuthContext";
import GlobalErrorProvider from "./contexts/GlobalErrorContext";
import ErrorBoundary from "./components/Errors/ErrorBoundary";
import MainLayout from "./layouts/MainLayout";
import { redirectToLogin } from "./utils/aidbox";

function PrivateRoute({ children, ...rest }) {
  const { user } = useAuthContext();
  return (
    <Route
      {...rest}
      render={() =>
        user?.id ? (
          <MainLayout routes={rest.routes}>
            <GlobalErrorProvider>
              <ErrorBoundary>
                <Switch>
                  {rest.routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      // eslint-disable jsx-props-no-spreading
                      render={(props) => (
                        <route.component {...props} routes={route.routes} />
                      )}
                    />
                  ))}
                </Switch>
              </ErrorBoundary>
            </GlobalErrorProvider>
          </MainLayout>
        ) : (
          redirectToLogin()
        )
      }
    />
  );
}

export default PrivateRoute;
