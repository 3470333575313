import React from "react";
import * as ui from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

import { useController } from "../../Controller";
import { getIn } from "../../utils/utils";
import VisitPatientInfo from "../../components/VisitPatientInfo";
import SingleEncounterButtonToolbar from "../../components/SingleEncounterButtonToolbar";
import { useStore } from "../../Store";
import DoseSpotUI from "../../components/DoseSpotUI";
import Conditions from "../../components/Conditions";
import Prescriptions from "../../components/Prescriptions";
import Medications from "../../components/Medications";
import MedicationAllergies from "../../components/MedicationAllergies";
import Chat from "../../components/Chat";
import DocumentReferenceEncounter from "../../components/DocumentReferenceEncounter";
import FollowUpMessages from "../../components/FollowUpMessages";
import ObservationTable from "../../components/ObservationsTable";
import QuestionnaireResponseTable from "../../components/QuestionnaireResponseTable";
import FamilyMemberHistories from "../../components/FamilyMemberHistories";

const useStyles = ui.makeStyles({
  chat: {
    height: "200px",
  },
  container: {},
});

function Visit() {
  const classes = useStyles();
  const [state] = useStore();
  const ctrl = useController();

  const isClosed = state.data.status === "finished";
  return (
    <>
      <ui.Grid container spacing={2}>
        <ui.Grid item xs={12}>
          <VisitPatientInfo
            patient={getIn(state.data, ["subject", "resource"])}
            chief={state.data?.reasonCode}
            datetime={getIn(state.data, ["period", "start"])}
            encounter={state}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <SingleEncounterButtonToolbar
            loading={state.loading}
            refresh={ctrl.refetch}
            subject={state.data.subject}
            isSync={state.isModalSync}
            prescribe={ctrl.prescribe}
            sync={ctrl.syncDoseSpot}
            closeVisit={ctrl.closeVisit}
            printVisit={ctrl.printVisit}
            markEncounterReadyForReview={ctrl.markEncounterReadyForReview}
            isClosed={isClosed}
            encounter={state}
          />
        </ui.Grid>
        <ui.Grid item xs={12} md={6}>
          <ui.Grid item xs={12}>
            <Conditions
              title="Conditions"
              patientConditions={state.data.subject.resource.conditions}
              patientID={state.data.subject.id}
              encounterID={state.id}
              onSave={ctrl.saveCondition}
              onDelete={ctrl.deleteCondition}
              isClosed={isClosed}
            />
          </ui.Grid>
          <ui.Grid item xs={12} style={{ marginTop: ".5rem" }}>
            <Medications
              patientMedications={getIn(
                state.data,
                ["subject", "resource", "medications"],
                []
              )}
              subject={state.data.subject}
              encounterID={state.id}
              onSave={ctrl.saveMedication}
              onDelete={ctrl.deleteMedication}
              patientAllergies={getIn(
                state.data,
                ["subject", "resource", "allergies"],
                []
              )}
              isClosed={isClosed}
              title="Self-Reported Medications"
            />
          </ui.Grid>
          <ui.Grid item xs={12}>
            <MedicationAllergies
              patientAllergies={getIn(
                state.data,
                ["subject", "resource", "allergies"],
                []
              )}
              subject={state.data.subject}
              encounterID={state.id}
              onSave={ctrl.saveAllergy}
              onDelete={ctrl.deleteAllergy}
              isClosed={isClosed}
            />
          </ui.Grid>
          <ui.Grid item xs={12}>
            <FamilyMemberHistories
              title="Family Member Histories"
              patientFamilyHistories={
                state.data.subject.resource.familymemberhistorys_as_patient ||
                []
              }
              patientID={state.data.subject.id}
              encounterID={state.id}
              onSave={ctrl.saveFamilyMemberHistory}
              onDelete={ctrl.deleteFamilyMemberHistory}
              isClosed={isClosed}
            />
          </ui.Grid>
          {state.data?.subject?.resource?.medicationrequests_as_subject
            ?.length > 0 && (
            <ui.Grid item xs={12}>
              <Prescriptions
                title="Recuro-Prescribed Medications"
                prescriptions={
                  state.data?.subject?.resource
                    ?.medicationrequests_as_subject || []
                }
              />
            </ui.Grid>
          )}
          {state.mediaResources.length > 0 && (
            <ui.Grid item xs={12}>
              <ImageGallery
                items={state.mediaResources}
                showThumbnails={false}
              />
            </ui.Grid>
          )}
        </ui.Grid>
        <ui.Grid item md={6} xs={12}>
          <ui.Grid item xs={12}>
            <Chat
              showToolbar={!state.isPhone}
              chatId={state.data.chat.id}
              className={classes.chat}
              participantId={state.participantId}
              userLinkId={getIn(state.user, ["link", 0, "link", "id"])}
            />
          </ui.Grid>
          {/* <ui.Grid item xs={12}>
              <DocumentReferenceEncounter
              title="Physician Notes"
              onSave={savePhysicianNotes}
              data={data.physicianNotes}
              isClosed={isClosed}
              />
              </ui.Grid> */}
          <ui.Grid item xs={12}>
            <Conditions
              title="Diagnosis"
              patientConditions={state.data.diagnosis.filter((cond) =>
                getIn(cond, [
                  "category",
                  0,
                  "coding",
                  { code: "encounter-diagnosis" },
                  0,
                ])
              )}
              patientID={state.data.subject.id}
              encounterID={state.id}
              onSave={ctrl.saveDiagnosis}
              onDelete={ctrl.deleteDiagnosis}
              isClosed={isClosed}
            />
          </ui.Grid>
          <ui.Grid item xs={12}>
            <DocumentReferenceEncounter
              title="Patient Instructions"
              onSave={ctrl.saveVisitInstructions}
              data={state.data.visitInstructions || []}
              isClosed={isClosed}
            />
          </ui.Grid>
          <ui.Grid item xs={12}>
            <DocumentReferenceEncounter
              title="Visit Summary / Observations"
              onSave={ctrl.saveEncounterSummary}
              data={state.data.visitSummary || []}
              isClosed={isClosed}
            />
          </ui.Grid>
          <ui.Grid item xs={12}>
            <Prescriptions
              title="New Prescriptions this Visit"
              prescriptions={state.data.prescriptions}
            />
          </ui.Grid>

          {!state.isPhone ? (
            <ui.Grid item xs={12}>
              <FollowUpMessages
                messages={getIn(
                  state.data,
                  ["chat", "resource", "followUp"],
                  []
                )}
                ids={{
                  chat: state.data.chat.id,
                  participant: state.participantId,
                  encounter: state.id,
                  author: getIn(state.user, ["link", 0, "link", "id"]),
                }}
                onSave={ctrl.saveFollowUp}
                onDisable={ctrl.disableFollowUp}
                onEnable={ctrl.enableFollowUp}
                isClosed={isClosed}
              />
            </ui.Grid>
          ) : null}
        </ui.Grid>
        {getIn(state, ["data", "subject", "resource", "observations"], [])
          .length > 0 ? (
          <ui.Grid item xs={12}>
            <ObservationTable
              title="Observations"
              observations={state?.data?.subject?.resource?.observations}
            />
          </ui.Grid>
        ) : null}
        {getIn(
          state,
          ["data", "subject", "resource", "questionnaireResponses"],
          []
        ).length > 0 ? (
          <ui.Grid item xs={12}>
            <QuestionnaireResponseTable
              title="Questionnaire Responses"
              responses={state?.data?.subject?.resource?.questionnaireResponses}
            />
          </ui.Grid>
        ) : null}
      </ui.Grid>
      <DoseSpotUI {...state.modalState} />
    </>
  );
}

export default Visit;
