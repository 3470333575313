import React from "react";
import PropTypes from "prop-types";
import CachedIcon from "@material-ui/icons/Cached";
import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { debounce } from "../hooks/useDebounceInput";
import * as aidbox from "../utils/aidbox";
import { getIn } from "../utils/utils";
import * as vars from "../styles/vars";
import { buttons, helpers } from "../styles";
import { BrandColors } from "../styles/vars";

const Medications = ({
  patientMedications,
  onSave,
  onDelete,
  subject,
  encounterID,
  patientAllergies,
  isClosed,
  title,
}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: ".75rem",
      backgroundColor: "white",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      marginBottom: ".75rem",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    btnGroup: {
      display: "flex",
    },
    chosenMedication: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    },
    chosenMedicationText: {
      fontWeight: "bold",
    },

    chosenMedicationResearch: {
      marginLeft: "10px",
    },
    addBody: {
      position: "relative",
      padding: ".5rem",
      display: "grid",
      gridTemplateColumns: "1fr 75px",
      gridGap: "10px",
    },
    addBodySearchInput: {
      width: "100%",
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: "4px",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      padding: ".25rem",
      fontSize: "1.25rem",
    },
    submit: {
      display: "flex",
      alignItems: "flex-end",
      "& button": {
        fontWeight: "bold",
        height: "35px",
      },
    },
    searchResult: {
      position: "absolute",
      top: "75px",
      left: ".5rem",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      height: "200px",
      borderRadius: "4px",
      overflowY: "auto",
      zIndex: "10",
      backgroundColor: "whitesmoke",
      color: theme.palette.primary.main,
      padding: ".75rem",
      border: `2px solid ${theme.palette.primary.main}`,
      "& div": {
        cursor: "pointer",
        padding: ".25rem",
        "& span": {
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      },
    },
    medicationsList: {
      marginTop: "1.5rem",
      display: "grid",
      gridGap: "10px",
    },
    medication: {
      marginBottom: ".75rem",
      display: "grid",
      alignItems: "flex-end",
      gridTemplateColumns: "min-content 1fr 1fr max-content",
      paddingBottom: ".25rem",
      borderBottom: "3px solid #D32F2F",
      color: "black",
    },
    medicationActive: {
      borderBottom: "3px solid #388E3C",
    },
    code: {
      padding: "0 .25rem",
      borderRight: "1px solid black",
      fontWeight: "bold",
    },
    text: {
      paddingLeft: ".25rem",
    },
    medicationDelete: {
      display: "flex",
      cursor: "pointer",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      color: "#616161",
      "& span": {
        display: "inline-block",
        fontWeight: " bold",
      },
      "&:hover": {
        color: "red",
      },
    },
  }));
  const classes = useStyles();
  const btns = buttons();
  const utils = helpers();
  const [isShow, setShow] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [isNotFound, setNotFound] = React.useState(false);
  const [isStopped, setStopped] = React.useState(false);
  const [currentMedication, setMedication] = React.useState({
    Name: "",
    LexiGenDrugId: null,
    LexiDrugSynId: null,
    RxCUI: null,
  });
  const [addBody, setBody] = React.useState({
    patient: {
      resourceType: "Patient",
      id: subject.id,
    },
    encounter: {
      resourceType: "Encounter",
      id: encounterID,
    },
  });
  const [medications, setMedications] = React.useState([]);
  const [isChosen, setChosen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  function resetForm() {
    setShow(false);
    setChosen(false);
    setBody({
      patient: {
        resourceType: "Patient",
        id: subject.id,
      },
      encounter: {
        resourceType: "Encounter",
        id: encounterID,
      },
    });
    setMedications([]);
    setMedication({
      Name: "",
      LexiGenDrugId: null,
      LexiDrugSynId: null,
      RxCUI: null,
    });
  }
  function searchMedications(query) {
    setMedications([]);
    if (query.length >= 3) {
      setLoading(true);
      aidbox
        .getPlain(`/Dosespot/$dosespot-basesearch-medications/${query}`)
        .then((resp) => {
          if (!isStopped) {
            if (resp.data.length) {
              const temp = resp.data.filter(
                (allergy) =>
                  !patientAllergies.find((exist) => {
                    return (
                      getIn(exist, [
                        "code",
                        "coding",
                        ({ system }) => system === "dosespot:rxcui",
                        0,
                        "code",
                      ]) === allergy.RxCUI.toString()
                    );
                  })
              );
              if (temp.length) {
                setMedications(temp);
                setLoading(false);
                setNotFound(false);
              } else setNotFound(true);
            } else {
              setNotFound(true);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          aidbox.sendErrorLogs({ data: err });
          console.log(err);
        });
    }
  }

  const debounceSearch = React.useCallback(
    debounce(searchMedications, 200),
    []
  );

  function chooseMedication(index) {
    setMedication({ ...medications[index] });
    setChosen(true);
    setBody({ ...addBody, ...medications[index] });
    setMedications([]);
    setDisabled(false);
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          {title ? title : "Medications"}
        </div>
        <div className={classes.btnGroup}>
          {!isClosed ? (
            isShow ? (
              <button
                type="button"
                className={clsx(btns.btn, btns.btnEdit, utils.fontBold)}
                onClick={() => resetForm()}
              >
                Reset
              </button>
            ) : (
              <button
                type="button"
                className={clsx(btns.btn, btns.btnAdd, utils.fontBold)}
                onClick={() => setShow(true)}
              >
                Add
              </button>
            )
          ) : null}
        </div>
      </div>
      {isShow ? (
        <div className={classes.addBody}>
          <div>
            {isChosen ? (
              <div className={classes.chosenMedication}>
                <div className={classes.chosenMedicationText}>
                  <div>Name: {currentMedication.Name}</div>
                  <div>LexiGenDrugId: {currentMedication.LexiGenDrugId}</div>
                  <div>LexiDrugSynId: {currentMedication.LexiDrugSynId}</div>
                  <div>RxCUI: {currentMedication.RxCUI ?? "Not set"}</div>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className={classes.chosenMedicationResearch}
                  onClick={() => setChosen(false)}
                >
                  <CachedIcon />
                </div>
              </div>
            ) : (
              <>
                <label className="required">Name</label>
                <input
                  className={classes.addBodySearchInput}
                  type="text"
                  onBlur={(e) => {
                    setStopped(true);
                    if (isChosen || isNotFound) {
                      setMedications([]);
                      setNotFound(false);
                    }
                    setLoading(false);
                    e.target.value = "";
                  }}
                  onChange={(e) => {
                    debounceSearch(e.target.value);
                  }}
                />
              </>
            )}
          </div>
          {medications.length !== 0 ? (
            <div className={classes.searchResult}>
              {medications.map((a, index) => (
                <div onClick={() => chooseMedication(index)}>
                  <span>
                    {a.Name} - {a.RxCUI}
                  </span>
                </div>
              ))}
            </div>
          ) : null}
          {isNotFound ? (
            <div className={classes.searchResult}>
              <div>Not found </div>
            </div>
          ) : null}
          {isLoading ? (
            <div className={classes.searchResult}>
              <div> Processing... </div>
            </div>
          ) : null}
          <div className={classes.submit}>
            <button
              className={
                isDisabled
                  ? clsx(btns.btn, btns.btnDisabled, utils.fontBold)
                  : clsx(btns.btn, btns.btnAdd, utils.fontBold)
              }
              onClick={() => {
                onSave(addBody, subject);
                resetForm();
              }}
              type="button"
              disabled={isDisabled}
            >
              Save
            </button>
          </div>
        </div>
      ) : null}
      <div className={classes.medicationsList}>
        {(patientMedications || []).map((medication) => (
          <div
            className={clsx(classes.medication, classes.medicationActive)}
            key={medication.id}
          >
            <div className={classes.code}>
              {getIn(
                medication,
                [
                  "medication",
                  "CodeableConcept",
                  "coding",
                  ({ system }) => system === "dosespot:lexigendrugid",
                  0,
                  "code",
                ],
                "Not set"
              )}
            </div>
            <div className={classes.text}>
              {getIn(medication, ["medication", "CodeableConcept", "text"])}
            </div>
            <div className={classes.text}>
              ({getIn(medication, ["status"])})
            </div>
            <div className={classes.medicationDelete}>
              {!isClosed ? (
                <span
                  onClick={() => {
                    const isDelete = window.confirm(
                      `Are you sure you would like to delete ${getIn(
                        medication,
                        ["medication", "CodeableConcept", "text"]
                      )}`
                    );
                    if (isDelete) {
                      onDelete(medication.id, subject.id);
                    }
                  }}
                >
                  &times;
                </span>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Medications.propTypes = {
  patientMedications: PropTypes.instanceOf(Array).isRequired,
  subject: PropTypes.instanceOf(Object).isRequired,
  encounterID: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  patientAllergies: PropTypes.instanceOf(Array).isRequired,
  isClosed: PropTypes.bool.isRequired,
};

export default Medications;
