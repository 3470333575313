import React from "react";
import { produce } from "immer";
import { StoreLayer, Store } from "../../Store";
import { ControllerLayer } from "../../Controller";
import { useAuthContext } from "../../components/AuthContext";
import * as aidbox from "../../utils/aidbox";
import { getIn } from "../../utils/utils";
import Settings from "./Settings";

export default function SettingsLayer() {
  const { user } = useAuthContext(0);
  const store = new Store({}, produce);
  const practId = getIn(user, ["link", 0, "link", "id"]);

  function refetch() {
    aidbox.request({ url: `/Practitioner/${practId}` }).then(({ data }) => {
      store.transform((state) => {
        state.notifications = getIn(data, ["preferences", "notifications"]);
        state.email = user.email;
        state.phone = getIn(data, ["telecom", { system: "phone" }, 0, "value"]);
      });
    });
  }

  const pageController = {
    changePreferences: (v) => {
      aidbox
        .request({
          url: `/Practitioner/${practId}`,
          method: "PATCH",
          data: { preferences: { notifications: v } },
        })
        .then((resp) => {
          refetch();
        });
    },
  };

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <ControllerLayer value={pageController}>
      <StoreLayer store={store}>
        <Settings />
      </StoreLayer>
    </ControllerLayer>
  );
}
