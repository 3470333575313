import { Box, Button, Typography } from "@material-ui/core";
import React from "react";

export default function VisitComplete({ dispatch }) {
  return (
    <>
      <Box sx={{ maxWidth: 600 }}>
        <Typography>This visit is now complete.</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            dispatch({
              type: "reset",
            });
          }}
        >
          To start another, click here.
        </Button>
      </Box>
    </>
  );
}
