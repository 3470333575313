import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import * as ui from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { dateDisplayToVisitTable, href, getIn } from "../../utils/utils";
import Title from "./Title";
import { buttons, helpers } from "../../styles";
import { useAuthContext } from "../AuthContext";
import { postPlain } from "../../utils/aidbox";
import { useHistory } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const baseHeadCells = [
  { id: "start", align: "left", disablePadding: false, label: "Created Date" },
  {
    id: "patient",
    numeric: false,
    disablePadding: false,
    label: "Patient",
  },
  {
    id: "chief",
    numeric: false,
    disablePadding: false,
    label: "Chief Complaint",
  },
  {
    id: "visit",
    numeric: false,
    disablePadding: false,
    label: "Visits Link",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  table: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  tableRow: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  tableCell: (props) => ({
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      display: "block",
      "&::before": {
        display: "inline-block",
        fontWeight: "bold",
        marginRight: theme.spacing(1),
      },
      "&:nth-child(1)::before": {
        content: '"Date:"',
      },
      "&:nth-child(2)::before": {
        content: '"Patient:"',
      },
      "&:nth-child(3)::before": {
        content: '"Chief Complaint:"',
      },
    },
  }),
  tableBody: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  tableHeadCell: {
    fontSize: "1.1rem",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      fontSize: "1rem",
    },
  },
}));

const filterEntryData = (rows) => {
  return rows.map((r) => {
    return {
      id: r.id,
      start: moment(getIn(r, ["period", "start"])).valueOf(),
      patient: `${getIn(r, [
        "subject",
        "resource",
        "name",
        0,
        "given",
        0,
      ])} ${getIn(r, ["subject", "resource", "name", 0, "family"])}`,
      patientID: getIn(r, ["subject", "id"]),
      chief: getIn(r, ["reasonCode", 0, "text"], "—"),
      visit: r.id,
      status: r.status,
      videoStart: getIn(
        r,
        ["videocalls_as_encounter", "0", "start"],
        undefined
      ),
      practitioner: getIn(
        r,
        [
          "participant",
          ({ individual }) => individual.resourceType === "Practitioner",
        ],
        []
      ),
      location: getIn(r, ["location", 0, "location", "id"]),
    };
  });
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, rows } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { practitioner } = useAuthContext();
  let headCells = [...baseHeadCells];

  const videoRows = rows.filter((row) => {
    return getIn(row, ["videoStart"], false);
  });

  const locationRows = rows.filter((row) => {
    return getIn(row, ["location"], false);
  });

  if (videoRows.length > 0) {
    headCells.splice(0, 0, {
      id: "videoStart",
      align: "left",
      disablePadding: false,
      label: "Date of Service",
    });
  }

  if (locationRows.length > 0) {
    headCells.splice(0, 0, {
      id: "location",
      align: "left",
      disablePadding: false,
      label: "Location",
    });
  }

  if (getIn(practitioner, ["practitionerType"], []).includes("behavioral")) {
    headCells = headCells.filter((cell) => {
      return cell.id !== "start";
    });
  }
  return (
    <ui.TableHead>
      <ui.TableRow>
        {headCells.map((headCell) => (
          <ui.TableCell
            key={headCell.id}
            style={{ fontWeight: "700" }}
            align={headCell.align || "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableHeadCell}
          >
            <ui.TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </ui.TableSortLabel>
          </ui.TableCell>
        ))}
      </ui.TableRow>
    </ui.TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function VisitTable({ tableTitle, rows, sortDirection, fetchData }) {
  const classes = useStyles(baseHeadCells);
  const btns = buttons();
  const utils = helpers();
  const [order, setOrder] = React.useState(sortDirection);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { practitioner, user } = useAuthContext();
  const rowsData = filterEntryData(rows);
  const [orderBy, setOrderBy] = React.useState(
    getIn(practitioner, ["practitionerType"], []).includes("behavioral") ||
      getIn(user, ["roleName"]) === "biller"
      ? "videoStart"
      : "start"
  );
  const history = useHistory();

  const assignEncounterToSelf = async (practitionerId, encounterId) => {
    try {
      const resp = await postPlain("/npc/$assign-encounter-to-self", {
        practitionerId,
        encounterId,
      });
      console.log(resp);
      if (resp.status === 200) {
        history.push(`/visits/${encounterId}`);
      }
    } catch (e) {
      if (e.response?.status === 409) {
        alert(e.response?.data);
        fetchData();
      }
    }
  };

  const videoRows = rowsData.filter((row) => {
    return getIn(row, ["videoStart"], false);
  });

  const locationRows = rows.filter((row) => {
    return getIn(row, ["location", 0, "location"], false);
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rowsData.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Title title={tableTitle} />
      <ui.Paper className={classes.paper}>
        <ui.TableContainer>
          <ui.Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowsData.length}
              rows={rowsData}
            />
            <ui.TableBody className={classes.tableBody}>
              {stableSort(rowsData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <ui.TableRow
                      className={classes.tableRow}
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      {videoRows.length > 0 && (
                        <ui.TableCell
                          className={classes.tableCell}
                          component="td"
                          scope="row"
                          padding="default"
                        >
                          {dateDisplayToVisitTable(row.videoStart)}
                        </ui.TableCell>
                      )}
                      {locationRows.length > 0 && (
                        <ui.TableCell
                          className={classes.tableCell}
                          component="td"
                          scope="row"
                          padding="default"
                        >
                          {row.location}
                        </ui.TableCell>
                      )}
                      {!getIn(practitioner, ["practitionerType"], []).includes(
                        "behavioral"
                      ) && (
                        <ui.TableCell
                          className={classes.tableCell}
                          component="td"
                          scope="row"
                          padding="default"
                        >
                          {dateDisplayToVisitTable(row.start, true)}
                        </ui.TableCell>
                      )}
                      <ui.TableCell
                        className={classes.tableCell}
                        align="center"
                      >
                        <RouterLink
                          style={{
                            cursor: "pointer",
                            borderBottom: "2px solid green",
                          }}
                          to={href("patients", row.patientID)}
                        >
                          {row.patient}
                        </RouterLink>
                      </ui.TableCell>
                      <ui.TableCell
                        className={classes.tableCell}
                        align="center"
                      >
                        {row.chief}
                      </ui.TableCell>

                      <ui.TableCell
                        className={classes.tableCell}
                        align="center"
                      >
                        {console.log("row", row)}
                        {row.practitioner?.length > 0 ? (
                          <RouterLink
                            className={clsx(
                              btns.btn,
                              btns.btnLink,
                              utils.textUpper
                            )}
                            to={href("visits", row.visit)}
                          >
                            Show visit
                          </RouterLink>
                        ) : (
                          <ui.Button
                            className={clsx(
                              btns.btn,
                              btns.btnPrimary,
                              utils.textUpper
                            )}
                            style={{ display: "inline-block" }}
                            onClick={() =>
                              assignEncounterToSelf(practitioner.id, row.visit)
                            }
                          >
                            Take This Visit
                          </ui.Button>
                        )}
                      </ui.TableCell>
                    </ui.TableRow>
                  );
                })}
            </ui.TableBody>
          </ui.Table>
        </ui.TableContainer>
        <ui.TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </ui.Paper>
    </div>
  );
}

VisitTable.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  rows: PropTypes.instanceOf(Array).isRequired,
};

export default VisitTable;
