import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  MenuItem,
  Grid,
  Snackbar,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import clsx from "clsx";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { helpers } from "../styles/index";
import { getIn } from "../utils/utils";
import aidbox, { patchResource, postPlain, putPlain } from "../utils/aidbox";
import * as vars from "../styles/vars";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import InputMask from "react-input-mask";
import { useLocation } from "react-router";
import moment from "moment";
import Spinner from "../components/Spinner";
import DateTime from "react-datetime";
import { useAuthContext } from "../components/AuthContext";

function NewEditPatientPage({ patientData, onSubmit }) {
  const location = useLocation();
  const [alertBar, setAlertBar] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alertBarMessage, setAlertBarMessage] = useState("");
  const [redirect, setRedirect] = useState("");
  const showCreateVisit = patientData?.createVisit === false ? false : true;
  const showSendEmail = patientData?.sendEmail === false ? false : true;
  const patientId = location.state;
  const { user } = useAuthContext();

  useEffect(() => {
    if (patientId) {
      setLoading(true);
      (async () => {
        const patient = await aidbox.getResource("Patient", patientId);
        setLoading(false);

        const patientResponse = await aidbox.getPlain(
          `/Patient/$patient-detail?patientId=${patientId}`
        );
        const patientEmail =
          getIn(patient, [
            "data",
            "telecom",
            ({ system }) => system === "email",
            0,
            "value",
          ]) || getIn(patientResponse, ["data", "user", "email"]);
        formik.setFieldValue(
          "firstName",
          getIn(patient, ["data", "name", 0, "given", 0])
        );
        formik.setFieldValue(
          "lastName",
          getIn(patient, ["data", "name", 0, "family"])
        );
        formik.setFieldValue(
          "birthDate",
          moment(getIn(patient, ["data", "birthDate"])).format("YYYY-MM-DD")
        );
        formik.setFieldValue("gender", getIn(patient, ["data", "gender"]));
        formik.setFieldValue("email", patientEmail);
        formik.setFieldValue(
          "phone",
          getIn(patient, [
            "data",
            "telecom",
            ({ system }) => system === "phone",
            0,
            "value",
          ])
        );
      })(patientId);
    }
  }, [patientId]);

  const validationSchema = yup.object({
    firstName: yup
      .string("Enter patient's first name")
      .required("First name is required"),
    lastName: yup
      .string("Enter patient's last name")
      .required("Last name is required"),
    birthDate: yup
      .string("This must be a valid date")
      .required("Birth Date is required")
      .test(
        "past-date-test",
        "Birth date should not be greater than the current date",
        (date) => {
          return moment().isSameOrAfter(moment(date));
        }
      ),
    email: yup
      .string("Enter patient's email")
      .email("Enter a valid email")
      .required("Email is required"),
    phone: yup
      .string("Phone number must contain only digits")
      .transform((value) => value.replace(/[^\d]/g, ""))
      .min(10, "Phone must be 10 digits and include area code")
      .max(10, "Phone must be 10 digits and include area code")
      .matches(/^[0-9]*$/, "Phone must contain only digits")
      .required("Phone number is required"),
    gender: yup.string().required("Patient gender is required"),
  });

  const defaultSubmit = async (values) => {
    const submissionValues = { ...values };
    submissionValues.phone = submissionValues.phone.replace(/[^\d]/g, "");
    const birthDate = moment(submissionValues.birthDate).format("YYYY-MM-DD");

    try {
      const payloadObject = {
        patient: {
          name: [
            {
              given: [submissionValues.firstName],
              family: submissionValues.lastName,
            },
          ],
          gender: submissionValues.gender,
          telecom: [
            { value: submissionValues.phone, system: "phone" },
            { value: submissionValues.email, system: "email" },
          ],
          birthDate: birthDate,
          physicianAppOrganization: user.physicianAppOrganization,
        },
        sendEmail: submissionValues.sendEmail,
      };
      if (patientId) {
        payloadObject.id = patientId;
      }
      const resp = patientId
        ? await putPlain("/Patient/$update-patient", payloadObject)
        : await postPlain("/Patient/$create-patient", payloadObject);
      const code = getIn(resp, ["status"]);

      if (patientId && code === 200) {
        setRedirect({
          pathname: `/patients/${patientId}`,
        });
      } else if (code === 201 || code === 200) {
        const patientIdFromResponse = getIn(resp, [
          "data",
          "entry",
          ({ resource }) => resource.resourceType === "Patient",
          0,
          "resource",
          "id",
        ]);

        setRedirect(
          formik.values.createVisit
            ? {
                pathname: "/new-visit",
                search: `?patient=${patientIdFromResponse}`,
              }
            : "/patients"
        );
      } else {
        throw new Error();
      }
    } catch (e) {
      if (e.response?.data) {
        setAlertBarMessage(
          e.response?.data?.message || e.response?.data?.toString()
        );
      } else {
        setAlertBarMessage(
          "There was an error. If this continues to happen, contact Recuro technical support."
        );
      }

      setAlertBar(true);
      console.log("Error", e.response);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: patientData?.values?.firstName || "",
      lastName: patientData?.values?.lastName || "",
      email: patientData?.values?.email || "",
      phone: patientData?.values?.phone || "",
      gender: patientData?.values?.gender || "",
      birthDate: patientData?.values?.birthDate || "",
      sendEmail: patientData?.sendEmail === false ? false : true,
      createVisit: patientData?.createVisit === false ? false : true,
    },
    isInitialValid: false,
    validationSchema,
    onSubmit: onSubmit || defaultSubmit,
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "min-content",
    },
    patientList: {
      display: "grid",
      gridRowGap: "1rem",
    },
    patient: {
      display: "grid",
      gridTemplateColumns: "1fr max-content",
      gridGap: "1rem",
      alignItems: "center",
      borderBottom: `2px solid ${vars.brown500}`,
      padding: ".5rem 0",
    },
    text: {
      textTransform: "capitalize",
      fontWeight: "bold",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    formRow: {
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const utils = helpers();

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  if (loading) return <Spinner />;

  return (
    <ErrorBoundary>
      <div className={clsx(utils.card, classes.root)}>
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          {patientId ? "Edit Patient" : "Create a New Patient"}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Grid container item spacing={3} xs={6}>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="firstName"
                  id="firstName"
                  label="First Name"
                  variant="outlined"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="lastName"
                  id="lastName"
                  label="Last Name"
                  variant="outlined"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="birthDate"
                  label="Birth Date"
                  type="date"
                  name="birthDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.birthDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.birthDate && Boolean(formik.errors.birthDate)
                  }
                  helperText={
                    formik.touched.birthDate && formik.errors.birthDate
                  }
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Gender"
                  variant="outlined"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  helperText={formik.touched.gender && formik.errors.gender}
                  onBlur={formik.handleBlur}
                  inputProps={{
                    name: "gender",
                    id: "gender",
                  }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="self-identify">Self-Identify</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="email"
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <InputMask
                  mask="(999)-999-9999"
                  value={formik.values.phone}
                  disabled={false}
                  maskChar=" "
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {() => (
                    <TextField
                      fullWidth
                      value={formik.values.phone}
                      name="phone"
                      id="phone"
                      label="Phone"
                      variant="outlined"
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
            {!patientId && (
              <Grid container item spacing={3} xs={12}>
                <Grid item>
                  <FormGroup>
                    {showSendEmail === true ? (
                      <FormControlLabel
                        label="Send a welcome email to the patient?"
                        control={
                          <Checkbox
                            onChange={formik.handleChange}
                            checked={formik.values.sendEmail}
                            name="sendEmail"
                          />
                        }
                      />
                    ) : null}
                    {showCreateVisit === true ? (
                      <FormControlLabel
                        label="Create a visit for the patient?"
                        control={
                          <Checkbox
                            onChange={formik.handleChange}
                            checked={formik.values.createVisit}
                            name="createVisit"
                          />
                        }
                      />
                    ) : null}
                  </FormGroup>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {patientId ? "Update" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          message={alertBarMessage}
          open={alertBar}
          autoHideDuration={5000}
          onClose={() => setAlertBar(false)}
        ></Snackbar>
      </div>
    </ErrorBoundary>
  );
}
export default NewEditPatientPage;
