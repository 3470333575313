import React, { useEffect, useState } from "react";
import * as ui from "@material-ui/core";
import * as aidbox from "../utils/aidbox";
import { getIn } from "../utils/utils";

const PHQ9Viewer = ({ rows }) => {
  console.log("PHQ9 rows", rows);
  const total = rows
    .map((item) => parseInt(item.answerText || 0, 10))
    .reduce((t, i) => t + i);
  return (
    <ui.TableContainer style={{ maxHeight: 400 }}>
      <ui.Table stickyHeader>
        <ui.TableHead>
          <ui.TableRow>
            <ui.TableCell>Question</ui.TableCell>
            <ui.TableCell>Answer</ui.TableCell>
          </ui.TableRow>
        </ui.TableHead>
        <ui.TableBody>
          {rows.map((row) => (
            <ui.TableRow key={row.id}>
              <ui.TableCell>{row.questionText}</ui.TableCell>
              <ui.TableCell>{row.answerText || 0}</ui.TableCell>
            </ui.TableRow>
          ))}
          <ui.TableRow>
            <ui.TableCell>Total</ui.TableCell>
            <ui.TableCell>{total}</ui.TableCell>
          </ui.TableRow>
        </ui.TableBody>
      </ui.Table>
    </ui.TableContainer>
  );
};

const RiskChecklist = ({ params }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    (async () => {
      const checklistId = getIn(params, ["row", "checklist", "0", "id"]);
      const checklist = await aidbox.getPlain(`/Checklist/${checklistId}`);
      const items = getIn(checklist, ["data", "items"], []).map((item) => {
        return {
          title: item.title,
          status: item.status,
        };
      });
      setRows(items);
      console.log("CL", checklist);
    })();
  });

  console.log("Risk Checklist", params);

  return (
    <div>
      <p style={{ marginBottom: 10 }}>
        Items with a status of <strong>selected</strong> have been identified by
        the patient as important. Those with a status of{" "}
        <strong>removed</strong> were recommended but dismissed by the patient.
        Items with a status of <strong>completed</strong> have been addressed by
        the patient.
      </p>
      <ui.TableContainer style={{ maxHeight: 400 }}>
        <ui.Table stickyHeader>
          <ui.TableHead>
            <ui.TableRow>
              <ui.TableCell>Recommendation</ui.TableCell>
              <ui.TableCell>Status</ui.TableCell>
            </ui.TableRow>
          </ui.TableHead>
          <ui.TableBody>
            {rows.map((row) => (
              <ui.TableRow key={row.id}>
                <ui.TableCell>{row.title}</ui.TableCell>
                <ui.TableCell>{row.status || 0}</ui.TableCell>
              </ui.TableRow>
            ))}
          </ui.TableBody>
        </ui.Table>
      </ui.TableContainer>
    </div>
  );
};

export { PHQ9Viewer, RiskChecklist };
