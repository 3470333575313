import moment from "moment";
import React, { useEffect, useState } from "react";
import HsChat from "./HsChat/HsChat";
import {
  BOOL_REPLIES,
  EXAMPLES,
  STI_SYMPTOMS,
  PAIN_RATING,
  END_REPLIES,
  ADDITIONAL_SYMPTOMS,
  DECREASED_ENERGY_SYMPTOMS,
  TEMPS,
} from "../constants/QuickReplies";
import { v4 as uuid } from "uuid";
import { postPlain } from "../utils/aidbox";
import { getIn } from "../utils/utils";
import { Button } from "@material-ui/core";

export default function ChatBotIntake({
  dispatch,
  patientData,
  encounterData,
}) {
  const BOT_USER = {
    id: "m-bot",
    _id: "m-bot",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Fbot-avatar.png?alt=media&token=ef386c69-0d95-475b-b782-2fa62754ee87",
  };
  const AGENT_USER = {
    id: patientData.user.id,
    _id: patientData.user.id,
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Favatar.png?alt=media&token=bdd40e1f-0eb5-4aa9-9d8f-07f6a093af6b",
  };
  const DEFAULT_MESSAGE = {
    id: uuid(),
    text: "Please select the patient's chief complaint, or enter it in the message box below.",
    createdAt: moment().toISOString(),
    user: BOT_USER,
    datetime: moment().toISOString(),
    quickReplies: EXAMPLES,
  };
  const [chatSession, setChatSession] = useState("");
  const [messages, setMessages] = useState([{ ...DEFAULT_MESSAGE }]);
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    setChatSession(uuid());
  }, []);

  useEffect(() => {
    console.log(chiefComplaint);
  }, [chiefComplaint]);

  const continueFlow = () => {
    dispatch({
      type: "nextStep",
    });
    dispatch({
      type: "updateEncounterData",
      data: { ...encounterData, messages, chiefComplaint },
    });
  };

  const initializeChat = () => {
    setMessages([{ ...DEFAULT_MESSAGE }]);
    setChiefComplaint("");
    setCanContinue(false);
    setChatSession(uuid());
  };

  const sendToDialogflow = async (messageText) => {
    if (!chatSession) return;
    (async () => {
      const result = await postPlain("/$dialog-flow", {
        message: messageText,
        sessionId: chatSession,
      });
      handleGoogleResponse(getIn(result, ["data", "0"]));
    })();
  };

  const showBotResponse = (text, replies) => {
    const botResponse = {
      text,
      createdAt: moment().toISOString(),
      datetime: moment().toISOString(),
      user: BOT_USER,
    };

    if (replies) {
      botResponse.quickReplies = replies;
    }

    setMessages((prev) => {
      botResponse.id = prev.length;
      return [...prev, botResponse];
    });
  };

  const onSend = (message) => {
    setMessages((prev) => {
      return [
        ...prev,
        {
          id: prev.length,
          text: message.text,
          user: AGENT_USER,
          createdAt: moment().toISOString(),
          datetime: moment().toISOString(),
        },
      ];
    });
    sendToDialogflow(message.text);
  };

  const endConversation = () => {
    setCanContinue(true);
  };

  const handleGoogleResponse = (result) => {
    const lastMessageReceived = getIn(
      result,
      [
        "queryResult",
        "diagnosticInfo",
        "fields",
        "end_conversation",
        "boolValue",
      ],
      false
    );
    if (lastMessageReceived) {
      endConversation();
      return;
    }

    let bool;
    let pain_rating;
    let decreased_energy;
    let temps;
    let sti_symptoms;
    let chiefComplaintIntent = getIn(result, [
      "queryResult",
      "intent",
      "displayName",
    ]);
    let contexts = getIn(result, ["queryResult", "outputContexts"], false);

    let action = getIn(result, ["queryResult", "action"]);
    let text = getIn(result, ["queryResult", "fulfillmentText"]);

    if (contexts) {
      bool =
        contexts.filter((context) => context.name.includes("_bool")).length > 0;
      pain_rating =
        contexts.filter((context) => context.name.includes("_1-10")).length > 0;
      decreased_energy =
        contexts.filter((context) => context.name.includes("_de")).length > 0;
      temps =
        contexts.filter((context) => context.name.includes("_temps")).length >
        0;
      sti_symptoms =
        contexts.filter((context) => context.name.includes("_stis")).length > 0;
    }
    let quickReplies = false;
    if (bool) {
      quickReplies = BOOL_REPLIES;
    }
    if (pain_rating) {
      quickReplies = PAIN_RATING;
    }
    if (decreased_energy) {
      quickReplies = DECREASED_ENERGY_SYMPTOMS;
    }
    if (temps) {
      quickReplies = TEMPS;
    }
    if (sti_symptoms) {
      quickReplies = STI_SYMPTOMS;
    }
    if (action === "multi-select") {
      quickReplies = ADDITIONAL_SYMPTOMS;
    }

    setChiefComplaint(chiefComplaintIntent);

    showBotResponse(text, quickReplies);
  };

  return (
    <div style={{ maxWidth: "600px" }}>
      <HsChat
        onSend={onSend}
        user={AGENT_USER}
        messages={messages}
        showToolbar={!canContinue}
      />
      {canContinue && (
        <div>
          <Button
            onClick={() => continueFlow()}
            color="primary"
            variant="contained"
            fullWidth
            type="button"
            style={{ marginBottom: 10 }}
          >
            Continue
          </Button>
          <Button onClick={() => initializeChat()} fullWidth type="button">
            Restart Triage
          </Button>
        </div>
      )}
    </div>
  );
}
