import React, { useEffect } from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import { useParams } from "react-router";
import { produce } from "immer";
import moment from "moment";
import AgoraRTC from "agora-rtc-sdk-ng";
import { StoreLayer, Store } from "../../Store";
import { ControllerLayer } from "../../Controller";
import { useAuthContext } from "../../components/AuthContext";
import * as aidbox from "../../utils/aidbox";
import { getIn, href, omit } from "../../utils/utils";
import VisitProcess from "./VisitProcess";
import { FamilyMemberHistory } from "../../utils/aidboxHelpers";

const store = new Store(
  {
    isLoading: true,
    isSaving: false,
    isPhone: false,
    reviewStatus: "",
    data: null,
    error: null,

    snackBar: {
      open: false,
      message: "",
    },
    video: {
      id: null,
      appID: "c1915d26772549409219b0451ffebc49",
      mic: true,
      camera: true,
      ready: false,
      me: null,
      meRef: null,
      client: null,
      localAudioTrack: null,
      localVideoTrack: null,
      videoLinkCopied: false,
    },
    isModalSync: false,
    modalState: {
      isOpen: false,
    },
    setting: {
      url: "/$graphql-query/physician-app-visit",
      method: "POST",
      data: {
        variables: {
          encId: null,
        },
      },
    },
    chatMessages: [],
    chatVersion: -1,
    participantId: null,
    videoCallDescription: "",
    videoCallStartTime: null,
    billingCodes: {},
    assessment: {
      patientHistory: null,
      appearance: null,
      behavior: null,
      speech: null,
      mood: null,
      thought: null,
      suicidal: null,
      process: null,
      associations: null,
      fund_of_knowledge: null,
      attention: null,
      insight: null,
      judgment: null,
      memory: null,
      motorStatus: null,
      orientation: null,
      physicianSummary: null,
      billingCodes: [],
    },
    physicianSummarySpeechInput: {
      recongnizer: null,
      isRecognizing: false,
      almostRecognizedText: "",
    },
    mediaResources: [],
  },
  produce
);

function VisitLayer(props) {
  const { id } = useParams();
  const { user } = useAuthContext();

  store.transform((state) => {
    state.user = user;
    state.id = id;
    state.setting.data.variables.encId = id;
  });

  const pageController = {
    refetch: () => {
      fetchData();
    },
    openSnackBar: (message) => {
      store.transform((s) => {
        s.snackBar = {
          open: true,
          message,
        };
      });
    },
    closeSnackBar: () => {
      store.transform((s) => {
        s.snackBar = {
          open: false,
          message: "",
        };
      });
    },
    createPatientInDosespot: async (patientID) => {
      try {
        const t = await aidbox.postPlain(
          `/Patient/${patientID}/$dosespot-create-patient`
        );
        if (!t.data.status) {
          pageController.refetch();
        }
        return {
          status: !!t.data.status,
          error: t.data.data,
        };
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        return {
          status: false,
          error: err,
        };
      }
    },
    markEncounterReadyForReview: async () => {
      try {
        await aidbox.patchPlain(`/npc/$mark-encounter-ready-for-review`, {
          encounterId: store.value.id,
        });
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    closeVisit: async () => {
      try {
        await aidbox.getPlain(`/Encounter/${store.value.id}/$close-visit`);
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    syncDoseSpot: async (subject) => {
      const patientCreating = await pageController.createPatientInDosespot(
        getIn(subject, ["resource", "id"])
      );
      if (patientCreating.status) {
        store.transform((state) => {
          state.isModalSync = true;
        });
        try {
          await aidbox.getPlain(
            `/Encounter/${store.value.id}/$dosespot-sync?debug=false`
          );
          pageController.refetch();
        } catch (err) {
          aidbox.sendErrorLogs({
            data: err,
          });
          console.log(err);
        }
        store.transform((state) => {
          state.isModalSync = false;
        });
      }
    },
    onEditVideoCallDescription: async (e) => {
      console.log("on change");
      store.transform((v) => {
        v.videoCallDescription = e.target.value;
      });
    },
    setVideoCallStartTime: async (value) => {
      console.log("Setting new time", value);
      store.transform((v) => {
        v.videoCallStartTime = value;
      });
    },
    saveVideoCallDescription: async (e) => {
      console.log("save");
      aidbox.request({
        url: `/VideoCall/${getIn(store.value, ["data", "videocall", 0, "id"])}`,
        method: "PATCH",
        data: {
          description: store.value.videoCallDescription,
        },
      });
    },
    saveVideoCallStartTime: async (e) => {
      console.log("save");
      aidbox.request({
        url: `/VideoCall/${getIn(store.value, ["data", "videocall", 0, "id"])}`,
        method: "PATCH",
        data: {
          start: store.value.videoCallStartTime,
        },
      });
    },
    setVideoLinkCopied: (e) => {
      e.preventDefault();
      store.transform((s) => {
        s.video.videoLinkCopied = true;
      });
    },
    disableAudio: async () => {
      store.value.video.client.unpublish([store.value.video.localAudioTrack]);
      store.transform((s) => {
        s.video.mic = false;
      });
    },
    enableAudio: async () => {
      store.value.video.client.publish([store.value.video.localAudioTrack]);
      store.transform((s) => {
        s.video.mic = true;
      });
    },
    disableVideo: async () => {
      store.value.video.client.unpublish([store.value.video.localVideoTrack]);
      store.value.video.localVideoTrack.stop();
      store.transform((s) => {
        s.video.camera = false;
      });
    },
    enableVideo: async () => {
      store.value.video.client.publish([store.value.video.localVideoTrack]);
      store.value.video.localVideoTrack.play(store.value.video.me, {
        mirror: true,
      });
      store.transform((s) => {
        s.video.camera = true;
      });
    },
    startVideoCall: async (e, me, patient) => {
      let localAudioTrack;
      let localVideoTrack;
      try {
        localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      } catch (err) {
        console.log(err);
      }

      if (!localAudioTrack || !localVideoTrack) {
        console.log("fail");
        store.transform((s) => {
          s.video.message =
            "Microphone and video need to be enabled in your browser.";
        });
        return;
      }

      store.transform((s) => {
        s.video.message = null;
      });

      if (!store.value.video.client) {
        store.transform((s) => {
          s.video.client = AgoraRTC.createClient({
            mode: "rtc",
            codec: "vp8",
          });
        });
      }

      store.value.video.client.on("user-unpublished", (userVideo) => {
        const playerContainer = document.getElementById(userVideo.uid);
        playerContainer && unmountComponentAtNode(playerContainer);
      });

      store.value.video.client.on(
        "user-published",
        async (userVideo, mediaType) => {
          await store.value.video.client.subscribe(userVideo, mediaType);
          console.log("subscribe success");

          if (mediaType === "video") {
            const remoteVideoTrack = userVideo.videoTrack;
            const playerContainer = React.createElement("div", {
              id: userVideo.uid.toString(),
              style: {
                width: "100%",
                height: "100%",
              },
            });
            ReactDOM.render(playerContainer, patient.current);
            remoteVideoTrack.play(userVideo.uid.toString());
          }

          if (mediaType === "audio") {
            const remoteAudioTrack = userVideo.audioTrack;
            remoteAudioTrack.play();
          }
        }
      );

      const uid = await store.value.video.client.join(
        store.value.video.appID,
        store.value.data.videocall[0].id,
        null,
        null
      );
      console.log("5");

      store.transform((s) => {
        s.video.localAudioTrack = localAudioTrack;
        s.video.localVideoTrack = localVideoTrack;
      });

      const playerContainer = React.createElement("div", {
        id: uid.toString(),
        style: {
          width: "100%",
          height: "100%",
          zIndex: "1000",
        },
      });
      ReactDOM.render(playerContainer, me.current);
      console.log("8");

      localVideoTrack.play(uid.toString(), {
        mirror: true,
      });
      console.log("9");

      await store.value.video.client.publish([
        localAudioTrack,
        localVideoTrack,
      ]);
      console.log("10");
      store.transform((s) => {
        s.video.me = uid.toString();
        s.video.meRef = me;
        s.video.ready = true;
      });
      await aidbox.request({
        url: `/VideoCall/${store.value.data.videocall[0].id}`,
        method: "PATCH",
        data: {
          status: "ready",
        },
      });
    },
    leaveVideoCall: async () => {
      console.log("leave");
      store.value.video.localAudioTrack.close();
      store.value.video.localVideoTrack.close();

      store.value.video.client.remoteUsers.forEach((userVideo) => {
        const playerContainer = document.getElementById(userVideo.uid);
        playerContainer && unmountComponentAtNode(playerContainer);
      });
      unmountComponentAtNode(store.value.video.meRef.current);

      await store.value.video.client.leave();
      store.transform((s) => {
        s.video.me = null;
        s.video.ready = false;
        s.video.localAudioTrack = null;
        s.video.localAudioTrack = null;
      });
    },
    scheduleVideoVisit: async (e) => {
      e.preventDefault();
      console.log(store.value);
      const resp = await aidbox.request({
        url: `/VideoCall?.encounter.id=${store.value.id}`,
        method: "POST",
        data: {
          encounter: {
            id: store.value.id,
            resourceType: "Encounter",
          },
          status: "not-started",
        },
      });
      fetchData();
    },
    prescribe: async (e, subject) => {
      e.preventDefault();
      store.transform((state) => {
        state.modalState = {
          isOpen: true,
          onClose: () => {
            store.transform((stateS) => {
              stateS.modalState = {
                isOpen: false,
                isLoading: false,
              };
            });
          },
        };
      });
      const patientCreating = await pageController.createPatientInDosespot(
        subject.id
      );
      if (!patientCreating.status) {
        let creatingError = "";
        if (Object.hasOwnProperty.call(patientCreating.error, "phone")) {
          console.log(patientCreating.error.details);
          creatingError = `${patientCreating.error.phone} - ${getIn(
            patientCreating.error,
            ["details", "ModelState", "request.PrimaryPhone", 0]
          )}`;
        } else {
          creatingError = "Unknow error";
        }
        store.transform((state) => {
          state.modalState = {
            isOpen: true,
            url: "",
            patientCreatingError: !patientCreating.status,
            patientError: creatingError,
            onClose: () => {
              store.transform((stateS) => {
                stateS.modalState = {
                  isOpen: false,
                  isLoading: false,
                };
              });
            },
          };
        });
      } else {
        store.transform((state) => {
          state.modalState = {
            isOpen: true,
            frameCreating: true,
            onClose: () => {
              store.transform((stateS) => {
                stateS.modalState = {
                  isOpen: false,
                  isLoading: false,
                };
              });
            },
          };
        });
        console.log("opening DoseSpot site");
        let resp = null;
        try {
          resp = await aidbox.getPlain(
            `/Encounter/${store.value.id}/$dosespot-iframe-url`
          );
          console.log(resp);
          store.transform((state) => {
            state.modalState = {
              isOpen: true,
              url: resp.data,
              isLoading: false,
              onClose: () => {
                store.transform((stateS) => {
                  stateS.modalState = {
                    isOpen: false,
                    isLoading: false,
                  };
                });
                pageController.syncDoseSpot(subject);
              },
            };
          });
        } catch (err) {
          aidbox.sendErrorLogs({
            data: err,
          });
          console.log(err);
          store.transform((state) => {
            state.modalState = {
              isOpen: true,
              isLoading: false,
              url: "",
              frameCreatingError: true,
              frameError: "Unknown error",
              onClose: () => {
                store.transform((stateS) => {
                  stateS.modalState = {
                    isOpen: false,
                    isLoading: false,
                  };
                });
              },
            };
          });
        }
      }
    },
    saveFamilyMemberHistory: async (body) => {
      console.log("Body", body);
      try {
        const newHistory = new FamilyMemberHistory();
        newHistory.setPatientId(body.subject.id);
        newHistory.setDisplayName(body.code?.text);
        const code = getIn(body, ["code", "coding", "0"]) || {};
        newHistory.condition[0].code = {
          text: body.code?.text,
          coding: [
            {
              code: code.code ?? "UserDefined",
              system: code.system ?? "recuro:userdefined",
              display: code.display ?? userDefined,
              userSelected: true,
            },
          ],
        };
        await aidbox.postPlain(`/FamilyMemberHistory`, {
          ...newHistory,
        });
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    deleteFamilyMemberHistory: async (history) => {
      try {
        await aidbox.deletePlain(`/FamilyMemberHistory/${history.id}`);
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    saveCondition: async (body) => {
      try {
        await aidbox.putPlain(`/Condition`, {
          ...body,
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/condition-category",
                  code: "problem-list-item",
                  display: "Problem List Item",
                },
              ],
            },
          ],
        });
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    deleteCondition: async (conditionID) => {
      try {
        await aidbox.deletePlain(`/Condition/${conditionID}`);
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    saveMedication: async (body, subject) => {
      if (
        !getIn(subject, [
          "resource",
          "identifier",
          ({ system }) => system === "dosespot:patient",
          0,
          "value",
        ])
      ) {
        await pageController.createPatientInDosespot(
          getIn(subject, ["resource", "id"])
        );
      }
      try {
        await aidbox.postPlain(
          `/MedicationStatement/$dosespot-add-medication/`,
          body
        );
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    deleteMedication: async (medicationID, patientID) => {
      try {
        await aidbox.postPlain(
          `/MedicationStatement/${medicationID}/${patientID}/$dosespot-delete-medication/`
        );
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    saveAllergy: async (body, subject) => {
      if (
        !getIn(subject, [
          "resource",
          "identifier",
          ({ system }) => system === "dosespot:patient",
          0,
          "value",
        ])
      ) {
        await pageController.createPatientInDosespot(
          getIn(subject, ["resource", "id"])
        );
      }
      try {
        await aidbox.postPlain(
          `/AllergyIntolerance/$dosespot-add-allergy/`,
          body
        );
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    deleteAllergy: async (allergyID, patientID) => {
      try {
        await aidbox.postPlain(
          `/AllergyIntolerance/${allergyID}/${patientID}/$dosespot-delete-allergy/`
        );
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    disableFollowUp: async (followId) => {
      try {
        await aidbox.patchResource({
          resourceType: "ScheduledChatMessage",
          id: followId,
          status: "disabled",
        });
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log("error update status");
      }
    },
    enableFollowUp: async (followId) => {
      try {
        await aidbox.patchResource({
          resourceType: "ScheduledChatMessage",
          id: followId,
          status: "in-queue",
        });
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log("error update status");
      }
    },
    saveFollowUp: async (message) => {
      try {
        await aidbox.createResource({
          resourceType: "ScheduledChatMessage",
          ...message,
        });
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    saveDiagnosis: async (body) => {
      try {
        await aidbox.putPlain(`/Condition`, {
          ...body,
          category: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/condition-category",
                  code: "encounter-diagnosis",
                  display: "Encounter Diagnosis",
                },
              ],
            },
          ],
        });
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    deleteDiagnosis: async (diagnosisID) => {
      try {
        await aidbox.deletePlain(`/Condition/${diagnosisID}`);
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    saveVisitInstructions: async (text) => {
      try {
        await aidbox.postPlain(
          `/Encounter/${store.value.id}/$save-visit-instruction`,
          {
            text,
            date: moment().utc().format(),
          }
        );
        pageController.refetch();
        // TODO: trigger chat refresh
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    savePhysicianNotes: async (text) => {
      try {
        await aidbox.postPlain(
          `/Encounter/${store.value.id}/$save-physician-note`,
          {
            text,
            date: moment().utc().format(),
          }
        );
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    saveEncounterSummary: async (text) => {
      try {
        await aidbox.postPlain(
          `/Encounter/${store.value.id}/$save-encounter-summary`,
          {
            text,
            date: moment().utc().format(),
          }
        );
        pageController.refetch();
      } catch (err) {
        aidbox.sendErrorLogs({
          data: err,
        });
        console.log(err);
      }
    },
    updateEncounter: async () => {
      aidbox
        .patchResource({
          resourceType: "Encounter",
          id: store.value.id,
          status: "in-progress",
        })
        .then(() => pageController.refetch())
        .catch((err) => {
          aidbox.sendErrorLogs({
            data: err,
          });
          console.log(err);
        });
    },
    onSend: async (msgs) => {
      const participantId = getIn(
        store.value.data,
        [
          "chat",
          "resource",
          "participants",
          (p) =>
            p?.person?.id ===
            getIn(store.value.user, ["link", 0, "link", "id"]),
          0,
          "id",
        ],
        "unknown"
      );
      if (!msgs.text) return;

      const msg = await aidbox.createResource({
        resourceType: `Chat/${store.value.data.chat.id}/$send-message`,
        text: msgs.text,
        datetime: moment().toISOString(),
        from: {
          id: participantId,
          resourceType: "ChatParticipant",
        },
      });
      store.transform((state) => {
        state.chatMessages.push({
          id: msg.data.id,
          text: msgs.text,
          createdAt: moment(new Date()).format("h:mm A"),
          user: {
            id: participantId,
            resourceType: "Practitioner",
            avatar:
              user.avatar ||
              "https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Favatar.png?alt=media&token=bdd40e1f-0eb5-4aa9-9d8f-07f6a093af6b",
          },
        });
      });
    },
    clearMessages: async () => {
      console.log("clearMessages");
      store.transform((state) => {
        state.chatMessages = [];
        state.chatVersion = -1;
      });
    },
    fetchMessages: async () => {
      const userLinkId = getIn(
        store.value.user,
        ["link", 0, "link", "id"],
        "unknown"
      );
      let encounter = await aidbox.getPlain(`/Encounter?id=${store.value.id}`);
      encounter = encounter.data.entry[0].resource;
      const encounterMessages = encounter.chatMessages || [];
      if (store.value.chatVersion === -1) {
        const { version } = (await aidbox.getPlain(`/ChatMessage/$changes`))
          .data;
        store.transform((state) => {
          state.chatVersion = version;
        });
        const { data } = await aidbox.readResource("ChatMessage", {
          _count: 1000,
          ".chat.id": encounter.chat.id,
          _sort: "datetime",
        });
        const chatMessages = data.entry.map((e) => e.resource);

        const msgs = [...chatMessages, ...encounterMessages].map((resource) => {
          const userId =
            resource.from.id ||
            `${resource.from.identifier?.system}/${resource.from.identifier?.value}`;

          return {
            id: resource.id,
            text: resource.text,
            createdAt: moment(
              resource.datetime || resource.meta.createdAt
            ).format("h:mm A"),
            user: {
              id: resource.from.id,
              avatar:
                ["supdoc:bot/chat-bot", "m-bot"].includes(userId) ||
                userId.includes("cp-m-bot")
                  ? "https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Fbot-avatar.png?alt=media&token=ef386c69-0d95-475b-b782-2fa62754ee87"
                  : "https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Favatar.png?alt=media&token=bdd40e1f-0eb5-4aa9-9d8f-07f6a093af6b",
            },
          };
        });
        store.transform((state) => {
          state.chatMessages = msgs;
        });
      } else {
        let data = {};
        try {
          data = await aidbox.readResource("ChatMessage/$changes", {
            _count: 1000,
            version: store.value.chatVersion,
            ".chat.id": encounter.chat.id,
          });
        } catch (err) {
          /* aidbox.sendErrorLogs({ data: err }); */
        }
        const msgs = data.data?.changes
          ?.filter(({ event }) => event === "created")
          .map(({ resource }) => {
            const userId =
              resource.from.id ||
              `${resource.from.identifier.system}/${resource.from.identifier.value}`;
            return {
              id: resource.id,
              text: resource.text,
              createdAt: moment(resource.meta.createdAt).format("h:mm A"),
              user: {
                id: userId,
                avatar:
                  userId === "chat-bot"
                    ? href(
                        "static",
                        "physician-app",
                        "static",
                        "images",
                        "bot-avatar.png"
                      )
                    : "https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Favatar.png?alt=media&token=bdd40e1f-0eb5-4aa9-9d8f-07f6a093af6b",
              },
            };
          });
        if (msgs) {
          const msgIds = new Set(
            store.value.chatMessages.map((message) => message.id)
          );
          store.transform((state) => {
            state.chatVersion = data.data.version;
            state.chatMessages = [
              ...store.value.chatMessages,
              ...msgs.filter((msg) => !msgIds.has(msg.id)),
            ];
          });
        }
      }
    },
    handleAssessment: (e, custom = false, customName = undefined) => {
      if (custom && customName) {
        store.transform((state) => {
          state.assessment[customName] = e;
        });
      } else {
        const { name, value } = e.target;
        store.transform((state) => {
          state.assessment[name] = value === "" ? null : value;
        });
      }
    },
    handleSpeechSummary: (value, main = true) => {
      store.transform((state) => {
        if (main) {
          state.assessment.physicianSummary = `${state.assessment.physicianSummary} ${value}`;
        } else {
          state.assessment.physicianSummary += value;
        }
      });
    },
    onPhysicianSummaryChanged: (value) => {
      store.transform((state) => {
        state.assessment.physicianSummary = value;
      });
    },
    handlePressEnterAssessment: (e) => {
      if (e.keyCode === 13) {
        e.target.value = "";
      }
    },
    saveAssessment: async () => {
      store.transform((s) => {
        s.isSaving = true;
      });
      try {
        console.log(store.value);
        let req;
        if (store.value?.assessment?.id) {
          req = await aidbox.request({
            url: `/PatientAssessment/${store.value.assessment.id}`,
            method: "PATCH",
            data: store.value.assessment,
          });
        } else {
          req = await aidbox.request({
            url: `/PatientAssessment`,
            method: "PUT",
            data: {
              ...store.value.assessment,
              patient: {
                id: store.value.data.subject.id,
                resourceType: "Patient",
              },
              encounter: {
                id: store.value.data.id,
                resourceType: "Encounter",
              },
            },
          });
        }

        console.log(req);
        if (req.status === 200 || req.status == 201) {
          pageController.openSnackBar("Assessment saved.");
          fetchData();
        } else {
          throw new Error();
        }
      } catch (err) {
        console.log(err);
        pageController.openSnackBar(
          "An error occurred. Wait a few seconds and try again. If it continues, contact SupDoc support."
        );
      } finally {
        store.transform((s) => {
          s.isSaving = false;
        });
      }
    },
    handleBillingCode: (code) => {
      store.transform((state) => {
        state.billingCodes[code.id] = code.description;
        state.assessment.billingCodes
          ? state.assessment.billingCodes.push({
              id: code.id,
              resourceType: "BillingCode",
            })
          : (state.assessment.billingCodes = [
              {
                id: code.id,
                resourceType: "BillingCode",
              },
            ]);
      });
      pageController.saveAssessment();
    },
    handleDeleteBillingCode: (codeId) => {
      store.transform((state) => {
        const { billingCodes, assessment } = state;
        state.billingCodes = omit(billingCodes, codeId);
        state.assessment.billingCodes = assessment.billingCodes.filter(
          (code) => code.id !== codeId
        );
      });
      pageController.saveAssessment();
    },
    // Speech API
    startRecognizing: () => {
      initRecognizer();
      store.value.physicianSummarySpeechInput.recognizer.start();
    },
    stopRecognizing: () => {
      store.value.physicianSummarySpeechInput.recognizer.stop();
    },
    printVisit: () => {
      window.print();
    },
  };

  function initRecognizer() {
    if (Object.hasOwnProperty.call(window, "webkitSpeechRecognition")) {
      store.transform((state) => {
        if (!state.physicianSummarySpeechInput.recognizer) {
          // eslint-disable-next-line
          const recognizer = new window.webkitSpeechRecognition();
          state.physicianSummarySpeechInput.recognizer = recognizer;
          recognizer.continuous = true;
          recognizer.interimResults = true;
          recognizer.lang = "en-US";
          recognizer.onstart = function () {
            console.log("onstart");
            // eslint-disable-next-line
            store.transform((state) => {
              state.physicianSummarySpeechInput.isRecognizing = true;
            });
          };
          recognizer.onerror = function (event) {
            console.log("error:", event.error);
          };
          recognizer.onend = function () {
            console.log("The end of recognizing.");
            // eslint-disable-next-line
            store.transform((state) => {
              state.physicianSummarySpeechInput.isRecognizing = false;
            });
          };
          recognizer.onresult = function (event) {
            console.log("onresult");
            console.log(event);
            let final = "";
            let process = "";
            // eslint-disable-next-line
            for (let i = event.resultIndex; i < event.results.length; ++i) {
              if (event.results[i].isFinal) {
                final += event.results[i][0].transcript;
              } else {
                process += event.results[i][0].transcript;
              }
            }
            console.log(final, " | ", process);
            // eslint-disable-next-line
            store.transform((state) => {
              console.log(
                `'${state.assessment.physicianSummary.trim()} ${final.trim()}'`
              );
              if (final) {
                state.assessment.physicianSummary = `${
                  state.assessment.physicianSummary
                } ${final.trim()}`;
              }
              state.physicianSummarySpeechInput.almostRecognizedText = process;
            });
          };
        }
      });
    }
  }

  const fetchData = React.useCallback(async () => {
    try {
      console.log(">>>", store.value.setting.url);
      const res = await aidbox.postPlain(
        store.value.setting.url,
        store.value.setting.data
      );

      const data = res.data ? res.data.data : res.data;
      const isVideo =
        getIn(data, ["encounter", "serviceType", "coding", 0, "code"]) ===
        "259";

      const isPhone =
        getIn(data, ["encounter", "type", 0, "coding", 0, "code"]) === "phone";
      const reviewStatus = getIn(data, ["encounter", "review", "status"]);
      store.transform((state) => {
        state.isLoading = false;
        state.isPhone = isPhone;
        state.reviewStatus = reviewStatus;
        state.data = data.encounter;
        state.participantId = getIn(data.encounter, [
          "chat",
          "resource",
          "participants",
          (p) => p?.person?.id === getIn(user, ["link", 0, "link", "id"]),
          0,
          "id",
        ]);
        state.mediaResources = getIn(data.encounter, ["media"], []).map(
          (item) => {
            return { original: item.content?.url };
          }
        );

        state.video.id = getIn(data.encounter, ["videocall", 0, "id"], null);
        state.videoCallDescription = getIn(
          data.encounter,
          ["videocall", 0, "description"],
          null
        );
        state.videoCallStartTime = getIn(
          data.encounter,
          ["videocall", 0, "start"],
          null
        );
        console.log("Fetching", state.videoCallStartTime);
        if (data.encounter?.assessment[0]) {
          state.assessment = getIn(data, ["encounter", "assessment", 0], {});
          state.assessment.billingCodes = (
            state.assessment.billingCodes || []
          ).map((code) => {
            state.billingCodes[code.resource.id] = code.resource.description;
            return {
              id: code.resource.id,
              resourceType: "BillingCode",
            };
          });
        } else if (isVideo) {
          state.assessment = {};
        }
      });
    } catch (err) {
      console.log("!!", err.message);
      store.transform((state) => {
        state.isLoading = false;
        state.error = err.response.data;
      });
    }
  });

  useEffect(() => {
    fetchData();
  }, [props.match.params.id]);
  return (
    <ControllerLayer value={pageController}>
      <StoreLayer store={store}>
        <VisitProcess />
      </StoreLayer>{" "}
    </ControllerLayer>
  );
}

export default VisitLayer;
