export const BOOL_REPLIES = {
  type: "radio", // or 'checkbox',
  keepIt: false,
  values: [
    {
      title: "Yes",
      value: "yes",
    },
    {
      title: "No",
      value: "no",
    },
  ],
};

export const STI_SYMPTOMS = {
  type: "checkbox",
  values: [
    {
      title: "Fever/Chills",
      value: "fever-chills",
    },
    {
      title: "Lower abdominal pain",
      value: "lower-abdominal-pain",
    },
    {
      title: "Pelvic pain",
      value: "pelvic-pain",
    },
    {
      title: "Genital discharge",
      value: "genital-discharge",
    },
    {
      title: "Bleeding",
      value: "bleeding",
    },
    {
      title: "Genital sores/lesions",
      value: "genital-sores-lesions",
    },
  ],
};

export const PAIN_RATING = {
  type: "radio",
  values: [
    {
      title: "1",
      value: "1",
    },
    {
      title: "2",
      value: "2",
    },
    {
      title: "3",
      value: "3",
    },
    {
      title: "4",
      value: "4",
    },
    {
      title: "5",
      value: "5",
    },
    {
      title: "6",
      value: "6",
    },
    {
      title: "7",
      value: "7",
    },
    {
      title: "8",
      value: "8",
    },
    {
      title: "9",
      value: "9",
    },
    {
      title: "10",
      value: "10",
    },
  ],
};

export const EXAMPLES = {
  type: "radio",
  keepIt: false,
  values: [
    {
      title: "Fever",
      value: "fever",
    },
    {
      title: "Pain",
      value: "pain",
    },
    {
      title: "Cough",
      value: "cough",
    },
    {
      title: "Rash",
      value: "rash",
    },
    {
      title: "Nausea",
      value: "nausea",
    },
    {
      title: "Trouble Sleeping",
      value: "trouble-sleeping",
    },
    {
      title: "Decreased Energy",
      value: "decreased-energy",
    },
    {
      title: "Urinary Issues",
      value: "urinary-issues",
    },
    {
      title: "Bowel Issues",
      value: "bowel-issues",
    },
    {
      title: "Allergies",
      value: "allergies",
    },
    {
      title: "UTI",
      value: "uti",
    },
    {
      title: "Yeast Infection",
      value: "yeast-infection",
    },
    {
      title: "Heartburn",
      value: "heartburn",
    },
    {
      title: "Pinkeye",
      value: "pink-eye",
    },
    {
      title: "Animal Bite",
      value: "animal-bite",
    },
    {
      title: "Sinus Infection",
      value: "sinus-infection",
    },
    {
      title: "Medication Refill",
      value: "medication-refill",
    },
    {
      title: "Hair Loss",
      value: "hair-loss",
    },
    {
      title: "Erectile Dysfunction",
      value: "erectile-dysfunction",
    },
    {
      title: "Birth Control",
      value: "birth-control",
    },
  ],
};

export const END_REPLIES = {
  type: "radio", // or 'checkbox',
  keepIt: false,
  values: [
    {
      title: "Continue",
      value: "continue",
    },
    {
      title: "Start Over",
      value: "start-over",
    },
  ],
};

export const ADDITIONAL_SYMPTOMS = {
  type: "checkbox",
  keepIt: false,
  values: [
    {
      title: "Chills",
      value: "chills",
    },
    {
      title: "Sore Throat",
      value: "sore-throat",
    },
  ],
};

export const DECREASED_ENERGY_SYMPTOMS = {
  type: "checkbox",
  keepIt: false,
  values: [
    {
      title: "Snoring",
      value: "snoring",
    },
    {
      title: "Jumpy / Restless Legs",
      value: "jumpy-restless-legs",
    },
    {
      title: "Nightmares / Night Terrors",
      value: "nightmares-night-terrors",
    },
    {
      title: "Gaining Weight",
      value: "gaining-weight",
    },
    {
      title: "Losing Weight",
      value: "losing-weight",
    },
    {
      title: "Thirstier than Usual",
      value: "thirstier-than-usual",
    },
    {
      title: "More Frequent Urination",
      value: "more-frequent-urination",
    },
    {
      title: "Constipation",
      value: "constipation",
    },
    {
      title: "Diarrhea",
      value: "diarrhea",
    },
    {
      title: "Fever",
      value: "fever",
    },
    {
      title: "Chills",
      value: "chills",
    },
    {
      title: "Rash",
      value: "rash",
    },
    {
      title: "None",
      value: "none",
    },
  ],
};

export const TEMPS = {
  type: "radio", // or 'checkbox',
  keepIt: false,
  values: [
    {
      title: "97-98.6 (normal)",
      value: "normal",
    },
    {
      title: "98-99",
      value: "98-99",
    },
    {
      title: "99-100",
      value: "99-100",
    },
    {
      title: "100-101",
      value: "100-101",
    },
    {
      title: "101-102",
      value: "101-102",
    },
    {
      title: "Over 102",
      value: "over-102",
    },
  ],
};
