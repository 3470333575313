import React from "react";
import PropTypes from "prop-types";
import {
  getUserInfo,
  getResource,
  readResource,
  getPlain,
} from "../utils/aidbox";
import { getIn } from "../utils/utils";
import Permissions from "../constants/Permissions";

export const AuthContext = React.createContext(undefined, undefined);

export function AuthContextProvider({ children }) {
  const [state, setState] = React.useState(null);
  const [practitioner, setPractitioner] = React.useState(null);
  const [userRelationMaps, setUserRelationMaps] = React.useState([]);
  const userCan = React.useCallback((permission) => {
    return state?.data?.userPermissions?.includes(permission);
  });
  const permissions = Permissions;

  if (state === null) {
    getUserInfo()
      .then(async (resp) => {
        let pract;

        const userId = getIn(resp, ["data", "id"]);
        const roleName = getIn(resp, ["data", "roleName"]);
        if (roleName == "practitioner") {
          pract = await getResource("Practitioner", userId);
          const practRole = getIn(
            await getPlain(`/PractitionerRole?.practitioner.id=${userId}`),
            ["data", "entry", 0, "resource"],
            undefined
          );
          if (practRole) {
            const practiceLocations = practRole.location.map((l) => l.id);
            pract.data.practiceLocations = practiceLocations;
            pract.data.practitionerRole = practRole;
          }

          setPractitioner(pract.data);
        }
        const mapsResp = await readResource("UserRelationMap", {
          user: userId,
        });
        const maps = getIn(
          mapsResp,
          ["data", "entry", "0", "resource", "relations"],
          []
        );
        setUserRelationMaps(maps);
        setState(resp.data);
      })
      .catch(() => setState({ test: true }));
  }
  const value = {
    user: state,
    practitioner,
    userCan,
    setUser: setState,
    userRelationMaps,
    permissions,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  return React.useContext(AuthContext);
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
