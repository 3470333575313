import React from "react";
import * as ui from "@material-ui/core";
import { getIn } from "../../utils/utils";
import { useController } from "../../Controller";
import { useStore } from "../../Store";

const useStyles = ui.makeStyles({
  main: {
    padding: "20px",
  },
});

export default function Settings() {
  const styles = useStyles();
  const ctrl = useController();
  const [store] = useStore();

  return (
    <ui.Paper className={styles.main}>
      <form noValidate autoComplete="off">
        <ui.FormControl>
          <ui.FormLabel>Notification preferences</ui.FormLabel>
          <ui.FormControlLabel
            control={
              <ui.Checkbox
                checked={getIn(store, ["notifications", "email"], false)}
                onChange={(e) =>
                  ctrl.changePreferences({ email: e.target.checked })
                }
                name="email-preference"
              />
            }
            label={`Email (${store.email || "—"})`}
          />
          <ui.FormControlLabel
            control={
              <ui.Checkbox
                checked={getIn(store, ["notifications", "sms"], false)}
                onChange={(e) =>
                  ctrl.changePreferences({ sms: e.target.checked })
                }
                name="sms-preference"
              />
            }
            label={`SMS (${store.phone || "—"})`}
          />
        </ui.FormControl>
      </form>
    </ui.Paper>
  );
}
