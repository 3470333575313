import React, { createContext, useContext } from "react";

const ControllerLayerContext = createContext(null);

export function ControllerLayer({ value, children }) {
  const parentInjector = useController();
  const injector = Object.assign(Object.create(parentInjector), value);
  return (
    <ControllerLayerContext.Provider value={injector}>
      {children}
    </ControllerLayerContext.Provider>
  );
}

export function useController() {
  return useContext(ControllerLayerContext) || null;
}
