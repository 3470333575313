import React, { useEffect } from "react";
import HsChat from "./HsChat/HsChat";
import { useController } from "../Controller";
import { useStore } from "../Store";

function Chat({ chatId, participantId, showToolbar }) {
  const ctrl = useController();
  const [state] = useStore();
  useEffect(() => {
    ctrl.clearMessages();
    ctrl.fetchMessages();
    const id = setInterval(ctrl.fetchMessages, 5000);
    return () => {
      console.log("turn off fetching for", chatId);
      clearInterval(id);
    };
  }, []);

  return (
    <HsChat
      user={{
        id: participantId,
        resourceType: "ChatParticipant",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Favatar.png?alt=media&token=bdd40e1f-0eb5-4aa9-9d8f-07f6a093af6b",
      }}
      messages={state.chatMessages}
      onSend={(msgs) => ctrl.onSend(msgs)}
      showToolbar={showToolbar}
    />
  );
}
export default Chat;
