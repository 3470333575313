import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { getIn } from "../utils/utils";
import aidbox, { postPlain, getPlain } from "../utils/aidbox";
import USStates from "../constants/USStates";

export default function PatientSearchForm({
  clsx,
  Boolean,
  classes,
  dispatch,
  setAlertBar,
  setAlertMessage,
  patientData,
}) {
  const [resultsFound, setResultsFound] = useState([]);
  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required."),
    birthDate: yup
      .date("This must be a valid date")
      .required("Birthdate is required."),
  });

  const formik = useFormik({
    initialValues: {
      firstName: patientData.values.firstName || "",
      lastName: patientData.values.lastName || "",
      birthDate: patientData.values.birthDate || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const submissionValues = { ...values };
      submissionValues.birthDate = moment(
        values.birthDate,
        "YYYY-MM-DD"
      ).format("MM/DD/YYYY");
      submissionValues.state = values.stateInCurrently;
      try {
        const resp = await aidbox.http().get("/Patient", {
          params: {
            _count: 1000,
            ".name.0.given.0$contains": submissionValues.firstName,
            ".name.0.family$contains": submissionValues.lastName,
            ".birthDate": values.birthDate,
          },
        });

        if (!getIn(resp, ["data"], false)) throw new Error();

        if (resp.status === 200) {
          const results = getIn(resp, ["data", "entry"]);
          if (results.length > 0) {
            setResultsFound(results);
          } else {
            setResultsFound([]);
            setAlertBar(true);
            setAlertMessage(
              "No patient record found. Check data and spellling."
            );
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        setAlertBar(true);
        setAlertMessage(e.toString());
      }
    },
  });

  const useRecord = async (record) => {
    try {
      const additionalData = getIn(
        await getPlain(`/npc/$patient-data?patientId=${record.id}`, {
          patientId: record.id,
        }),
        ["data", "patientData"]
      );

      if (!additionalData) {
        throw new Error("There was an error.");
      }

      const newRecord = { ...patientData, ...record, ...additionalData };

      dispatch({
        type: "updatePatientData",
        data: newRecord,
      });
      dispatch({
        type: "nextStep",
      });
    } catch (e) {
      return;
    }
  };

  return (
    <>
      <Typography style={{ marginBottom: 10 }} variant="h6">
        Find Existing Patient Record
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <div className={clsx(classes.formWrapperCentered)}>
          <Grid
            container
            item
            spacing={3}
            columns={{
              xs: 1,
              sm: 1,
              md: 1,
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="firstName"
                id="firstName"
                label="First Name"
                variant="outlined"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="lastName"
                id="lastName"
                label="Last Name"
                variant="outlined"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                fullWidth
                variant="outlined"
                id="birthDate"
                label="Birth Date"
                type="date"
                name="birthDate"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.birthDate}
                onChange={formik.handleChange}
                error={
                  formik.touched.birthDate && Boolean(formik.errors.birthDate)
                }
                helperText={formik.touched.birthDate && formik.errors.birthDate}
                onBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
      <div>
        {resultsFound.length > 0 && (
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Birthdate</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultsFound.map((record) => {
                const values = {
                  firstName: getIn(record, [
                    "resource",
                    "name",
                    "0",
                    "given",
                    "0",
                  ]),
                  lastName: getIn(record, ["resource", "name", "0", "family"]),
                  birthDate: getIn(record, ["resource", "birthDate"]),
                  id: getIn(record, ["resource", "id"]),
                  phone: getIn(record, [
                    "resource",
                    "telecom",
                    ({ system }) => system === "phone",
                    0,
                    "value",
                  ]),
                };
                const { firstName, lastName, birthDate, id, phone } = values;
                return (
                  <TableRow key={id}>
                    <TableCell>{firstName}</TableCell>
                    <TableCell>{lastName}</TableCell>
                    <TableCell>{birthDate}</TableCell>
                    <TableCell>{phone}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => useRecord(values)}
                        color="primary"
                        variant="contained"
                        type="button"
                      >
                        Use Record
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    </>
  );
}
