import React, { useEffect, useReducer, useState } from "react";
import { makeStyles, Snackbar } from "@material-ui/core";
import clsx from "clsx";
import { helpers } from "../styles/index";
import * as vars from "../styles/vars";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import EligibilityChecker from "../components/EligibilityChecker";
import PatientSearchForm from "../components/PatientSearchForm";
import PatientTypeSelector from "../components/PatientTypeSelector";
import ChatBotIntake from "../components/ChatBotIntake";
import { v4 as uuid } from "uuid";
import PatientHistory from "../components/PatientHistory";
import VisitComplete from "../components/VisitComplete";
import NewPatientStep from "../components/NewPatientStep";
import InformedConsent from "../components/InformedConsent";

function init() {
  const initialState = {
    flow: "start",
    flowStep: 0,
    patientData: {
      id: "",
      firstName: "",
      lastName: "",
      birthDate: "",
      stateInCurrently: "",
      activationCodeText: "",
      gender: "",
      phone: "",
      email: "",
      allergyintolerances_as_patient: [],
      conditions_as_subject: [],
      familymemberhistorys_as_patient: [],
      medicationstatements_as_subject: [],
      preferences: {
        pharmacy: {},
      },
      createVisit: false,
      sendEmail: false,
    },
    encounterData: {
      id: uuid(),
    },
  };

  return initialState;
}

function reducer(prevState, action) {
  switch (action.type) {
    case "nextStep":
      return { ...prevState, flowStep: prevState.flowStep + 1 };
    case "updatePatientData":
      return { ...prevState, patientData: action.data };
    case "updateEncounterData":
      return { ...prevState, encounterData: action.data };
    case "startFlow":
      return {
        ...prevState,
        flow: action.data,
        flowStep: prevState.flowStep + 1,
      };
    case "reset":
      return init();
    default:
      throw new Error();
  }
}

const flows = {
  start: [PatientTypeSelector],
  newPatient: [
    PatientTypeSelector,
    InformedConsent,
    EligibilityChecker,
    NewPatientStep,
    ChatBotIntake,
    PatientHistory,
    VisitComplete,
  ],
  existingPatient: [
    PatientTypeSelector,
    InformedConsent,
    EligibilityChecker,
    PatientSearchForm,
    ChatBotIntake,
    PatientHistory,
    VisitComplete,
  ],
};

function NewPhoneVisitPage({ reset }) {
  const [alertBar, setAlertBar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [state, dispatch] = useReducer(reducer, init(), init);

  const StepComponent = flows[state.flow][state.flowStep];

  useEffect(() => {
    console.log(">>>>>>>>>>>>State", state);
  }, [state]);

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "min-content",
    },
    patientList: {
      display: "grid",
      gridRowGap: "1rem",
    },
    patient: {
      display: "grid",
      gridTemplateColumns: "1fr max-content",
      gridGap: "1rem",
      alignItems: "center",
      borderBottom: `2px solid ${vars.brown500}`,
      padding: ".5rem 0",
    },
    text: {
      textTransform: "capitalize",
      fontWeight: "bold",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    formRow: {
      padding: theme.spacing(1),
    },
    formWrapperCentered: {
      maxWidth: "600px",
    },
  }));
  const classes = useStyles();
  const utils = helpers();

  return (
    <ErrorBoundary>
      <div className={clsx(utils.card, classes.root)}>
        <StepComponent
          clsx={clsx}
          Boolean={Boolean}
          classes={classes}
          dispatch={dispatch}
          setAlertBar={setAlertBar}
          setAlertMessage={setAlertMessage}
          patientData={state.patientData}
          encounterData={state.encounterData}
          reset={reset}
        />
        <Snackbar
          message={alertMessage}
          open={alertBar}
          autoHideDuration={5000}
          onClose={() => {
            setAlertMessage("");
            setAlertBar(false);
          }}
        ></Snackbar>
      </div>
    </ErrorBoundary>
  );
}
export default NewPhoneVisitPage;
