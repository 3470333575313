import React from "react";
import PropTypes from "prop-types";

const Title = (props) => {
  const { title, style } = props;
  return (
    <h3 style={style} className="visitTableTitle">
      {title}
    </h3>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
