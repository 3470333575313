import moment from "moment";
import { v4 as uuid } from "uuid";
const MEDICATION_ACTIVE = ["active", "intended"];
const FAMILY_HISTORY_ACTIVE = ["partial", "completed"];

class Patient {
  constructor(id) {
    this.id = id;
  }

  id = this.id ? this.id : uuid();
  name = [
    {
      given: [""],
      family: "",
    },
  ];
  resourceType = "Patient";
  preferences = {
    pharmacy: {
      id: "",
    },
    notifications: {
      sms: true,
      email: true,
    },
  };
  telecom = [
    {
      value: "",
      system: "phone",
    },
    {
      value: "",
      system: "email",
    },
  ];
  gender = "";

  setPatientName(firstName, lastName) {
    this.name[0].given[0] = firstName;
    this.name[0].family = lastName;
  }

  setPharmacy(pharmacyId) {
    this.pharmacy.id = pharmacyId;
  }
}
class MedicationStatement {
  id = uuid();
  status = "active";
  context;
  subject = { id: undefined, resourceType: "Patient" };
  identifier = [{ value: undefined, system: "dosespot:medication" }];
  medication = {
    CodeableConcept: {
      text: undefined,
      coding: [],
    },
  };

  getStatus() {
    return MEDICATION_ACTIVE.includes(resource.status);
  }

  setStatus(active) {
    this.status = active ? "active" : "completed";
  }

  getDisplayName() {
    return this.medication.CodeableConcept.text;
  }

  setDisplayName(label) {
    this.medication.CodeableConcept.text = label;
  }

  setPatientId(patientId) {
    this.subject.id = patientId;
  }
}

class Condition {
  id = uuid();
  code = {
    text: undefined,
    coding: [
      {
        code: undefined,
        display: undefined,
        id: undefined,
        system: undefined,
        userSelected: true,
      },
    ],
  };
  subject = { id: undefined, resourceType: "Patient" };
  category;
  encounter;
  clinicalStatus = { text: "active" };

  getStatus() {
    if (this.clinicalStatus.coding?.length > 0) {
      return resource.clinicalStatus.coding[0].code == "active";
    } else {
      return this.clinicalStatus.text == "active";
    }
  }

  setStatus(active) {
    let value = active ? "active" : "inactive";
    if (this.clinicalStatus.coding) {
      this.clinicalStatus.coding = [{ code: value, display: value }];
    } else {
      this.clinicalStatus = { text: value };
    }
  }

  getDisplayName() {
    return this.code?.text;
  }

  setDisplayName(label) {
    this.code.text = label;
    this.code.coding[0].display = label;
  }

  setPatientId(patientId) {
    this.subject.id = patientId;
  }
}

class FamilyMemberHistory {
  id = uuid();
  condition = [
    {
      code: {
        coding: [{}],
      },
    },
  ];
  patient = { id: undefined, resourceType: "Patient" };
  relationship = {
    coding: [
      {
        code: "FAMMEMB",
        display: "family member",
        system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
      },
    ],
  };
  status = "partial";

  getStatus() {
    return FAMILY_HISTORY_ACTIVE.includes(this.status);
  }

  setStatus(active) {
    this.status = active ? "partial" : "entered-in-error";
  }

  getDisplayName() {
    return this.condition[0].code.coding[0].display;
  }

  setDisplayName(label) {
    if (this.condition[0]) {
      this.condition[0].code.coding[0].display = label;
    }
  }

  setPatientId(patientId) {
    this.patient.id = patientId;
  }
}

class AllergyIntolerance {
  id = uuid();
  patient = { id: undefined, resourceType: "Patient" };
  onset = { dateTime: undefined, string: undefined };
  category = ["medication"];
  criticality = "unable-to-assess";
  clinicalStatus = { coding: [{ code: "active", display: "active" }] };
  encounter;
  type = "allergy";
  note;
  code = { text: undefined, coding: [] };
  identifier = [{ value: undefined, system: "dosespot:allergy" }];
  verificationStatus = {
    coding: [
      {
        code: "unconfirmed",
        display: "unconfirmed",
      },
    ],
  };

  getStatus() {
    if (this.clinicalStatus.coding?.length > 0) {
      return resource.clinicalStatus.coding[0].code == "active";
    } else {
      return this.clinicalStatus.text == "active";
    }
  }

  setStatus(active) {
    let value = active ? "active" : "inactive";
    if (this.clinicalStatus.coding) {
      this.clinicalStatus.coding = [{ code: value, display: value }];
    } else {
      this.clinicalStatus = { text: value };
    }
  }

  getDisplayName() {
    return this.code.text;
  }

  setDisplayName(label) {
    this.code.text = label;
  }

  setPatientId(patientId) {
    this.patient.id = patientId;
  }
}

class Encounter {
  class = {
    code: "VR",
    system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
    display: "virtual",
  };
  status = "arrived";
  paymentStatus = "paid";
  subject = { id: undefined, resourceType: "Patient" };
  participant = [];
  reasonCode = [
    {
      text: undefined,
    },
  ];
  period = {
    start: moment(Date()).toISOString(),
  };
  chatMessages = [];
  type = [{ text: undefined }];
  location = undefined;

  setPatientId(patientId) {
    this.subject.id = patientId;
    return this;
  }

  setChiefComplaint(reasonText) {
    this.reasonCode[0].text = reasonText;
    return this;
  }

  setType(type) {
    this.type[0] = type;
    return this;
  }

  setLocation(locationId) {
    this.location = [
      {
        location: {
          id: locationId,
          resourceType: "Location",
        },
      },
    ];
  }
}

const getResourceDisplayValue = (resource) => {
  let ret = "Unknown Value";
  if (resource.code) {
    // Get Display value for allergies and diagnoses
    ret = resource.code.text;
  } else if (resource.medication) {
    // Get Display value for patient medications
    ret = resource.medication.CodeableConcept.text;
  } else if (resource.condition && resource.condition[0]) {
    // Get Display value for family history
    ret = resource.condition[0].code?.coding[0].display;
  }
  return ret ?? "Unknown Value";
};

const setResourceStatus = (resource, active) => {
  resource = { ...resource };
  if (resource.code) {
    // Toggle status for allergies and diagnoses
    let value = active ? "active" : "inactive";
    if (resource.clinicalStatus.coding) {
      resource.clinicalStatus.coding = [{ code: value, display: value }];
    } else {
      resource.clinicalStatus = active ? { text: value } : { text: value };
    }
  } else if (resource.medication) {
    // Toggle status for medication
    resource.status = active ? "active" : "completed";
  } else if (resource.condition && resource.condition[0]) {
    // Toggle status for family history
    resource.status = active ? "partial" : "entered-in-error";
  }
  return resource;
};

const getResourceStatus = (resource) => {
  let value = false;
  const medicationValid = ["active", "intended"];
  if (resource.code) {
    // Get status for allergies and diagnoses
    if (resource.clinicalStatus.coding?.length > 0) {
      value = resource.clinicalStatus.coding[0].code == "active";
    } else {
      value = resource.clinicalStatus.text == "active";
    }
  } else if (resource.medication) {
    // Get status for medication
    value = medicationValid.includes(resource.status);
  } else if (resource.condition && resource.condition[0]) {
    // Get status for family history
    value = resource.status == "partial";
  }
  return value;
};

export {
  MedicationStatement,
  Condition,
  FamilyMemberHistory,
  AllergyIntolerance,
  Encounter,
  getResourceStatus,
  setResourceStatus,
  getResourceDisplayValue,
};
