import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Button } from "@material-ui/core";
import MicOffIcon from "@material-ui/icons/MicOff";
import MicIcon from "@material-ui/icons/Mic";

const Dictaphone = ({ transcriptHandler, previewHandler, shouldListen }) => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const recognition = SpeechRecognition.getRecognition();
  const [listening, setListening] = useState(false);

  const stopListening = async () => {
    setListening(false);
    transcriptHandler(transcript);
    SpeechRecognition.stopListening();
  };

  const startListening = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
    (async () => SpeechRecognition.startListening({ continuous: true }))();
    setListening(true);
  };

  useEffect(() => {
    if (previewHandler) {
      previewHandler(listening ? transcript : "");
    }
  }, [transcript, listening]);

  useEffect(() => {
    if (shouldListen) {
      startListening();
    } else {
      stopListening();
    }
  }, [shouldListen]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  return (
    <>
      <div>
        <Button
          disableRipple
          onFocus={() => startListening()}
          onBlur={() => stopListening()}
          style={{
            minWidth: 0,
            position: "absolute",
            zIndex: 50,
            top: 0,
            bottom: 0,
            left: 0,
          }}
        >
          {listening ? (
            <MicIcon style={{ color: "green", minWidth: "none" }}></MicIcon>
          ) : (
            <MicOffIcon></MicOffIcon>
          )}
        </Button>
      </div>
    </>
  );
};
export default Dictaphone;
