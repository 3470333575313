import React, { useEffect, useState } from "react";
import * as ui from "@material-ui/core";
import { v4 as uuid } from "uuid";
import MedicationAllergies from "./MedicationAllergies";
import Conditions from "./Conditions";
import Medications from "./Medications";
import PharmacySearch from "./PharmacySearch";
import {
  AllergyIntolerance,
  Condition,
  FamilyMemberHistory,
  MedicationStatement,
  Encounter,
  setResourceStatus,
} from "../utils/aidboxHelpers";
import { getIn } from "../utils/utils";
import FamilyMemberHistories from "./FamilyMemberHistories";
import * as aidbox from "../utils/aidbox";

export default function PatientHistory({
  patientData,
  encounterData,
  dispatch,
  setAlertMessage,
  setAlertBar,
}) {
  const [creatingEncounter, setCreatingEncounter] = useState(false);
  const [encounterComplete, setEncounterComplete] = useState(false);

  useEffect(() => {
    if (!encounterComplete) return;
    dispatch({
      type: "nextStep",
    });
  }, [encounterComplete]);

  return (
    <ui.Grid container spacing={2}>
      <ui.Grid item xs={6}>
        <MedicationAllergies
          patientAllergies={patientData.allergyintolerances_as_patient}
          subject={patientData}
          encounterID={encounterData.id}
          isClosed={false}
          onSave={(addBody, subject) => {
            const allergyIntolerance = new AllergyIntolerance();
            allergyIntolerance.id = uuid();
            allergyIntolerance.setPatientId(subject.id);
            allergyIntolerance.setDisplayName(addBody.Name);
            allergyIntolerance.code.coding = [
              {
                code: addBody.Code,
                system: "dosespot:allergycodetype:" + addBody.CodeType,
              },
            ];
            if (addBody.RxCUI) {
              allergyIntolerance.code.coding.push({
                code: addBody.RxCUI.toString(),
                system: "dosespot:rxcui",
              });
            }
            const newData = { ...subject };
            newData.allergyintolerances_as_patient.push(allergyIntolerance);
            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
          onDelete={(allergy) => {
            const newData = { ...patientData };
            newData.allergyintolerances_as_patient =
              newData.allergyintolerances_as_patient.map((existing) => {
                if (existing.id === allergy) {
                  return setResourceStatus(existing, false);
                }
                return existing;
              });
            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
        />
      </ui.Grid>
      <ui.Grid item xs={6}>
        {/* TODO: Add ability for user-defined conditions */}
        <Conditions
          title="Conditions"
          queryParams="&valueset=recurohealth-icd-10-subset-1"
          patientConditions={patientData.conditions_as_subject}
          onSave={(addBody, userDefined) => {
            const newCondition = new Condition();
            newCondition.setPatientId(patientData.id);
            newCondition.setDisplayName(addBody.code?.text || userDefined);
            const code = getIn(addBody, ["code", "coding", "0"]) || {};
            newCondition.code.coding = [
              {
                code: code.code ?? "UserDefined",
                system: code.system ?? "recuro:userdefined",
                display: code.display ?? userDefined,
                userSelected: true,
              },
            ];
            const newData = { ...patientData };
            newData.conditions_as_subject.push(newCondition);
            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
          onDelete={(conditionId) => {
            const newData = { ...patientData };
            newData.conditions_as_subject = newData.conditions_as_subject.map(
              (existing) => {
                if (existing.id === conditionId) {
                  return setResourceStatus(existing, false);
                }
                return existing;
              }
            );
            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
          patientID={patientData.id}
          encounterID={encounterData.id}
          isClosed={false}
        />
      </ui.Grid>
      <ui.Grid item xs={6}>
        {/* TODO: Add ability for user-defined conditions */}
        <FamilyMemberHistories
          title="Family History"
          patientFamilyHistories={patientData.familymemberhistorys_as_patient}
          onSave={(addBody, userDefined) => {
            const newHistory = new FamilyMemberHistory();
            newHistory.setPatientId(patientData.id);
            newHistory.setDisplayName(addBody.code?.text || userDefined);
            const code = getIn(addBody, ["code", "coding", "0"]) || {};
            newHistory.condition[0].code = {
              text: addBody.code?.text || userDefined,
              coding: [
                {
                  code: code.code ?? "UserDefined",
                  system: code.system ?? "recuro:userdefined",
                  display: code.display ?? userDefined,
                  userSelected: true,
                },
              ],
            };

            const newData = { ...patientData };
            newData.familymemberhistorys_as_patient.push(newHistory);
            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
          onDelete={(history, condition) => {
            const newData = { ...patientData };
            newData.familymemberhistorys_as_patient =
              newData.familymemberhistorys_as_patient.map((existing) => {
                if (history.id === existing.id) {
                  return setResourceStatus(existing, false);
                }
                return existing;
              });
            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
          patientID={patientData.id}
          encounterID={encounterData.id}
          isClosed={false}
        />
      </ui.Grid>
      <ui.Grid item xs={6}>
        <Medications
          patientMedications={patientData.medicationstatements_as_subject.map(
            (item) => item
          )}
          subject={patientData}
          encounterID={encounterData.id}
          patientAllergies={patientData.allergyintolerances_as_patient}
          isClosed={false}
          onSave={(addBody, subject) => {
            const newMedicationStatement = new MedicationStatement();
            newMedicationStatement.id = uuid();
            newMedicationStatement.setPatientId(subject.id);
            newMedicationStatement.setDisplayName(addBody.Name);
            newMedicationStatement.medication.CodeableConcept.coding = [
              { code: addBody.RxCUI?.toString(), system: "dosespot:rxcui" },
              {
                code: addBody.LexiGenDrugId?.toString(),
                system: "dosespot:lexigendrugid",
              },
              {
                code: addBody.LexiDrugSynId?.toString(),
                system: "dosespot:lexigensynid",
              },
            ];
            const newData = { ...subject };
            newData.medicationstatements_as_subject.push(
              newMedicationStatement
            );
            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
          onDelete={(medication) => {
            const newData = { ...patientData };
            newData.medicationstatements_as_subject =
              newData.medicationstatements_as_subject.map((existing) => {
                if (existing.id === medication) {
                  return setResourceStatus(existing, false);
                }
                return existing;
              });

            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
        />
      </ui.Grid>
      <ui.Grid item xs={6}>
        <PharmacySearch
          isClosed={false}
          onSave={(addBody) => {
            const newData = { ...patientData };
            newData.preferences.pharmacy = {
              id: addBody.PharmacyId.toString(),
            };
            dispatch({
              type: "updatePatientData",
              data: newData,
            });
          }}
          currentlyPreferredPharmacy={patientData.preferences.pharmacy}
        />
      </ui.Grid>
      <ui.Grid item xs={6}>
        <ui.Button
          disabled={creatingEncounter}
          color="primary"
          variant="contained"
          type="submit"
          onClick={async () => {
            if (
              !confirm(
                "Are you sure? The visit cannot be edited once it is saved."
              )
            ) {
              return;
            }
            const encounter = new Encounter();
            encounter
              .setChiefComplaint(encounterData.chiefComplaint)
              .setPatientId(patientData.id)
              .setType({
                coding: [
                  {
                    code: "phone",
                    system: "http://hl7.org/fhir/ValueSet/contact-point-system",
                    display: "Phone",
                  },
                ],
              })
              .setLocation(patientData.values.stateInCurrently);

            encounter.id = encounterData.id;
            encounter.chatMessages = [...encounterData.messages];
            const {
              allergyintolerances_as_patient,
              conditions_as_subject,
              familymemberhistorys_as_patient,
              medicationstatements_as_subject,
              preferences,
            } = patientData;

            const payload = {
              allergyintolerances_as_patient,
              conditions_as_subject,
              familymemberhistorys_as_patient,
              medicationstatements_as_subject,
              preferences,
              encounter,
            };

            setCreatingEncounter(true);
            try {
              if (!preferences?.pharmacy?.id)
                throw new Error("Pharmacy is requried");
              const resp = await aidbox.postPlain(
                "/npc/$submit-patient-intake",
                payload
              );
              if (!resp.status === 200) {
                throw new Error("Something went wrong.");
              }

              setEncounterComplete(true);
            } catch (e) {
              setAlertMessage(e.toString());
              setAlertBar(true);
            }
            setCreatingEncounter(false);
          }}
        >
          Finish Visit
        </ui.Button>
      </ui.Grid>
    </ui.Grid>
  );
}
