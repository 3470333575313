export function getParam(parameterName) {
  let result = null;
  let tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach((item) => {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

function getParams() {
  return window.location.search
    .substr(1)
    .split("&")
    .reduce((params, param) => {
      const result = { ...params };
      const [k, v] = param.split("=");
      result[k] = decodeURIComponent(v);
      return result;
    }, {});
}

const setQueryString = (params) => {
  const s = [];
  Object.entries(params).forEach((k, v) => {
    if (v !== null) {
      s.push(`${k}=${v}`);
    }
  });
  return s.join("&");
};

function setParams(p) {
  setTimeout(() => {
    window.location.search = setQueryString(p);
  }, 0);
}

function mergeParams(newParams) {
  const p = getParams();
  for (const [k, v] of Object.entries(newParams)) {
    if (v === null) {
      delete p[k];
    } else {
      p[k] = v;
    }
  }

  setParams(p);
}

export { getParams, setParams, mergeParams };
