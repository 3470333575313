import React from "react";
import PropTypes from "prop-types";

function Title({ title, classes }) {
  return (
    <div className={classes.chatTitle}>
      <span>{title}</span>
    </div>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default Title;
