import React from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { grey, indigo } from "@material-ui/core/colors";
import useLocalStorage from "@rehooks/local-storage";
import { useAuthContext } from "../components/AuthContext";
import * as vars from "../styles/vars";
import logo from "../assets/logo.png";
import { logout } from "../utils/aidbox";
import { getIn, href } from "../utils/utils";
import RefreshIcon from "@material-ui/icons/Refresh";

function MainLayout({ routes, children }) {
  const drawerWidth = 240;
  const theme = useTheme();
  const history = useHistory();

  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
    },
    activeRoute: {
      backgroundColor: grey[300],
    },
    toolbarRightStack: {
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
      color: "white",
    },
    practAvatar: {
      height: "50px",
      borderRadius: "50%",
      marginLeft: ".5rem",
    },
    appBar: {
      background: [
        "linear-gradient(9deg, rgba(166,218,245,1) 0%, rgba(106,161,216,1) 13%, rgba(80,91,167,1) 64%, rgba(182,72,204,1) 100%)",
      ],
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("md")]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(9) + 1,
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.down("md")]: {
        width: 0,
      },
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
  }));
  const { user, setUser } = useAuthContext();
  const classes = useStyles();
  const [open, setOpen] = useLocalStorage("menuDrawerOpen", false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleError = (e) => {
    e.target.onerror = null;
    const src = `https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/static%2Favatar.png?alt=media&token=bdd40e1f-0eb5-4aa9-9d8f-07f6a093af6b`;
    setUser({ ...user, avatar: src });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img
            className="logo"
            src={logo}
            alt="supdoc logo"
            style={{ maxWidth: "100%", height: "37px" }}
          />

          <Typography
            variant="h6"
            noWrap
            style={{ flexGrow: 1, marginLeft: ".5rem" }}
          ></Typography>
          <div className={classes.toolbarRightStack}>
            Logged in as {getIn(user, ["name", "0", "family"], user.id)}
            &nbsp;
            {}
            <img
              alt="user avatar"
              className={classes.practAvatar}
              src={
                !user.avatar
                  ? !window.AIDBOX_URL
                    ? href(
                        "static",
                        "physician-app",
                        "static",
                        "images",
                        `${user.id}.jpg`
                      )
                    : `${window.AIDBOX_URL}${href(
                        "static",
                        "physician-app",
                        "static",
                        "images",
                        `${user.id}.jpg`
                      )}`
                  : user.avatar
              }
              onError={(e) => {
                handleError(e);
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {routes
            .filter((r) => r.sidebar === true)
            .map((route) => (
              <ListItem
                button
                key={route.path}
                activeClassName={classes.activeRoute}
                component={NavLink}
                to={route.path}
                exact
              >
                <ListItemIcon>
                  {route.icon ? <route.icon /> : null}
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            ))}
          {user?.roleName === "call-center-agent" && (
            <ListItem
              button
              key="refresh"
              activeClassName={classes.activeRoute}
              onClick={() => {
                if (
                  confirm(
                    "Are you sure? This will erase any data about the current visit."
                  ) === true
                ) {
                  history.go(0);
                }
              }}
            >
              <ListItemIcon>
                <RefreshIcon />
              </ListItemIcon>
              <ListItemText primary={"Refresh"} />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          {user?.roleName == "admin" ? (
            <>
              <ListItem
                button
                component="a"
                target="_blank"
                href={`${window.AIDBOX_URL || ""}/static/console.html`}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Aidbox Console" />
              </ListItem>
              <ListItem
                button
                component="a"
                target="_blank"
                href={`${window.AIDBOX_URL || ""}/static/index.html`}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Admin page" />
              </ListItem>
            </>
          ) : null}

          <ListItem button onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

export default MainLayout;
