import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as ui from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import clsx from "clsx";
import * as aidbox from "../utils/aidbox";
import { debounce } from "../hooks/useDebounceInput";
import { buttons, helpers } from "../styles";
import * as vars from "../styles/vars";

const BillingCode = ({
  title,
  list,
  onSave,
  onDelete,
  isClosed,
  billingCodes,
}) => {
  const useStyles = ui.makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    chosenMedication: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    },
    chosenMedicationText: {
      fontWeight: "bold",
    },

    chosenMedicationResearch: {
      marginLeft: "10px",
    },
    addBody: {
      position: "relative",
      padding: ".5rem 0",
      display: "grid",
      gridTemplateColumns: "1fr 75px",
      gridGap: "10px",
    },
    addBodySearchInput: {
      width: "100%",
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: "4px",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      padding: ".25rem",
      fontSize: "1.25rem",
    },
    submit: {
      display: "flex",
      alignItems: "flex-end",
      "& button": {
        width: "100%",
        fontWeight: "bold",
        height: "35px",
      },
    },
    submitCloseIcon: {
      marginLeft: ".5rem",
    },
    searchResult: {
      position: "absolute",
      top: "75px",
      left: ".5rem",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      height: "200px",
      borderRadius: "4px",
      overflowY: "auto",
      backgroundColor: "whitesmoke",
      color: vars.btnPrimary,
      padding: ".75rem",
      border: `2px solid ${theme.palette.primary.main}`,
      "& div": {
        cursor: "pointer",
        padding: ".25rem",
        "& span": {
          borderBottom: `2px solid {theme.palette.primary.main}`,
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      },
      zIndex: 1202,
    },
    searchResultWrapper: {
      width: "100%",
      minHeight: "100vh",
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      zIndex: 1201,
    },
    codesList: {
      marginTop: ".5rem",
      display: "flex",
      gridGap: "10px",
      flexWrap: "wrap",
      "& button": {
        marginRight: ".5rem",
      },
    },
    codeDelete: {
      display: "flex",
      cursor: "pointer",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      color: vars.grey600,
      "& span": {
        display: "inline-block",
        fontWeight: " bold",
      },
      "&:hover": {
        color: vars.red700,
      },
    },
  }));
  const classes = useStyles();
  const btns = buttons();
  const styleUtils = helpers();

  const [isDisabled, setDisabled] = React.useState(true);
  const [isNotFound, setNotFound] = React.useState(false);
  const [isStopped, setStopped] = React.useState(false);
  const [codes, setCodes] = React.useState([]);
  const [isChosen, setChosen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [currentCode, setCode] = React.useState({
    id: "",
    description: "",
  });
  const resetForm = () => {
    setChosen(false);
    setCodes([]);
    setCode({
      id: "",
      description: "",
    });
    setDisabled(true);
  };

  const searchCodes = (query, listC) => {
    setCodes([]);
    setNotFound(false);

    if (!query.length) {
      return;
    }

    setLoading(true);
    setNotFound(false);

    aidbox
      .getPlain(
        `/BillingCode/$lookup?by=cpt,description&sort=cpt,description&limit=200&count=50&q=${query}`
      )
      .then((resp) => {
        if (isStopped) {
          setLoading(false);
          return;
        }

        if (!resp.data.entry) {
          setNotFound(true);
          return;
        }

        const codesList = resp.data.entry
          .map((r) => r.resource)
          .filter((r) => !list.find((code) => code.id === r.id));

        if (codesList.length) {
          setCodes(codesList);
        }

        setNotFound(!codesList.length);
        setLoading(false);
      })
      .catch((err) => {
        aidbox.sendErrorLogs({ data: err });
        console.log(err);
      });
  };

  const debounceSearch = React.useCallback(debounce(searchCodes, 200), []);

  const chooseCode = (index) => {
    setCode({
      id: codes[index].id,
      description: codes[index].description,
    });
    // setChosen(true);
    // setCodes([]);
    // setDisabled(false);
  };

  const clearSearchInput = (e) => {
    e.target.value = "";
  };

  const onCancelSearch = (e) => {
    if (e.keyCode === 27) {
      setCodes([]);
      clearSearchInput(e);
    }
    isNotFound && setNotFound(false);
  };

  const onChangeSearchInput = (e) => {
    debounceSearch(e.target.value, list);
  };

  const btnStateClass = isDisabled ? "btnDisabled" : "btnAdd";
  const submitBtnClass = clsx(
    btns.btn,
    btns[btnStateClass],
    styleUtils.fontBold
  );

  useEffect(() => {
    if (currentCode.description && currentCode.id) {
      onSave(currentCode);
      resetForm();
    }
  }, [currentCode]);

  return (
    /* eslint no-nested-ternary: "off" */
    <div className={styleUtils.card}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>{title}</div>
      </div>
      <div className={classes.addBody}>
        <div>
          {isChosen ? (
            <div className={classes.chosenMedication}>
              <div className={classes.chosenMedicationText}>
                <div>Name: {currentCode.description}</div>
                <div>Code: {currentCode.id}</div>
              </div>
              <div
                className={classes.chosenMedicationResearch}
                onClick={resetForm}
              >
                <CachedIcon />
              </div>
            </div>
          ) : (
            <>
              <input
                className={classes.addBodySearchInput}
                type="text"
                onBlur={(e) => {
                  onCancelSearch(e);
                  clearSearchInput(e);
                }}
                onKeyDown={onCancelSearch}
                onChange={onChangeSearchInput}
              />
            </>
          )}
        </div>
        {codes.length !== 0 ? (
          <>
            <div
              className={classes.searchResultWrapper}
              onClick={() => setCodes([])}
            ></div>
            <div className={classes.searchResult}>
              {codes.map((a, index) => (
                <div key={a.id} onClick={() => chooseCode(index)}>
                  <span>
                    {a.cpt} - {a.description}
                  </span>
                </div>
              ))}
            </div>
          </>
        ) : null}
        {isLoading ? (
          <div className={classes.searchResult}>
            <div> Processing... </div>
          </div>
        ) : null}
        {isNotFound ? (
          <div className={classes.searchResult}>
            <div>Not found </div>
          </div>
        ) : null}

        <div className={classes.submit}>
          <button
            className={submitBtnClass}
            onClick={() => {
              onSave(currentCode);
              resetForm();
            }}
            type="button"
            disabled={isDisabled}
          >
            Save
          </button>
        </div>
      </div>
      <div className={classes.codesList}>
        {list.map((code) => (
          <ui.Chip
            label={`${code.id} - ${billingCodes[code.id]}`}
            onDelete={() => onDelete(code.id)}
            deleteIcon={<HighlightOffIcon />}
          />
        ))}
      </div>
    </div>
  );
};

BillingCode.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
  billingCodes: PropTypes.instanceOf(Object).isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isClosed: PropTypes.bool.isRequired,
};

export default BillingCode;
