import React from "react";
import { Alert as AlertMaterial } from "@material-ui/lab";

function Alert({ error, severity }) {
  return (
    <AlertMaterial
      variant="filled"
      severity={severity}
      style={{ marginBottom: "1rem" }}
    >
      {error}
    </AlertMaterial>
  );
}
export default Alert;
