import React from "react";
import UnreadChats from "../components/UnreadChats";
import { useAuthContext } from "../components/AuthContext";
import * as aidbox from "../utils/aidbox";
import ScheduledMessageList from "../components/ScheduledMessageList";
import { getIn } from "../utils/utils";
import Alert from "../components/Errors/Alert";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import useGlobalError from "../hooks/useGlobalError";
import Spinner from "../components/Spinner";

const MessageCenterPage = () => {
  const { addError } = useGlobalError();
  const [chats, setChats] = React.useState([]);
  const [scheduledMessages, setScheduledMessages] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);

  const [componentChat, setChatError] = React.useState({
    status: true,
    error: null,
  });
  const [componentScm, setScmError] = React.useState({
    status: true,
    error: null,
  });

  const { user } = useAuthContext();

  async function fetchData() {
    try {
      const response = await aidbox.getPlain(
        `/ChatParticipant/$unread-message?resourceType=Practitioner&id=${getIn(
          user,
          ["link", 0, "link", "id"]
        )}`
      );
      setChats(response.data);
    } catch (err) {
      aidbox.sendErrorLogs({ data: err });
      if (
        err.request.status === 500 &&
        err.response.data.endpoint.type === "http-rpc"
      ) {
        addError(`LOAD_DATA_ERROR: ${err}`, err.response.status);
      } else {
        setChatError({
          error: `Error getting unread messages`,
          status: false,
        });
      }
    }
    try {
      const response = await aidbox.getPlain(
        `/$scheduled-messages-for-practitioner?practitioner=${user.id}`
      );
      setScheduledMessages(response.data);
    } catch (err) {
      aidbox.sendErrorLogs({ data: err });
      if (
        err.request.status === 500 &&
        err.response.data.endpoint.type === "http-rpc"
      ) {
        addError(`LOAD_DATA_ERROR: ${err}`, err.response.status);
      } else {
        setScmError({
          error: `Error getting scheduled messages`,
          status: false,
        });
      }
    }
    setLoading(false);
  }

  React.useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  });

  if (isLoading) return <Spinner />;
  return (
    <>
      <ErrorBoundary>
        {componentChat.status ? (
          <UnreadChats
            loading={isLoading}
            chats={chats}
            resync={fetchData}
            title="Unread chats"
            emptyText="You have no unread chats"
          />
        ) : (
          <Alert error={componentChat.error} severity="error" />
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        {componentScm.status ? (
          <ScheduledMessageList
            title="Scheduled Follow-Ups Messages"
            emptyText="Not set"
            messages={scheduledMessages}
          />
        ) : (
          <Alert error={componentScm.error} severity="error" />
        )}
      </ErrorBoundary>
    </>
  );
};

export default MessageCenterPage;
