// import moment from "moment";
import moment from "moment-timezone"; 

export function dateDisplayToVisitTable(dateString, short) {
  if (!dateString) {
    return "";
  }
  if (short) {
    return moment(new Date(moment.utc(dateString))).format("MM/DD/YYYY");
  }
  return moment(new Date(moment.utc(dateString))).format("dddd, MMMM Do, YYYY - h:mm a");
}

export function href(...pathElements) {
  return `/${pathElements.join("/")}`;
}

/*
  In future it should be https://github.com/niquola/matcho
*/
function match(o, pattern) {
  try {
    return Object.keys(pattern).reduce(
      (acc, k) => acc && o[k] === pattern[k],
      true
    );
  } catch (e) {
    return false;
  }
}

/*
  See https://clojuredocs.org/clojure.core/get-in
*/
export function getIn(obj, path, defaultValue) {
  const result = path.reduce(
    (o, k) => {
      if (o == null) {
        return null;
      }
      if (typeof k === "string" && typeof o === "object") {
        return o[k];
      }
      if (typeof k === "number" && Array.isArray(o)) {
        return o[k];
      }
      if (typeof k === "object" && Array.isArray(o)) {
        return o.filter((el) => match(el, k));
      }
      if (typeof k === "function" && Array.isArray(o)) {
        return o.filter(k);
      }
      return null;
    },
    obj,
    path
  );
  return result || defaultValue;
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function omit(object, key) {
  return Object.keys(object)
    .filter((k) => k !== String(key))
    .reduce((acc, k) => ({ [k]: object[k], ...acc }), {});
}

export default {
  mergeDeep,
  deepEqual,
  href,
  dateDisplayToVisitTable,
  getIn,
  omit,
};
