import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  text: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginRight: ".5rem",
  },
});

function PatientCreatingLoader() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.text}>Creating patient. Please wait...</span>
      <CircularProgress color="secondary" />
    </div>
  );
}

export default PatientCreatingLoader;
