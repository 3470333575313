/** Recuro Brand Colors */
export const BrandColors = {
  BLUE_MAIN: "#6aa1d8",
  LIGHT_BLUE: "#a6daf5",
  DARK_BLUE: "#505ba7",
  ORANGE: "#f26f21",
  PURPLE: "#b648cc",
  YELLOW: "#efaf22",
  BLACK: "#12151e",
  DARK_GRAY: "#252835",
  LIGHT_GRAY: "#dddddd",
  DARK_TAN: "#b5afac",
};

/** Amber material colors */
export const amber50 = "#FFF8E1";
export const amber100 = "#FFECB3";
export const amber200 = "#FFE082";
export const amber300 = "#FFD54F";
export const amber400 = "#FFCA28";
export const amber500 = "#FFC107";
export const amber600 = "#FFB300";
export const amber700 = "#FFA000";
export const amber800 = "#FF8F00";
export const amber900 = "#FF6F00";
export const amberA100 = "#FFE57F";
export const amberA200 = "#FFD740";
export const amberA400 = "#FFC400";
export const amberA700 = "#FFAB00";

/** Black & white */
export const black = "#000000";
export const white = "#FFFFFF";

/** Blue material color */
export const blue50 = "#E3F2FD";
export const blue100 = "#BBDEFB";
export const blue200 = "#90CAF9";
export const blue300 = "#64B5F6";
export const blue400 = "#42A5F5";
export const blue500 = "#2196F3";
export const blue600 = "#1E88E5";
export const blue700 = "#1976D2";
export const blue800 = "#1565C0";
export const blue900 = "#0D47A1";
export const blueA100 = "#82B1FF";
export const blueA200 = "#448AFF";
export const blueA400 = "#2979FF";
export const blueA700 = "#2962FF";

/** Indigo material color */
export const indigo50 = "#E8EAF6";
export const indigo100 = "#C5CAE9";
export const indigo200 = "#9FA8DA";
export const indigo300 = "#7986CB";
export const indigo400 = "#5C6BC0";
export const indigo500 = "#3F51B5";
export const indigo600 = "#3949AB";
export const indigo700 = "#303F9F";
export const indigo800 = "#283593";
export const indigo900 = "#1A237E";
export const indigoA100 = "#8C9EFF";
export const indigoA200 = "#536DFE";
export const indigoA400 = "#3D5AFE";
export const indigoA700 = "#304FFE";

/** Bluegrey material colors */
export const bluegrey50 = "#ECEFF1";
export const bluegrey100 = "#CFD8DC";
export const bluegrey200 = "#B0BEC5";
export const bluegrey300 = "#90A4AE";
export const bluegrey400 = "#78909C";
export const bluegrey500 = "#607D8B";
export const bluegrey600 = "#546E7A";
export const bluegrey700 = "#455A64";
export const bluegrey800 = "#37474F";
export const bluegrey900 = "#263238";

/** Grey material colors */
export const brown50 = "#EFEBE9";
export const brown100 = "#D7CCC8";
export const brown200 = "#BCAAA4";
export const brown300 = "#A1887F";
export const brown400 = "#8D6E63";
export const brown500 = "#795548";
export const brown600 = "#6D4C41";
export const brown700 = "#5D4037";
export const brown800 = "#4E342E";
export const brown900 = "#3E2723";

/** Cyan material colors */
export const cyan50 = "#E0F7FA";
export const cyan100 = "#B2EBF2";
export const cyan200 = "#80DEEA";
export const cyan300 = "#4DD0E1";
export const cyan400 = "#26C6DA";
export const cyan500 = "#00BCD4";
export const cyan600 = "#00ACC1";
export const cyan700 = "#0097A7";
export const cyan800 = "#00838F";
export const cyan900 = "#006064";
export const cyanA100 = "#84FFFF";
export const cyanA200 = "#18FFFF";
export const cyanA400 = "#00E5FF";
export const cyanA700 = "#00B8D4";

/** Dark-orange material colors */
export const darkorange50 = "#FBE9E7";
export const darkorange100 = "#FFCCBC";
export const darkorange200 = "#FFAB91";
export const darkorange300 = "#FF8A65";
export const darkorange400 = "#FF7043";
export const darkorange500 = "#FF5722";
export const darkorange600 = "#F4511E";
export const darkorange700 = "#E64A19";
export const darkorange800 = "#D84315";
export const darkorange900 = "#BF360C";
export const darkorangeA100 = "#FF9E80";
export const darkorangeA200 = "#FF6E40";
export const darkorangeA400 = "#FF3D00";
export const darkorangeA700 = "#DD2C00";

/** Darkpurple material colors */
export const darkpurple50 = "#EDE7F6";
export const darkpurple100 = "#D1C4E9";
export const darkpurple200 = "#B39DDB";
export const darkpurple300 = "#9575CD";
export const darkpurple400 = "#7E57C2";
export const darkpurple500 = "#673AB7";
export const darkpurple600 = "#5E35B1";
export const darkpurple700 = "#512DA8";
export const darkpurple800 = "#4527A0";
export const darkpurple900 = "#311B92";
export const darkpurpleA100 = "#B388FF";
export const darkpurpleA200 = "#7C4DFF";
export const darkpurpleA400 = "#651FFF";
export const darkpurpleA700 = "#6200EA";

/** Green material colors */
export const green50 = "#E8F5E9";
export const green100 = "#C8E6C9";
export const green200 = "#A5D6A7";
export const green300 = "#81C784";
export const green400 = "#66BB6A";
export const green500 = "#4CAF50";
export const green600 = "#43A047";
export const green700 = "#388E3C";
export const green800 = "#2E7D32";
export const green900 = "#1B5E20";
export const greenA100 = "#B9F6CA";
export const greenA200 = "#69F0AE";
export const greenA400 = "#00E676";
export const greenA700 = "#00C853";

/** Grey material colors */
export const grey50 = "#FAFAFA";
export const grey100 = "#F5F5F5";
export const grey200 = "#EEEEEE";
export const grey300 = "#E0E0E0";
export const grey400 = "#BDBDBD";
export const grey500 = "#9E9E9E";
export const grey600 = "#757575";
export const grey700 = "#616161";
export const grey800 = "#424242";
export const grey900 = "#212121";

/** Light-blue material colors */
export const lightblue50 = "#E1F5FE";
export const lightblue100 = "#B3E5FC";
export const lightblue200 = "#81D4FA";
export const lightblue300 = "#4FC3F7";
export const lightblue400 = "#29B6F6";
export const lightblue500 = "#03A9F4";
export const lightblue600 = "#039BE5";
export const lightblue700 = "#0288D1";
export const lightblue800 = "#0277BD";
export const lightblue900 = "#01579B";
export const lightblueA100 = "#80D8FF";
export const lightblueA200 = "#40C4FF";
export const lightblueA400 = "#00B0FF";
export const lightblueA700 = "#0091EA";

/** Light-green material colors */
export const lightgreen50 = "#F1F8E9";
export const lightgreen100 = "#DCEDC8";
export const lightgreen200 = "#C5E1A5";
export const lightgreen300 = "#AED581";
export const lightgreen400 = "#9CCC65";
export const lightgreen500 = "#8BC34A";
export const lightgreen600 = "#7CB342";
export const lightgreen700 = "#689F38";
export const lightgreen800 = "#558B2F";
export const lightgreen900 = "#33691E";
export const lightgreenA100 = "#CCFF90";
export const lightgreenA200 = "#B2FF59";
export const lightgreenA400 = "#76FF03";
export const lightgreenA700 = "#64DD17";

/** Lime material colors */
export const lime50 = "#F9FBE7";
export const lime100 = "#F0F4C3";
export const lime200 = "#E6EE9C";
export const lime300 = "#DCE775";
export const lime400 = "#D4E157";
export const lime500 = "#CDDC39";
export const lime600 = "#C0CA33";
export const lime700 = "#AFB42B";
export const lime800 = "#9E9D24";
export const lime900 = "#827717";
export const limeA100 = "#F4FF81";
export const limeA200 = "#EEFF41";
export const limeA400 = "#C6FF00";
export const limeA700 = "#AEEA00";

/** Orange material colors */
export const orange50 = "#FFF3E0";
export const orange100 = "#FFE0B2";
export const orange200 = "#FFCC80";
export const orange300 = "#FFB74D";
export const orange400 = "#FFA726";
export const orange500 = "#FF9800";
export const orange600 = "#FB8C00";
export const orange700 = "#F57C00";
export const orange800 = "#EF6C00";
export const orange900 = "#E65100";
export const orangeA100 = "#FFD180";
export const orangeA200 = "#FFAB40";
export const orangeA400 = "#FF9100";
export const orangeA700 = "#FF6D00";

/** Pink material colors */
export const pink50 = "#FCE4EC";
export const pink100 = "#F8BBD0";
export const pink200 = "#F48FB1";
export const pink300 = "#F06292";
export const pink400 = "#EC407A";
export const pink500 = "#E91E63";
export const pink600 = "#D81B60";
export const pink700 = "#C2185B";
export const pink800 = "#AD1457";
export const pink900 = "#880E4F";
export const pinkA100 = "#FF80AB";
export const pinkA200 = "#FF4081";
export const pinkA400 = "#F50057";
export const pinkA700 = "#C51162";

/** Purple material colors */
export const purple50 = "#F3E5F5";
export const purple100 = "#E1BEE7";
export const purple200 = "#CE93D8";
export const purple300 = "#BA68C8";
export const purple400 = "#AB47BC";
export const purple500 = "#9C27B0";
export const purple600 = "#8E24AA";
export const purple700 = "#7B1FA2";
export const purple800 = "#6A1B9A";
export const purple900 = "#4A148C";
export const purpleA100 = "#EA80FC";
export const purpleA200 = "#E040FB";
export const purpleA400 = "#D500F9";
export const purpleA700 = "#AA00FF";

/** Red material colors */
export const red50 = "#FFEBEE";
export const red100 = "#FFCDD2";
export const red200 = "#EF9A9A";
export const red300 = "#E57373";
export const red400 = "#EF5350";
export const red500 = "#F44336";
export const red600 = "#E53935";
export const red700 = "#D32F2F";
export const red800 = "#C62828";
export const red900 = "#B71C1C";
export const redA100 = "#FF8A80";
export const redA200 = "#FF5252";
export const redA400 = "#FF1744";
export const redA700 = "#D50000";

/** Teal material colors */
export const teal50 = "#E0F2F1";
export const teal100 = "#B2DFDB";
export const teal200 = "#80CBC4";
export const teal300 = "#4DB6AC";
export const teal400 = "#26A69A";
export const teal500 = "#009688";
export const teal600 = "#00897B";
export const teal700 = "#00796B";
export const teal800 = "#00695C";
export const teal900 = "#004D40";
export const tealA100 = "#A7FFEB";
export const tealA200 = "#64FFDA";
export const tealA400 = "#1DE9B6";
export const tealA700 = "#00BFA5";

/** Yellow material colors */
export const yellow50 = "#FFFDE7";
export const yellow100 = "#FFF9C4";
export const yellow200 = "#FFF59D";
export const yellow300 = "#FFF176";
export const yellow400 = "#FFEE58";
export const yellow500 = "#FFEB3B";
export const yellow600 = "#FDD835";
export const yellow700 = "#FBC02D";
export const yellow800 = "#F9A825";
export const yellow900 = "#F57F17";
export const yellowA100 = "#FFFF8D";
export const yellowA200 = "#FFFF00";
export const yellowA400 = "#FFEA00";
export const yellowA700 = "#FFD600";

export const btnPrimary = BrandColors.DARK_BLUE;
export const btnsecondary = "rgb(220, 0, 78)";
export const primary = BrandColors.DARK_BLUE;
export const btnlink = "#388E3C";
export const success = "#388E3C";
export const add = "#4CAF50";
export const edit = "#FFA000";
export const darkorange = "#BF360C";

export const btnprimaryhover = "#303f9f";
export const btnsecondaryhover = "rgb(154, 0, 54)";
export const btnedithover = "#FF6F00";
export const btnaddhover = "#388E3C";

export const pageheaderbgColor = "#303f9f";
export const pageheaderbgColorhover = "white";
export const pageheadertxtColor = "white";
export const pageheaderheadingColor = "white";
export const pageheaderwidth = "220px";
export const pagecontentbgColor = "#f0f1f6";
export const pagecontenttxtColor = "#171616";
export const pagecontentblockColor = "#fff";
export const blue = "#00b9eb";
export const red = "#ec1848";
export const boxshadowhover =
  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)";
export const boxShadow = "0 0 10px 2px rgba(0, 0, 0, 0.075)";
export const borderRadius = "4px";
