import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import tokenizer from "sbd";
import Dictaphone from "./Dictaphone";

export default function SpeechInput({
  inputRows,
  heading,
  inputType,
  handleChange,
  disableEnter,
  onKeyDown,
  value,
  inputName,
  autoStart,
  capitalizeSentence,
  onBlur,
}) {
  const inputRef = useRef();
  const [inputRow, setInputRows] = useState(4);
  const [previewText, setPreviewText] = useState("");
  const [autoDictate, setAutoDictate] = useState(false);
  const [micActive, setMicActive] = useState(false);
  const [recognition, setRecognition] = useState("");
  const [cursorPosition, setCursorPosition] = useState();
  const [shouldListen, setShouldListen] = useState(false);

  useEffect(() => {
    setInputRows(inputRows || 4);
    setAutoDictate(autoStart);
  }, []);

  const previewHandler = (text) => {
    setPreviewText(text);
  };

  const handleKeyDown = (e) => {
    setCursorPosition(inputRef.current.selectionStart);
    if (e.keyCode === 17) setShouldListen(true);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 17) setShouldListen(false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const upperCaseInputValue = () => {
    let sentences = tokenizer.sentences(value);
    sentences = sentences.map((sentence) => capitalizeFirstLetter(sentence));
    stopDictation();
    handleChange(sentences.join(" ").trim(), true, inputName);
  };

  const onInputBlur = (endDictation, event) => {
    setCursorPosition(inputRef.current.selectionStart);

    onBlur();
    if (capitalizeSentence) {
      upperCaseInputValue();
    }
    if (endDictation) {
      stopDictation();
    } else if (recognition.continuous) {
      recognition.continuous = false;
      recognition.stop();
    }
    setMicActive(false);
  };

  useEffect(() => {
    if (Object.hasOwnProperty.call(window, "webkitSpeechRecognition")) {
      // eslint-disable-next-line
      setRecognition(new window.webkitSpeechRecognition());
    }
    setAutoDictate(autoDictate);
  }, [autoDictate]);

  /* 
  useEffect(() => {
    if (Object.hasOwnProperty.call(window, "webkitSpeechRecognition")) {
      if (recognition) {
        recognition.onend = () => setMicActive(false);
        recognition.onerror = () => setMicActive(false);
      }
    }
  }, [recognition]); */

  const startDictation = () => {
    if (micActive) {
      return;
    }
    if (Object.hasOwnProperty.call(window, "webkitSpeechRecognition")) {
      if (!recognition.continuous) {
        recognition.continuous = true;
        recognition.interimResults = false;
        recognition.lang = "en-US";
        recognition.start();
        setMicActive(true);
        inputRef.current.focus();
      }
      recognition.onresult = (e) => {
        const currentResultIndex = e.resultIndex;
        const { transcript } = e.results[currentResultIndex][0];

        if (transcript.toLowerCase().trim() === "end") {
          recognition.stop();
        }
        if (value) {
          handleChange(
            // eslint-disable-next-line
            `${value} ${Object.values(e.results).reduce((res, curr) => {
              if (curr.isFinal) {
                if (curr[0].transcript === "period") {
                  return `${res} .`;
                }
                return res + curr[0].transcript;
              }
            }, "")}`,
            true,
            inputName
          );
        } else {
          handleChange(
            // eslint-disable-next-line
            Object.values(e.results).reduce((res, curr) => {
              if (curr.isFinal) {
                if (curr[0].transcript === "period") {
                  return `${res} .`;
                }
                return res + curr[0].transcript;
              }
            }, ""),
            true,
            inputName
          );
        }
      };
      recognition.onerror = () => {
        recognition.stop();
        setMicActive(false);
      };
    }
  };

  const stopDictation = () => {
    if (Object.hasOwnProperty.call(window, "webkitSpeechRecognition")) {
      recognition.continuous = false;
      recognition.stop();
      inputRef.current.blur();
    }
  };

  const speechHandler = (transcript) => {
    if (!transcript) return;
    const uppercase = transcript.charAt(0).toUpperCase() + transcript.slice(1);
    const spliced = `${value.slice(0, cursorPosition)}${uppercase}${value.slice(
      cursorPosition
    )}`;
    const event = {
      target: { name: inputName, value: spliced },
    };
    handleChange(event);
  };

  return (
    <div style={{ position: "relative" }}>
      {autoStart ? (
        <textarea
          ref={inputRef}
          label={heading}
          multiline={inputType === "textarea"}
          onChange={handleChange}
          onBlur={(e) => onInputBlur(false, e)}
          onKeyDown={disableEnter ? (e) => handleKeyDown(e) : null}
          rows={inputRow}
          value={value}
          name={inputName}
          style={{ width: "100%", resize: "vertical" }}
          variant="outlined"
        ></textarea>
      ) : (
        <div>
          <p>{previewText}</p>
          <div style={{ position: "relative" }}>
            <Dictaphone
              shouldListen={shouldListen}
              transcriptHandler={speechHandler}
              previewHandler={previewHandler}
            />
            <TextField
              inputRef={inputRef}
              multiline={inputType === "textarea"}
              onChange={handleChange}
              onBlur={(e) => onInputBlur(false, e)}
              onKeyDown={(e) => handleKeyDown(e)}
              onKeyUp={(e) => handleKeyUp(e)}
              rows={inputRow}
              value={value}
              name={inputName}
              style={{ width: "100%", position: "relative" }}
              variant="outlined"
              inputProps={{ style: { paddingLeft: 45 } }}
            />
          </div>
        </div>
      )}
      <span
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "10px",
          bottom: "8px",
        }}
      >
        {autoStart ? (
          micActive ? (
            <MicIcon
              fontSize="large"
              style={{ color: "green" }}
              onClick={() => onInputBlur(true)}
            />
          ) : (
            <MicOffIcon
              fontSize="large"
              style={{ color: "red" }}
              onClick={startDictation}
            />
          )
        ) : null}
      </span>
    </div>
  );
}
