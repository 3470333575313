import { Box, Button } from "@material-ui/core";
import React from "react";

export default function InformedConsent({ dispatch }) {
  return (
    <>
      <Box>
        <iframe
          width="100%"
          height="800"
          src="https://firebasestorage.googleapis.com/v0/b/supdocpatientapp.appspot.com/o/artifacts%2Fdocuments%2Fconsents%2FInformedConsentRecuroHealth_abbreviated.html?alt=media&token=af904ea1-51be-44e6-b317-bc91cb7cf627"
        />
        <Button
          style={{ marginRight: 20 }}
          color="primary"
          variant="contained"
          type="button"
          onClick={() =>
            dispatch({
              type: "nextStep",
            })
          }
        >
          Accept
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={() =>
            dispatch({
              type: "reset",
            })
          }
        >
          Decline
        </Button>
      </Box>
    </>
  );
}
