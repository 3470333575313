import { makeStyles } from "@material-ui/core";
import theme from "./mainTheme";
import * as vars from "./vars";

export const helpers = makeStyles({
  textUpper: { textTransform: "uppercase" },
  flexColumn: { flexDirection: "column" },
  dFlex: { display: "flex" },
  dGrid: { display: "grid" },
  fontBold: { fontWeight: "bold" },
  mb1: { marginBottom: ".5rem" },
  card: {
    width: "100%",
    padding: ".75rem",
    backgroundColor: "white",
    boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    marginBottom: ".75rem",
  },
});

export const buttons = makeStyles({
  btn: {
    textAlign: "center",
    textDecoration: "none",
    color: "darkgrey",
    backgroundColor: "white",
    display: "block",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    borderRadius: "5px",
    letterSpacing: "0.02857em",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  btnLink: {
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    color: "white",
  },
  btnRemove: {
    border: `2px solid ${vars.red500}`,
    color: vars.red500,
    "&:hover": {
      backgroundColor: vars.red500,
      color: "white",
    },
  },
  btnDisabled: {
    border: "2px solid transparent",
    cursor: "not-allowed",
    background: "transparent",
    boxShadow: "none",
    color: "black",
    "&:hover": {
      boxShadow: "none",
    },
  },
  btnPrimary: {
    color: "#3f51b5",
    border: "2px solid #3f51b5",
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
    },
  },

  btnAdd: {
    color: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      color: "white",
    },
  },
  btnEdit: {
    color: theme.palette.warning.main,
    border: `2px solid ${theme.palette.warning.main}`,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
      color: "white",
    },
  },
  btnSecondary: {
    color: "rgb(220, 0, 78)",
    border: "2px solid rgb(220, 0, 78)",
    "&:hover": {
      backgroundColor: "rgb(154, 0, 54)",
      color: "white",
    },
  },
});
