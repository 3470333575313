import { Box, Button } from "@material-ui/core";
import React from "react";

export default function PatientTypeSelector({ dispatch }) {
  return (
    <>
      <Box sx={{ maxWidth: 600 }}>
        <Button
          style={{ marginRight: 20 }}
          color="primary"
          variant="contained"
          type="button"
          onClick={() =>
            dispatch({
              type: "startFlow",
              data: "newPatient",
            })
          }
        >
          New Patient
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={() =>
            dispatch({
              type: "startFlow",
              data: "existingPatient",
            })
          }
        >
          Existing Patient
        </Button>
      </Box>
    </>
  );
}
