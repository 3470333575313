import React from "react";
import PropTypes from "prop-types";
import * as ui from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import PatientCreatingLoader from "../Loaders/PatientCreatingLoader";
import DoseSpotCreatingUrlLoader from "../Loaders/DoseSpotCreateUrlLoader";
import PatientCreatingError from "../Errors/PatientCreatingError";
import DoseSpotCreatingUrlError from "../Errors/DoseSpotCreatingUrlError";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    backgroundColor: "white",
    border: "2px solid black",
    width: "90%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  frame: {
    width: "100%",
    height: "100%",
  },
});

let DoseSpotUI = ({
  isOpen,
  url,
  onClose,
  patientCreating,
  patientCreatingError,
  patientError,
  frameCreating,
  frameCreatingError,
  frameError,
}) => {
  const classes = useStyles();
  return (
    <ui.Modal
      className={classes.root}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="dosespot ui"
      aria-describedby="dosespot ui frame"
    >
      <div className={classes.container}>
        {patientCreating && !patientCreatingError ? (
          <PatientCreatingLoader />
        ) : null}
        {patientCreatingError ? (
          <PatientCreatingError error={patientError} />
        ) : null}
        {frameCreating && !frameCreatingError ? (
          <DoseSpotCreatingUrlLoader />
        ) : null}
        {frameCreatingError ? (
          <DoseSpotCreatingUrlError error={frameError} />
        ) : null}
        {!frameCreatingError && !patientCreatingError && !frameCreating ? (
          <iframe title="DoseSpot Site" className={classes.frame} src={url} />
        ) : null}
      </div>
    </ui.Modal>
  );
};
DoseSpotUI.defaultProps = {
  url: null,
  onClose: null,
  patientCreating: false,
  patientCreatingError: false,
  patientError: null,
  frameCreating: false,
  frameCreatingError: false,
  frameError: null,
};

DoseSpotUI.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  url: PropTypes.string,
  onClose: PropTypes.func,
  patientCreating: PropTypes.bool,
  patientCreatingError: PropTypes.bool,
  patientError: PropTypes.string,
  frameCreating: PropTypes.bool,
  frameCreatingError: PropTypes.bool,
  frameError: PropTypes.string,
};

export default DoseSpotUI = React.memo(DoseSpotUI);
