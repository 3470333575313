import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import VisitTable from "../components/VisitTable";
import hooks from "../hooks";
import { useAuthContext } from "../components/AuthContext";
import UnreadChats from "../components/UnreadChats";
import * as aidbox from "../utils/aidbox";
import { getIn } from "../utils/utils";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import Spinner from "../components/Spinner";
import Alert from "../components/Errors/Alert";
import useGlobalError from "../hooks/useGlobalError";
import _ from "lodash";
import moment from "moment";

const VisitTableNew = ({ visitData }) => {
  const rows = visitData.map((visit) => {
    return {
      id: visit.id,
      createdDate: moment(getIn(visit, ["period", "start"])).valueOf(),
      patient: `${getIn(visit, [
        "subject",
        "resource",
        "name",
        0,
        "given",
        0,
      ])} ${getIn(visit, ["subject", "resource", "name", 0, "family"])}`,
      patientID: getIn(visit, ["subject", "id"]),
      chiefComplaint: getIn(visit, ["reasonCode", 0, "text"], "—"),
      visitId: visit.id,
      status: visit.status,
      videoStart: getIn(
        visit,
        ["videocalls_as_encounter", "0", "start"],
        undefined
      ),
      practitioner: getIn(
        visit,
        [
          "participant",
          ({ individual }) => individual.resourceType === "Practitioner",
        ],
        []
      ),
    };
  });

  const columns = [
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 150,
      editable: true,
    },
    {
      field: "patient",
      headerName: "Patient",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "chiefComplaint",
      headerName: "Chief Complaint",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "visitId",
      headerName: "Visit Link",
      flex: 1,
      minWidth: 150,
    },
  ];

  return (
    <DataGrid
      style={{ height: 400, width: "100%" }}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      disableSelectionOnClick
      columns={columns}
      rows={rows}
    />
  );
};

function HomePage() {
  const { user, userCan, practitioner } = useAuthContext();
  const { addError } = useGlobalError();
  const [chats, setChats] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [componentChat, setChatError] = React.useState({
    status: true,
    error: null,
  });
  const [unassignedEncounters, setUnassignedEncounters] = React.useState([]);
  const { loading, error, data } = hooks.useQuery({
    url: "/$graphql-query/physician-app-homepage",
    method: "POST",
    data: {
      variables: {
        practId: getIn(user, ["link", 0, "link", "id"]),
      },
    },
  });

  async function fetchData() {
    setLoading(false);
    aidbox
      .getPlain(
        `/ChatParticipant/$unread-message?resourceType=Practitioner&id=${getIn(
          user,
          ["link", 0, "link", "id"]
        )}`
      )
      .then((response) => setChats(response.data))
      .catch((err) => {
        aidbox.sendErrorLogs({ data: err });
        if (
          err.request.status === 500 &&
          err.response.data.endpoint.type === "http-rpc"
        ) {
          addError(`LOAD_DATA_ERROR: ${err}`, err.response.status);
        } else {
          setChatError({
            error: "Data request error occurred",
            status: false,
          });
        }
      });

    try {
      const resp = await aidbox.postPlain(
        "/$graphql-query/physician-app-homepage",
        {
          variables: {
            practId: practitioner.id,
            locationId: practitioner.practiceLocations?.join(",") || "",
          },
        }
      );
      if (resp.status !== 200) {
        throw new Error("Something went wrong.");
      }

      const encounters = getIn(resp, ["data", "data", "new"], []);

      if (encounters.length > 0) {
        setUnassignedEncounters(
          encounters.filter((item) => {
            return getIn(item, ["participant"], []).length === 0;
          })
        );
      }
    } catch (e) {
      return;
    }
  }

  React.useEffect(() => {
    (async () => {
      try {
        await fetchData();
      } catch (e) {}
    })();

    const interval = setInterval(async () => {
      try {
        await fetchData();
      } catch (e) {}
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <Spinner />;
  if (error)
    return (
      <Alert
        severity="error"
        error="Something went wrong. If this persists, contact Recuro at support@recurohealth.com."
      />
    );
  return (
    <div>
      {data?.new?.length > 0 && (
        <ErrorBoundary>
          <VisitTable tableTitle="New Visits" rows={data.new} />
        </ErrorBoundary>
      )}
      {userCan("phys-app-see-unassigned-encounters") && (
        <ErrorBoundary>
          <VisitTable
            fetchData={fetchData}
            tableTitle="Unassigned Visits"
            rows={unassignedEncounters}
          />
        </ErrorBoundary>
      )}
      {/* <ErrorBoundary>
        {componentChat.status ? (
          <UnreadChats
            loading={isLoading}
            chats={chats}
            resync={fetchData}
            title="Unread chats"
            emptyText="You have no unread chats"
          />
        ) : (
          <Alert error={componentChat.error} severity="error" />
        )}
      </ErrorBoundary> */}
    </div>
  );
}

export default HomePage;
