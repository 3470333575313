import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as ui from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import CachedIcon from "@material-ui/icons/Cached";
import clsx from "clsx";
import { useTheme } from "@material-ui/core";
import * as aidbox from "../utils/aidbox";
import { debounce } from "../hooks/useDebounceInput";
import { buttons, helpers } from "../styles";
import { getIn } from "../utils/utils";
import * as vars from "../styles/vars";

const ObservationTable = ({ title, observations }) => {
  const useStyles = ui.makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  }));
  const classes = useStyles();

  const styleUtils = helpers();

  console.log("Obs", observations);

  const columns = [
    { field: "codeText", headerName: "Code", flex: 2 },
    { field: "value", headerName: "Value", flex: 2 },
    { field: "date", headerName: "Date Observed", flex: 2 },
    { field: "category", headerName: "Category", flex: 2 },
  ];

  const getObservationValue = (valueObject) => {
    console.log(valueObject);
    const val = Object.entries(valueObject)
      .filter(([name, value]) => value !== null)
      .map(([name, value]) => {
        switch (name) {
          case "Quantity":
            return `${value.value} ${value.unit}`;
          case "CodeableConcept":
            return getIn(value, ["coding", "0", "display"], "");
          case "boolean":
            return value.toString();
          default:
            return "";
        }
      });
    console.log(val);
    return val;
  };

  const rows = observations.map((item) => {
    return {
      id: item.id,
      codeText: item.code?.text || "",
      value: getObservationValue(item.value),
      date: moment(item.meta?.date).format("MM/DD/YYYY"),
      category: getIn(item, ["category", "0", "coding", "0", "display"]),
    };
  });

  return (
    <div className={styleUtils.card}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>{title}</div>
      </div>
      <div style={{ width: "100%", marginTop: 20 }}>
        <DataGrid
          autoHeight
          pageSize={5}
          columns={columns}
          rows={rows}
          onRowClick={(GridRowParams) => console.log(GridRowParams)}
        ></DataGrid>
      </div>
    </div>
  );
};

ObservationTable.propTypes = {};

export default ObservationTable;
