import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { buttons } from "../styles";
import { href, dateDisplayToVisitTable } from "../utils/utils";

function UnreadChats({ chats, title, emptyText, resync }) {
  const useStyles = makeStyles((theme) => ({
    container: {
      marginBottom: ".75rem",
    },
    unreadList: {
      backgroundColor: "white",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
      borderRadius: "4px",
      padding: ".75rem",
      margin: "0 auto",
      width: "100%",
    },
    header: {
      display: "grid",
      gridTemplateColumns: "0px 1fr 75px",
      justifyContent: "center",
      "& div": {
        textAlign: "left",
        "& span ": {
          borderBottom: `5px solid ${theme.palette.primary.main}`,
          fontWeight: "bold",
          fontSize: "1.5rem",
        },
      },
    },
    chatList: {
      cursor: "default",
      padding: ".5rem 0",
    },
    empty: {
      textAlign: "center",
      "& span": {
        fontWeight: "bold",
        fontSize: "1.25rem",
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    message: {
      marginTop: "10px",
      display: "grid",
      gridTemplateColumns:
        "minmax(50px,max-content) minmax(50px,max-content) 1fr 40px 75px",
      gridColumnGap: "10px",
      alignItems: "center",
      "& div": {
        display: "flex",
        height: "100%",
        alignItems: "center",
        borderRight: "2px solid #9E9E9E",
      },
    },
    date: {
      paddingRight: ".5rem",
      fontWeight: "bold",
    },
    count: {
      borderRight: "none",
      height: "30px",
      width: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    patientLink: {
      paddingRight: ".5rem",
      "& a": {
        color: theme.palette.primary.main,
        fontSize: "1.25rem",
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        "&:hover": {
          color: "#00796B",
          borderBottom: "2px solid #004D40",
        },
      },
    },
    text: {
      display: "inline-block!important",
      overflow: "hidden",
      paddingRight: ".5rem",
      textOverflow: "ellipsis",
      fontSize: "1.25rem",
      whiteSpace: "nowrap",
    },
    visit: {
      borderRight: "none!important",
      "& a": {
        height: "35px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
        color: "#4CAF50",
        border: "2px solid #4CAF50",
        cursor: "pointer",
        backgroundColor: "transparent",
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
        "&:hover": {
          backgroundColor: "#4CAF50",
          color: "white",
          boxShadow:
            "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
        },
      },
    },
  }));

  const classes = useStyles();
  const btns = buttons();

  return (
    <div className={classes.container}>
      <div className={classes.unreadList}>
        <div className={classes.header}>
          <div />
          <div>
            <span>{title}</span>
          </div>
          <button type="button" className={btns.btnPrimary} onClick={resync}>
            Resync
          </button>
        </div>
        <div className={classes.chatList}>
          {!chats.length ? (
            <div className={classes.empty}>
              <span>{emptyText}</span>
            </div>
          ) : (
            chats
              .sort((a, b) => {
                return (
                  new Date(b.last_message.datetime) -
                  new Date(a.last_message.datetime)
                );
              })
              .map((chat) => (
                <div className={classes.message} key={chat.id}>
                  <div className={classes.date}>
                    {dateDisplayToVisitTable(chat.last_message.datetime)}
                  </div>
                  <div className={classes.patientLink}>
                    <RouterLink
                      to={href("patients", chat.last_message.patientID)}
                    >
                      {chat.last_message.patient.name[0].given[0]}{" "}
                      {chat.last_message.patient.name[0].family}
                    </RouterLink>
                  </div>
                  <div className={classes.text}>{chat.last_message.text}</div>
                  <div>
                    <span className={chat.unread_count ? classes.count : null}>
                      {chat.unread_count ?? null}
                    </span>
                  </div>
                  <div className={classes.visit}>
                    <RouterLink to={href("visits", chat.encounter)}>
                      Show
                      <ArrowForwardIosIcon />
                    </RouterLink>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );
}

UnreadChats.defaultProps = {
  title: "Unread",
  emptyText: "empty",
};

UnreadChats.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  chats: PropTypes.instanceOf(Array).isRequired,
  resync: PropTypes.func.isRequired,
};

export default UnreadChats;
