import React from "react";
import { v4 as uuid } from "uuid";
import NewEditPatientPage from "../pages/NewEditPatientPage";
import { postPlain, getPlain } from "../utils/aidbox";
import moment from "moment";
import { getIn } from "../utils/utils";

export default function NewPatientStep(props) {
  const { setAlertBar, setAlertMessage, patientData, dispatch } = props;

  const onSubmit = async (values) => {
    console.log("using new submit");
    const submissionValues = { ...values };
    submissionValues.phone = submissionValues.phone.replace(/[^\d]/g, "");
    submissionValues.birthDate = moment(values.birthDate, "YYYY-MM-DD").format(
      "MM/DD/YYYY"
    );

    try {
      const resp = await postPlain("/npc/$create-new-patient", {
        firstName: submissionValues.firstName,
        lastName: submissionValues.lastName,
        birthDate: submissionValues.birthDate,
        activationCodeText: patientData.activationCodeText,
        email: submissionValues.email,
        password: uuid(),
        gender: submissionValues.gender,
        phoneNumber: submissionValues.phone,
      });

      const code = getIn(resp, ["status"]);
      console.log("response", resp);

      if (code === 200) {
        const patientId = getIn(resp, [
          "data",
          "entry",
          ({ resource }) => resource.resourceType === "Patient",
          0,
          "resource",
          "id",
        ]);
        const fullPatientData = getIn(
          await getPlain(`/npc/$patient-data?patientId=${patientId}`),
          ["data", "patientData"]
        );

        setAlertBar(true);
        setAlertMessage("New patient record created.");

        dispatch({
          type: "updatePatientData",
          data: { ...patientData, ...fullPatientData },
        });
        dispatch({
          type: "nextStep",
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      if (e.response?.data) {
        setAlertBar(true);
        setAlertMessage(
          e.data?.message ||
            e.response?.data?.message ||
            e.response?.data?.toString()
        );
      } else {
        setAlertBar(true);
        setAlertMessage(
          "There was an error. If this continues to happen, contact Recuro technical support."
        );
      }
    }
  };
  return <NewEditPatientPage {...props} onSubmit={onSubmit} />;
}
