import React, { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParams, Redirect } from "react-router";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { AuthContext } from "../components/AuthContext";
import hooks from "../hooks";
import { href, getIn, dateDisplayToVisitTable } from "../utils/utils";
import * as vars from "../styles/vars";
import { buttons, helpers } from "../styles/index";
import Alert from "../components/Errors/Alert";
import Spinner from "../components/Spinner";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import permissions from "../constants/Permissions";

const useStyles = makeStyles({
  mainInfo: {},
  component: {},
  component__header: {
    marginBottom: "1rem",
    "& span": {
      fontSize: "1.2em",
      borderBottom: `5px solid ${vars.indigo500}`,
      fontWeight: "bold",
    },
  },
  table: {
    borderCollapse: "collapse",
    minWidth: "300px",
  },
  table__head: {
    backgroundColor: vars.teal700,
    borderBottom: `3px solid ${vars.teal900}`,
  },
  table__head_item: {
    color: "white",
    padding: ".25rem 2rem",
    textAlign: "center",
  },
  table__item: {
    padding: ".5rem 1rem",
    maxWidth: "200px",
    wordBreak: "break-word",
    textAlign: "center",
  },
  table__item_text: {
    padding: ".5rem",
  },
  itemList: {
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    padding: "0.25em .5rem",
    margin: "0.5em",
    wordWrap: "break-word",
    whiteSpace: "normal",
    display: "inline-block",
    border: `2px solid ${vars.teal700}`,
    fontWeight: "bold",
  },
  patientName: {
    color: vars.indigo700,
    fontSize: "1.5rem",
    fontWeight: "normal",
    textTransform: "capitalize",
  },
  patientAge: {
    fontSize: "1rem",
  },
});

export default function PatientPage() {
  const { user, userCan } = useContext(AuthContext);
  const utils = helpers();
  const btns = buttons();
  const classes = useStyles();
  const { id } = useParams();
  const [redirect, setRedirect] = useState("");

  let showEditButton = userCan(permissions.CREATE_NEW_PATIENT);

  const scm = hooks.useQuery({
    method: "GET",
    url: `/$scheduled-messages-for-patient-page?patient=${id}&practitioner=${getIn(
      user,
      ["link", 0, "link", "id"]
    )}`,
  });

  const { loading, data, error } = hooks.useQuery({
    url: "/$graphql-query/physician-app-patient-page",
    method: "POST",
    data: {
      variables: {
        ptId: id,
      },
    },
  });

  const hasVideoCalls =
    getIn(data, ["patient", "encounters", (e) => e.videocalls.length > 0], [])
      .length > 0;

  if (loading && !data) {
    return <Spinner />;
  }

  if (error) {
    return <Alert error={JSON.stringify(error, null, 2)} severity="warning" />;
  }
  const patient = getIn(data, ["patient"]);

  if (!loading && !patient) {
    return <Alert error={`Patient ${id} not found`} severity="warning" />;
  }

  const email = getIn(patient, [
    "telecom",
    ({ system }) => system === "email",
    0,
    "value",
  ]);
  const phone = getIn(patient, [
    "telecom",
    ({ system }) => system === "phone",
    0,
    "value",
  ]);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <ErrorBoundary>
      <div className={utils.card}>
        <div className={classes.mainInfo}>
          <div className={classes.patientName}>
            <div style={{ display: "flex" }}>
              <div>
                {getIn(patient, ["name", 0, "given", 0])}{" "}
                {getIn(patient, ["name", 0, "family"])},
                {` ${getIn(patient, ["gender"])}`}
              </div>
              <div style={{ marginLeft: "2rem" }}>
                {showEditButton && (
                  <button
                    className={clsx(btns.btn, btns.btnLink, utils.textUpper)}
                    onClick={() => {
                      setRedirect({
                        pathname: "/edit-patient",
                        state: id,
                      });
                    }}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={classes.patientAge}>
            {patient.birthDate
              ? `${moment().diff(patient.birthDate, "years")} y.o.`
              : "Not set"}
          </div>
          {email ? <div className={utils.fontBold}>{email}</div> : null}
          {phone ? <div className={utils.fontBold}>{phone}</div> : null}
        </div>
      </div>
      <div className={utils.card}>
        <div className={classes.component}>
          <div className={classes.component__header}>
            <span>Visit History</span>
          </div>
          <table className={classes.table}>
            <thead>
              <tr className={classes.table__head}>
                {hasVideoCalls && (
                  <td className={classes.table__head_item}>Video Date</td>
                )}
                <td className={classes.table__head_item}>Created Date</td>

                <td className={classes.table__head_item}>Chief Complaint</td>
              </tr>
            </thead>
            <tbody>
              {patient.encounters
                .sort((a, b) =>
                  hasVideoCalls
                    ? getIn(a, ["videocalls", "0", "start"]) >
                      getIn(b, ["videocalls", "0", "start"])
                      ? -1
                      : 1
                    : a.period.start > b.period.start
                    ? -1
                    : 1
                )
                .map((enc) => (
                  <tr key={enc.id}>
                    {hasVideoCalls && (
                      <td
                        className={classes.table__item}
                        style={{ fontWeight: "bold" }}
                      >
                        {getIn(enc, ["period", "start"])
                          ? dateDisplayToVisitTable(
                              getIn(enc, ["videocalls", 0, "start"], ""),
                              true
                            )
                          : "—"}
                      </td>
                    )}
                    <td className={classes.table__item}>
                      {getIn(enc, ["period", "start"])
                        ? dateDisplayToVisitTable(
                            getIn(enc, ["period", "start"]),
                            true
                          )
                        : "—"}
                    </td>

                    <td className={classes.table__item}>
                      {getIn(enc, ["reasonCode", 0, "text"], "—")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {scm.error ? (
        <Alert error="Error loading scheduled messages" severity="error" />
      ) : (
        <div className={utils.card}>
          <div className={classes.component}>
            <div className={classes.component__header}>
              <span>Scheduled Follow-ups</span>
            </div>
            <table className={classes.table}>
              <thead>
                <tr className={classes.table__head}>
                  <td className={classes.table__head_item}>Date</td>
                  <td className={classes.table__head_item}>Follow-up</td>
                  <td className={classes.table__head_item}>Visit Link</td>
                </tr>
              </thead>
              <tbody>
                {(scm.data || []).map((s) => {
                  return (
                    <tr key={s.id}>
                      <td className={classes.table__item}>
                        {getIn(s, ["dateTime"])
                          ? dateDisplayToVisitTable(
                              getIn(s, ["dateTime"]).slice(0, 10)
                            )
                          : "—"}
                      </td>
                      <td
                        className={clsx(
                          classes.table__item,
                          classes.table__item_text
                        )}
                      >
                        {getIn(s, ["text"], "—")}
                      </td>
                      <td className={classes.table__item}>
                        <RouterLink
                          className={clsx(
                            btns.btn,
                            btns.btnLink,
                            utils.textUpper
                          )}
                          to={href("visits", getIn(s, ["encounter", "id"]))}
                        >
                          Show visit
                        </RouterLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className={utils.card}>
        <div className={classes.component}>
          <div className={classes.component__header}>
            <span>Allergies</span>
          </div>
          <div className={classes.itemList}>
            {patient.allergies.map((allergy) => (
              <div className={classes.item} key={allergy.id}>
                <span>{getIn(allergy, ["code", "text"])}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={utils.card}>
        <div className={classes.component}>
          <div className={classes.component__header}>
            <span>Medications</span>
          </div>
          <table className={classes.table}>
            <thead>
              <tr className={classes.table__head}>
                <td className={classes.table__head_item}>Date</td>
                <td className={classes.table__head_item}>Status</td>
                <td className={classes.table__head_item}>Name</td>
                <td className={classes.table__head_item}>Visit Link</td>
              </tr>
            </thead>
            <tbody>
              {patient.medicationRequests
                .sort((a, b) => {
                  return (
                    new Date(b.meta.createdAt) - new Date(a.meta.createdAt)
                  );
                })
                .map((medication) => (
                  <tr key={medication.id}>
                    <td className={classes.table__item}>
                      {dateDisplayToVisitTable(medication.meta.createdAt)}
                    </td>
                    <td className={classes.table__item}>{medication.status}</td>
                    <td
                      className={clsx(
                        classes.table__item,
                        classes.table__item_text
                      )}
                    >
                      {getIn(medication, [
                        "medication",
                        "CodeableConcept",
                        "text",
                      ])}
                    </td>
                    <td className={classes.table__item}>
                      {getIn(medication, ["encounter", "id"]) ? (
                        <RouterLink
                          className={clsx(
                            btns.btn,
                            btns.btnLink,
                            utils.textUpper
                          )}
                          to={href(
                            "visits",
                            getIn(medication, ["encounter", "id"])
                          )}
                        >
                          Show visit
                        </RouterLink>
                      ) : null}
                    </td>
                  </tr>
                ))}
              {patient.selfReportedMedications
                .sort((a, b) => {
                  return (
                    new Date(b.meta.createdAt) - new Date(a.meta.createdAt)
                  );
                })
                .map((medication) => (
                  <tr key={medication.id}>
                    <td className={classes.table__item}>
                      {dateDisplayToVisitTable(medication.meta.createdAt)}
                    </td>
                    <td className={classes.table__item}>{medication.status}</td>
                    <td
                      className={clsx(
                        classes.table__item,
                        classes.table__item_text
                      )}
                    >
                      {getIn(medication, [
                        "medication",
                        "CodeableConcept",
                        "text",
                      ])}
                    </td>
                    <td className={classes.table__item}>
                      {getIn(medication, ["context", "id"]) ? (
                        <RouterLink
                          className={clsx(
                            btns.btn,
                            btns.btnLink,
                            utils.textUpper
                          )}
                          to={href(
                            "visits",
                            getIn(medication, ["context", "id"])
                          )}
                        >
                          Show visit
                        </RouterLink>
                      ) : null}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={utils.card}>
        <div className={classes.component}>
          <div className={classes.component__header}>
            <span>Conditions</span>
          </div>
          <table className={classes.table}>
            <thead>
              <tr className={classes.table__head}>
                <td className={classes.table__head_item}>Date</td>
                <td className={classes.table__head_item}>Status</td>
                <td className={classes.table__head_item}>Name</td>
              </tr>
            </thead>
            <tbody>
              {patient.conditions
                .sort((a, b) => {
                  return (
                    new Date(b.meta.createdAt) - new Date(a.meta.createdAt)
                  );
                })
                .map((condition) => (
                  <tr key={condition.id}>
                    <td className={classes.table__item}>
                      {dateDisplayToVisitTable(condition.meta.createdAt)}
                    </td>
                    <td className={classes.table__item}>
                      {getIn(condition, ["clinicalStatus", "text"], "--")}
                    </td>
                    <td
                      className={clsx(
                        classes.table__item,
                        classes.table__item_text
                      )}
                    >
                      {getIn(condition, ["code", "text"])}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </ErrorBoundary>
  );
}
