import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as ui from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import CachedIcon from "@material-ui/icons/Cached";
import clsx from "clsx";
import * as aidbox from "../utils/aidbox";
import { debounce } from "../hooks/useDebounceInput";
import { buttons, helpers } from "../styles";
import { getIn } from "../utils/utils";
import * as vars from "../styles/vars";
import * as questionnaireViews from "./QuestionnaireViews";

const QuestionnaireResponseTable = ({ title, responses }) => {
  const useStyles = ui.makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitle: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    paper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      maxHeight: 500,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();

  const styleUtils = helpers();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState("");

  console.log("Responses", responses);

  const onRowClick = async (params) => {
    console.log(params);
    const questions = await aidbox.getPlain(
      `/Questionnaire/${params.row.questionnaireId}`
    );
    const answers = await aidbox.getPlain(
      `/QuestionnaireResponse/${params.row.id}`
    );

    if (questions.status === 200 && answers.status === 200) {
      console.log(questions.data.item, answers.data.item);
      const rows = questions.data?.item.map((i) => {
        return {
          id: i.id,
          questionText: i.text,
          answerText: getIn(answers, [
            "data",
            "item",
            (j) => j.linkId === i.id,
            "0",
            "answer",
            "0",
            "value",
            i.type,
          ]),
        };
      });

      console.log("Rows", rows);

      const componentMap = {
        "phq-9-patient-depression-questionnaire": questionnaireViews.PHQ9Viewer,
        "uspstf-risk-questions-adapted": questionnaireViews.RiskChecklist,
      };

      console.log(rows);
      const QuestionnaireViewer = componentMap[params.row.questionnaireId];
      setModalBody(<QuestionnaireViewer rows={rows} params={params} />);
      setModalOpen(true);
    }
  };

  const columns = [
    { field: "questionnaire", headerName: "Questionnaire", flex: 2 },
    { field: "date", headerName: "Date Completed", flex: 2 },
  ];

  const rows = responses.map((item) => {
    return {
      id: item.id,
      questionnaireId: item.questionnaireReference?.resource.id,
      questionnaire: item.questionnaireReference?.resource?.title || "",
      date: item.authored ? moment(item.authored).format("MM/DD/YYYY") : "",
      checklist: item.checklist || undefined,
    };
  });

  return (
    <div className={styleUtils.card}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>{title}</div>
      </div>
      <div style={{ width: "100%", marginTop: 20 }}>
        <DataGrid
          columns={columns}
          pageSize={5}
          rows={rows}
          autoHeight
          onRowClick={(params) => onRowClick(params)}
        ></DataGrid>
        <ui.Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className={classes.paper}>{modalBody}</div>
        </ui.Modal>
      </div>
    </div>
  );
};

QuestionnaireResponseTable.propTypes = {};

export default QuestionnaireResponseTable;
