import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core";
import { dateDisplayToVisitTable, getIn } from "../utils/utils";
import { BrandColors } from "../styles/vars";
import PhoneIcon from "@material-ui/icons/Phone";

let VisitPatientInfo = ({
  patient,
  datetime,
  chief,
  videoStartTime,
  encounter,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "grid",
      gridTemplateRows: "1fr max-content",
      width: "100%",
      gridTemplateColumns: "max-content",
      gridRowGap: "10px",
      gridTemplateAreas: '"main" "bottombar"',
      marginBottom: ".75rem",
    },
    container: {
      gridArea: "main",
      borderRadius: "4px",
      border: "1px solid whitesmoke",
      backgroundColor: "white",
      display: "grid",
      gridTemplateColumns: "max-content max-content",
      boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
    },
    mainInfo: {
      padding: "2rem 3rem 2rem 2rem",
      borderRight: "2px solid #f0f1f6",
      display: "grid",
    },
    anyInfo: {
      padding: "2rem 3rem 2rem 2rem",
    },
    patientName: {
      color: theme.palette.primary.main,
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    patientAge: {
      fontSize: "1rem",
    },
    bottomBar: {
      display: "flex",
      gridArea: "bottombar",
      backgroundColor: "transparent",
    },
    chief: {
      fontSize: "1.25rem",
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
  }));
  const classes = useStyles();
  const email = getIn(
    patient,
    ["telecom", ({ system }) => system === "email", 0, "value"],
    null
  );
  const phone = getIn(
    patient,
    ["telecom", ({ system }) => system === "phone", 0, "value"],
    null
  );
  const phoneFormatted = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.mainInfo}>
          <div className={classes.patientName}>
            {getIn(patient, ["name", 0, "given", 0])}{" "}
            {getIn(patient, ["name", 0, "family"])}
            {`, ${getIn(patient, ["gender"])}`}
          </div>
          <div className={classes.patientAge}>
            <div>
              DOB: {`${moment(patient.birthDate).format("MM/DD/yyyy")}`}
            </div>
            {patient.birthDate
              ? `Age: ${moment().diff(patient.birthDate, "years")} years old`
              : "Not set"}
          </div>
          {email ? <div>Email: {email}</div> : null}
          {phone ? <div>Phone: {phoneFormatted}</div> : null}
          Visit Created: {dateDisplayToVisitTable(datetime)}
        </div>
        {encounter?.isPhone && (
          <div className={classes.mainInfo}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ padding: 10 }}>
                <PhoneIcon />
              </div>
              <div>
                <div>
                  <span>This is a telehealth visit.</span>
                </div>
                <div>
                  <span>Patient's callback number is {phoneFormatted}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {chief ? (
        <div className={classes.bottomBar}>
          <span className={classes.chief}>
            <strong>Chief Complaint: </strong> {chief[0].text}
          </span>
        </div>
      ) : null}
    </div>
  );
};
VisitPatientInfo.propTypes = {
  patient: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default VisitPatientInfo = React.memo(VisitPatientInfo);
