import React, { useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useStore } from "../../Store";
import { useController } from "../../Controller";
import CircularLoading from "../../utils/loading";

// appID: "c1915d26772549409219b0451ffebc49",
// channel: "supdoc-enc-2",

const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: ".75rem",
    backgroundColor: "white",
    boxShadow: "0 0 10px -2px rgba(0, 0, 0, 0.075)",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    marginBottom: ".75rem",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    "& button + button": {
      marginLeft: "1rem",
    },
  },
  videos: {
    display: "grid",
    gridTemplateColumns: "480px 480px",
    gridColumnGap: "2rem",
    justifyContent: "center",
    height: "480px",
    width: "100%",
    paddingBottom: "1rem",
  },
  me: {
    height: "100%",
    backgroundColor: grey[300],
  },
  patient: {
    backgroundColor: grey[300],
    height: "100%",
  },
});

export default function VideoPlayer({ meRef, patientRef }) {
  const classes = useStyles();
  const [state] = useStore();
  const ctrl = useController();

  return (
    <div className={classes.container}>
      <div className={classes.videos}>
        <div className={classes.me} ref={meRef}></div>
        <div className={classes.patient} ref={patientRef}>
          <CircularLoading />
        </div>
      </div>
      <div className={classes.toolbar}>
        <Button
          color="secondary"
          variant="contained"
          onClick={ctrl.leaveVideoCall}
        >
          Leave
        </Button>
        {state.video.mic ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={ctrl.disableAudio}
          >
            Disable audio
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={ctrl.enableAudio}
          >
            Enable audio
          </Button>
        )}
        {state.video.camera ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={ctrl.disableVideo}
          >
            Disable video
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={ctrl.enableVideo}
          >
            Enable video
          </Button>
        )}
      </div>
    </div>
  );
}
