import React from "react";
import VisitTable from "../components/VisitTable";
import hooks from "../hooks";
import { useAuthContext } from "../components/AuthContext";
import UnreadChats from "../components/UnreadChats";
import * as aidbox from "../utils/aidbox";
import { getIn } from "../utils/utils";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import Spinner from "../components/Spinner";
import Alert from "../components/Errors/Alert";
import useGlobalError from "../hooks/useGlobalError";

function Scheduler() {
  const { user } = useAuthContext();
  const { addError } = useGlobalError();
  const [chats, setChats] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [componentChat, setChatError] = React.useState({
    status: true,
    error: null,
  });
  function fetchData() {
    setLoading(false);
    aidbox
      .getPlain(
        `/ChatParticipant/$unread-message?resourceType=Practitioner&id=${getIn(
          user,
          ["link", 0, "link", "id"]
        )}`
      )
      .then((response) => setChats(response.data))
      .catch((err) => {
        aidbox.sendErrorLogs({ data: err });
        if (
          err.request.status === 500 &&
          err.response.data.endpoint.type === "http-rpc"
        ) {
          addError(`LOAD_DATA_ERROR: ${err}`, err.response.status);
        } else {
          setChatError({
            error: "Data request error occurred",
            status: false,
          });
        }
      });
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <iframe
        src={`${window.REACT_APP_SCHEDULER_APP_URL}/index.php/backend`}
        style={{
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100vh",
          width: "100%",
          position: "relative",
        }}
        height="100%"
        width="100%"
      ></iframe>
    </div>
  );
}

export default Scheduler;
